import { useEffect, useReducer } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet-async';
import Product from '../component/Product';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { useNavigate } from 'react-router-dom';
import Slider from '../ecommerce/Slider';
import FloatingButton from '../component/FloatingButton';
import { api } from '../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function EcommerceScreen() {
  const navigate = useNavigate();

  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    products: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(
          api+'/api/products'
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILED', payload: error.message });
      }
    };
    fetchData();
  }, []);

  function gotoHome() {
    navigate('/shop');
  }

  return (
		<div>
			<Helmet>
				<title>eCommerce </title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<FloatingButton />

				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i class="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						eCommerce
					</div>

					<div className="card-body" style={{ margin: "auto" }}></div>
					{loading ? (
						<LoadingBox />
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<Row style={{ marginTop: "1rem" }}>
							{products.map((product) => (
								<Col key={product.slug} sm={6} md={4} lg={3} className="mb-5">
									<Product product={product} />
								</Col>
							))}
						</Row>
					)}
				</div>
			</Container>
		</div>
	);
}
