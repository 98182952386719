import cl from "./About.module.css";

import aboutHomeImage from "../../../assets/about/aboutHome.png";
import { NavLink } from "react-router-dom";

function AboutHome() {
  return (
    <div className={cl.container}>
      <div className={cl.text}>
        <h1>Icon Marketing Limited</h1>
        <h3>
          Icon - the online shop that offers an affiliate program, empowering
          individuals to earn and build a rewarding career.
        </h3>
        <p>
          "Icon" is not just an online shop but also a pioneering network
          marketing company, providing individuals with a pathway to success.
          With a vast product range and a lucrative earning opportunity, "Icon"
          enables people to build their careers and achieve financial stability
          while sharing exceptional products with others{" "}
          <NavLink to={"/about"}>Read more...</NavLink>
        </p>
      </div>
      <div className={cl.image}>
        <img src={aboutHomeImage} alt="Icon" />
      </div>
    </div>
  );
}

export default AboutHome;
