import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { api } from "../Api";


export default function MyPromotionalStatus() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;

  function gotoHome() {
    navigate('/salesandpromotion');
  }
  const [name, setName] = useState('...');
  const [sold, setSold] = useState(0);
  const [totalReq, settotalReq] = useState(0);
  const remaining = Math.abs(totalReq - sold);
  const [days, setDays] = useState(0);
  const [allData, setAllData] = useState([]);
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/designation/mypromotionalstatus`,
        { serial: userInfo.designation, username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      const days = data.duration;
      const today = new Date();
      const prev = new Date(
        data.history ? data.history.updated_at : data.user.createdAt
      );

      var nDays =
        (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
          Date.UTC(prev.getFullYear(), prev.getMonth(), prev.getDate())) /
        86400000;

      setAllData(data);
      setName(data.name);
      setSold(data.sold);
      settotalReq(data.totalNeedsales);
      setDays(data.duration > nDays ? data.duration - nDays : 0);
      console.log(data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [userInfo]);

  return (
    <div>
      <Helmet>
        <title>My Promotional Status</title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '} My
            Promotional Status
          </div>

          <div className="card-body" style={{ margin: 'auto' }}>
            <div className="card">
              <div className="card-header">Upcoming Status</div>
              <div className="card-body">
                <table className="table table-responsive  table-hover table-stripe w-100">
                  <tr>
                    <td style={{ textAlign: 'left' }}>Next Level Status</td>
                    <td style={{ minWidth: '50px', textAlign: 'center' }}>
                      :{' '}
                    </td>
                    <td style={{ textAlign: 'right' }}>{name}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Qualifying Sales</td>
                    <td style={{ minWidth: '50px', textAlign: 'center' }}>
                      :{' '}
                    </td>
                    <td style={{ textAlign: 'right' }}>{allData.qualified}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Remaining Sales</td>
                    <td style={{ minWidth: '50px', textAlign: 'center' }}>
                      :{' '}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {allData.totalReq > allData.qualified
                        ? allData.totalReq - allData.qualified
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left' }}>Days</td>
                    <td style={{ minWidth: '50px', textAlign: 'center' }}>
                      :{' '}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Badge>{days}</Badge>{' '}
                    </td>
                  </tr>
                </table>
              </div>
              <div className="card-footer text-danger">
                Prerequisites: {totalReq} , Need Next {days} Days (T&C apply)
              </div>
            </div>
          </div>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
