import Carousel from 'react-bootstrap/Carousel';

function Slider() {
  return (
    <>
      <Carousel>
        <Carousel.Item interval={1000}>
          <img
            className="d-block w-100"
            src="./images/slider-1.jpeg"
            alt="Icon Marketing"
          />
          <Carousel.Caption className="d-none">
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img
            className="d-block w-100"
            src="./images/slider-2.jpeg"
            alt="Icon Marketing"
          />
          <Carousel.Caption className="d-none">
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <table className="table table-responsive d-none d-lg-block">
        <tr>
          <td>
            <img
              className="d-block w-100"
              src="https://clickcart.biz/assets/images/temp/banner-1.png"
              alt="Second slide"
            />
          </td>
          <td>
            <img
              className="d-block w-100"
              src="https://clickcart.biz/assets/images/temp/banner-2.png"
              alt="Second slide"
            />
            <img
              className="d-block w-100"
              src="https://clickcart.biz/assets/images/temp/banner-3.png"
              alt="Second slide"
            />
          </td>
        </tr>
      </table>
      <img
        className="d-none d-lg-block w-100"
        src="/images/prmoises.png"
        alt="Second slide"
      />
    </>
  );
}

export default Slider;
