import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import {Helmet} from "react-helmet-async";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import Spinner from 'react-bootstrap/Spinner';
import {api} from '../../Api';

var mark = [];


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ProductListScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  const fetchData = async (value) => {
    try {
      const { data } = await axios.get(
        api + `/api/users/admin?page=${page}&users=${value}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error('FatchDataError: ' + getError(error));
    }
  };
  useEffect(() => {
    fetchData('all');
  }, [page, userInfo]);

  const deleteHandler = async (product) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(
          api+`/api/users/${product._id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.success('User deleted successfully');
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  const [searchvalue, setSearch] = useState('');
  const [searchType, setSearchType] = useState('username');
  const [serachResult, setSearchResult] = useState();
  const [searchLoading, setSearchLoading] = useState(0);
  const doSearch = async () => {
    if (searchvalue.length > 0) {
      try {
        setSearchLoading(1);
        const { data } = await axios.get(
          api +
            `/api/users/admin/search?page=${page}&value=${searchvalue}&type=${searchType}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setSearchLoading(0);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
        setTotalPages(data.pages);
        setTotalUsers(data.countProducts);
      } catch (error) {
        setSearchLoading(0);
        toast.error(getError(error));
      }
    } else {
      fetchData('all');
      setSearchLoading(0);
    }
  };

  function setSearchTypefunction(value) {
    setSearchType(value);
    setSearchLoading(0);
  }

  const [totalMarked, settotalMarked] = useState(0);

  function setMark(value) {
    if (mark.find((e) => e === value)) {
      console.log('found');
      var index = mark.indexOf(value);
      if (index > -1) {
        mark.splice(index, 1);
        settotalMarked(mark.length);
      }
    } else {
      console.log('Not found');
      mark.push(value);
      settotalMarked(mark.length);
    }
    console.log(mark);
  }

  async function deleteMarked() {
    if (mark.length > 0) {
      try {
        const { data } = await axios.post(
          api+`/api/users/admin/disableuser`,
          { users: mark },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.info(data.message);

        fetchData('all');
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      toast.error('No User Selected');
    }
  }

  async function blockMarked() {
    if (mark.length > 0) {
      try {
        const { data } = await axios.post(
          api + `/api/users/admin/block`,
          { users: mark },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.info(data.message);
        fetchData('all');
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      toast.error('No User Selected');
    }
  }

  async function unblockMarked() {
    if (mark.length > 0) {
      try {
        const { data } = await axios.post(
          api + `/api/users/admin/unblock`,
          { users: mark },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.info(data.message);

        fetchData('all');
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      toast.error('No User Selected');
    }
  }

  async function filterUsers(value) {
    fetchData(value);
  }

   const [startDate, setStartDate] = useState();
		const [endDate, setEndDate] = useState();

		async function filter(startDate, endDate) {
			try {
				dispatch({ type: "FETCH_REQUEST" });
				const { data } = await axios.post(
					api + `/api/users/filterUsersByDate/filter?page=${page}`,
					{
						startDate: startDate,
						endDate: endDate,
					},
					{
						headers: { Authorization: `Bearer ${userInfo.token}` },
					}
				);
                dispatch({type: "FETCH_SUCCESS", payload: data});
                setTotalPages(data.pages);
                setTotalUsers(data.countProducts);
            } catch (err) {
                dispatch({
                    type: "FETCH_FAIL",
                    payload: getError(err),
                });
            }
        }

    function getClassName(date) {
        const createdAtDate = new Date(date);

        // Date to compare against (December 18, 2022)
        const comparisonDate = new Date('2022-12-18T00:00:00.000Z');

        // Check if createdAtDate is older than the comparisonDate
        const isOlder = createdAtDate < comparisonDate;

        // Set the className based on the comparison
        return isOlder ? 'text-danger' : '';
    }

    return (
        <div>
            <Helmet>
                <title>Manage Users</title>
            </Helmet>
            <Container className="">
                <Row className="my-3">
                    <Col>
                        <Row>
							<h2>
								Manage Users{" "}
								<select onChange={(e) => filterUsers(e.target.value)}>
									<option value="all">All Users</option>
									<option value="varified">Verified Users</option>
									<option value="unvarified">unVerified Users</option>
									<option value="blocked">Blocked Users</option>
								</select>
							</h2>
						</Row>
					</Col>
					<Col>
						<Row>
							<input
								type="search"
								style={{ width: "35%" }}
								placeholder="Search Anything"
								className="form-control"
								onChange={(e) => setSearch(e.target.value)}
							/>{" "}
							<select
								className="form-control"
								style={{ width: "20%" }}
								onChange={(e) => setSearchTypefunction(e.target.value)}
							>
								<option value="username">Username</option>
								<option value="reference">Reference</option>
								<option value="placement">Placement</option>
								<option value="phone">Phone</option>
							</select>
							{searchLoading ? (
								<Button
									variant="primary"
									style={{ width: "20%" }}
									onClick={() => doSearch(search)}
									disabled
								>
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
									<span className="visually-hidden">Search</span>
								</Button>
							) : (
								<Button
									variant="primary"
									style={{ width: "20%" }}
									onClick={() => doSearch(search)}
								>
									Search
								</Button>
							)}{" "}
						</Row>
					</Col>
					{totalMarked > 0 ? (
						<Row className="w-100" style={{ marginLeft: "20%" }}>
							{" "}
							<Button
								variant="warning"
								style={{ width: "20%" }}
								onClick={() => blockMarked()}
							>
								Block {totalMarked}
							</Button>
							<Button
								variant="info"
								style={{ width: "20%" }}
								onClick={() => unblockMarked()}
							>
								UnBlokck {totalMarked}
							</Button>
							<Button
								variant="danger"
								style={{ width: "20%" }}
								onClick={() => deleteMarked()}
							>
								Delete {totalMarked}
							</Button>
						</Row>
					) : (
						""
					)}
				</Row>

				<Row
					style={{
						border: "1px solid blue ",
						padding: "5px",
						borderRadius: 10,
            margin:'auto'
					}}
				>
					
					<Col md={3}>
						<Form.Group className="mb-3" controlId="name">
							<Form.Control
								type="date"
								placeholder="Start Date"
								onChange={(e) => setStartDate(e.target.value)}
								value={startDate}
							/>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Control
							type="date"
							placeholder="End Date"
							onChange={(e) => setEndDate(e.target.value)}
							value={endDate}
						/>
					</Col>

					<Col md={3}>
						<div className="d-grid">
							<Button
								variant="outline-success"
								onClick={() => filter( startDate, endDate)}
							>
								Search
							</Button>
						</div>
					</Col>
				</Row>

				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<>
						<div
							className="table-responsive"
							style={{
								background: "white",
							}}
						>
							<table className="table table-striped table-bordered table-hover">
								<thead className="table-light">
									<tr>
										<th>Mark</th>
										<th>Name</th>
										<th>Username</th>
										<th>Email</th>
										<th>Phone</th>
										<th>Reference</th>
										<th>Placement</th>
										<th>Point/Abl</th>
										<th>Team</th>
										<th>Joining</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{products.map((product) => (
										<tr
                                            key={product._id}
                                            className={product.status ? "" : "table-danger"}
                                        >
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    variant="checked"
                                                    onChange={(e) => setMark(e.target.value)}
                                                    value={product._id}
                                                />{" "}
                                            </td>
                                            <td> {product.name} </td>
                                            <td><span
                                                className={getClassName(product.createdAt)}>{product.username}</span>
                                            </td>
                                            <td>{product.email}</td>
                                            <td>{product.phone}</td>
                                            <td>{product.reference}</td>
                                            <td>
                                                {product.placement + " (" + product.position + ")"}
                                            </td>
                                            <td>{product.point + "/" + product.availablebalance}</td>
                                            <td>{product.leftuser + "/" + product.rightuser}</td>
                                            <td>
                                                {product.createdAt}
												{product.createdBy ? " BY " + product.createdBy : ""}
											</td>
											<td>
												<Link
													className=""
													to={`/admin/userreport/${product._id}`}
													variant="outline-success"
												>
													<i className="fa fa-eye" aria-hidden="true"></i>
												</Link>{" "}
												<Link
													to={`/admin/edituser/${product._id}`}
													variant="outline-success"
												>
													<i
														className="fa fa-pencil-square"
														aria-hidden="true"
													></i>
												</Link>{" "}
												&nbsp;
												<Link
													onClick={() => deleteHandler(product)}
													variant="outline-success"
												>
													<i className="fa fa-trash" aria-hidden="true"></i>
												</Link>
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<div>
								{"Total Users: " + totalUsers + ", Total Pages: " + totalPages}
								<nav aria-label="..." style={{ float: "right" }}>
									<ul className="pagination">
										<Link
											className={
												parseInt(page) - 2 < 1
													? "page-link disabled"
													: "page-link"
											}
											to={`/admin/users?page=${parseInt(page) - 2}`}
										>
											Previous
										</Link>
										<li className="page-item">
											<Link
												className={
													parseInt(page) - 1 < 1
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/users?page=${parseInt(page) - 1}`}
											>
												{parseInt(page) - 1}
											</Link>
										</li>
										<li className="page-item active">
											<span className="page-link">
												{parseInt(page)}
												<span className="sr-only">(current)</span>
											</span>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 1 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/users?page=${parseInt(page) + 1}`}
											>
												{parseInt(page) + 1}
											</Link>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 2 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/users?page=${page + 2}`}
											>
												Next
											</Link>
										</li>
									</ul>
								</nav>

								{[...Array(pages).keys()].map((x) => (
									<Link
										style={{ display: "none" }}
										className={
											x + 1 === Number(pages) ? "btn text-bold" : "btn"
										}
										key={x + 1}
										to={`/admin/products?page=${x + 1}`}
									>
										{x + 1}
									</Link>
								))}
							</div>
						</div>
					</>
				)}
			</Container>
		</div>
	);
}
