import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { Store } from '../Store';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../Api';
import { getError } from '../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ChatScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;
  const userID = userInfo._id;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  const [text, setText] = useState();
  const now = new Date().toLocaleString();

  async function saveNotice() {
    try {
      const data = await axios.post(
        api + `/api/costs/sendMessage`,
        { message: text, messageAt: now, userName: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success(data.message);
      fetchData();
      setText();
    } catch (err) {
      toast.error('Something Went Wrong.');
    }
  }

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/costs/allChats?page=${page}`,
        { username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
            toast.error("Something Went Wrong.");

    }
  };

  function gotoHome() {
    navigate('/user-panel');
  }

  return (
    <div>
      <Helmet>
        <title>Chat with Admin</title>
      </Helmet>

      {loading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <section
            style={{
              width: '80%',
              margin: 'auto',
              background: '#eee',
              height: '98vh',
            }}
          >
            <div className="container py-5">
              <div className="row d-flex justify-content-center">
                <div className="col">
                  <div
                    className="card"
                    id="chat1"
                    style={{ borderRadius: '15px', minHeight: '90vh' }}
                  >
                    <div
                      onClick={() => {
                        gotoHome();
                      }}
                      className="card-header d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                      style={{
                        borderTopLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                      }}
                    >
                      <i className="fas fa-angle-left"></i>
                      <p className="mb-0 fw-bold">Chat with Admin </p>
                      <i className="fas fa-times"></i>
                    </div>
                    <div
                      className="card-body"
                      style={{ maxHeight: '45vh', overflowY: 'scroll' }}
                    >
                      {/* ADMIN MESSAGE START*/}

                      <div className="d-flex flex-row justify-content-start mb-4">
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                          alt="avatar 1"
                          style={{ width: '45px', height: '100%' }}
                        />
                        <div
                          className="p-3 ms-3"
                          style={{
                            borderRadius: '15px',
                            backgroundColor: 'rgba(57, 192, 237,.2)',
                          }}
                        >
                          <p className="small mb-0">
                            Hello {userInfo.name}, how can we help you?
                          </p>
                        </div>
                      </div>
                      {/* ADMIN MESSAGE END*/}
                      {products.map((product) => (
                        <>
                          {/* USER MESSAGE START*/}
                          <div className="d-flex flex-row justify-content-end mb-4">
                            <div
                              className="p-3 me-3 border"
                              style={{
                                borderRadius: '15px',
                                backgroundColor: '#fbfbfb',
                              }}
                            >
                              <p className="small mb-0">{product.message}</p>
                              <cite
                                className="text-muted text-end"
                                style={{ fontSize: '0.7rem' }}
                              >
                                <i
                                  className="fa fa-check-circle"
                                  aria-hidden="true"
                                ></i>{' '}
                                {product.messageAt}
                              </cite>
                            </div>
                            <img
                              src={userInfo.profilePhoto}
                              alt="avatar 1"
                              style={{ width: '45px', height: '100%' }}
                            />
                          </div>
                          {/* USER MESSAGE END*/}
                          {product.reply ? (
                            <>
                              {/* ADMIN MESSAGE START*/}

                              <div className="d-flex flex-row justify-content-start mb-4">
                                <img
                                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                                  alt="avatar 1"
                                  style={{ width: '45px', height: '100%' }}
                                />
                                <div
                                  className="p-3 ms-3"
                                  style={{
                                    borderRadius: '15px',
                                    backgroundColor: 'rgba(57, 192, 237,.2)',
                                  }}
                                >
                                  <p className="small mb-0">{product.reply}</p>
                                  <cite
                                    className="text-muted text-end"
                                    style={{ fontSize: '0.7rem' }}
                                  >
                                    <i
                                      className="fa fa-check-circle"
                                      aria-hidden="true"
                                    ></i>{' '}
                                    {product.replyAt}
                                  </cite>
                                </div>
                              </div>
                              {/* ADMIN MESSAGE END*/}
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </div>
                    <div
                      className="form-outline card-footer card-footer text-muted row"
                      style={{ width: '60%', margin: 'auto' }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="textAreaExample"
                        rows="4"
                        value={text}
                        autoFocus
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Type your message"
                      />{' '}
                      <Button
                        className={
                          text ? 'btn btn-primary' : 'btn btn-primary disabled'
                        }
                        onClick={saveNotice}
                      >
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        {'  '}
                        Send Message
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}
