import Header from "../../components/global/header/Header";
import SliderComponent from "../../components/home/heroSlider/Slider";
import Features from "../../components/home/features/Features";
import SectionHeader from "../../components/global/sectionHeader/SectionHeader";
import Products from "../../components/home/products/Products";
import AboutHome from "../../components/home/about/About";
import Footer from "../../components/global/footer/Footer";
import Banner from "../../components/home/banner/Banner";
import ViewMore from "../../components/global/viewMore/ViewMore";
import Team from "../../components/home/team/Team";
import Brands from "../../components/home/brands/Brands";
import Contact from "../../components/home/contact/ContactForm";
import Earn from "../../components/home/earn/Earn";
import Quotes from "../../components/home/quotes/Quotes";

function Home() {
  return (
    <>
      <Header />
      <SliderComponent />
      <Features />
      <SectionHeader title={"Feature Products"} />
      <Products />
      <ViewMore text={"View More"} link={"/shop"} />
      <Banner />
      <AboutHome />
      <SectionHeader title={"Meet our team"} />
      <Team />
      <ViewMore text={"Learn More"} link={"/team"} />
      <SectionHeader title={"Our Brands"} />
      <Brands />
      <SectionHeader title={"Quotes we need"} />
      <Quotes />
      <Earn />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
