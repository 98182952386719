import { useContext } from "react";
import cl from "./Product.module.css";
import { Store } from "../../../../Store";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function Product(props) {
const api = "https://clickcart-julkarnine.herokuapp.com";

  const { product } = props;

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const {
		cart: { cartItems },
	} = state;
	const navigate = useNavigate();

	const addToCartHandler = async (item) => {
		const existItem = cartItems.find((x) => x._id === product._id);
		const quantity = existItem ? existItem.quantity + 1 : 1;
		const { data } = await axios.get(api + `/api/products/${item._id}`);
		if (data.stock < quantity) {
			window.alert("Sorry, this product is currently stockout.");
			return;
		}
		ctxDispatch({
			type: "CART_ADD_ITEM",
			payload: { ...item, quantity },
		});
		toast.info("Product Added to Cart");
	};

	const buyNow = async (item) => {
		const existItem = cartItems.find((x) => x._id === product._id);
		const quantity = existItem ? existItem.quantity + 1 : 1;
		const { data } = await axios.get(api + `/api/products/${item._id}`);
		if (data.stock < quantity) {
			window.alert("Sorry, this product is currently stockout.");
			return;
		}
		ctxDispatch({
			type: "CART_ADD_ITEM",
			payload: { ...item, quantity },
		});
		toast.info("Product Added to Cart");
		navigate("/cart");
	};


  return (
		<div className={cl.container}>
			<div className={cl.image_container}>
				<div className={cl.buttons}>
					<button onClick={() => addToCartHandler(props.product)}>
						Add to cart
					</button>
					<button onClick={() => buyNow(props.product)}>Buy now</button>
				</div>
				<div className={cl.image}>
					<img src={props.product.image} alt="Icon Product" />
				</div>
			</div>
			<div className={cl.title}>
				<h3>{props.product.name}</h3>
			</div>
			<div className={cl.stars}>
				{props.product.stars &&
					props.product.stars.map((star, i) => {
						if (star) {
							return <div key={i} className={cl["five-pointed-star"]}></div>;
						} else {
							return (
								<div
									key={i}
									className={cl["five-pointed-star"]}
									style={{ "--star-color": "var(--disable)" }}
								></div>
							);
						}
					})}
			</div>
			<div className={cl.price}>
				{props.product.discount ? (
					<p>
						<del>{`৳ ${props.product.discount}`}</del>{" "}
						{`৳ ${props.product.price}`}
					</p>
				) : (
					<p>{`৳ ${props.product.price}`}</p>
				)}
			</div>
			<div className={cl.point}>
				<p>
					<span className={cl["gg-trophy"]}></span>
					{`${props.product.point} + Points`}
				</p>
			</div>
		</div>
	);
}
export default Product;
