import Slider from "react-animated-slider";
import "./Custom_Slider.css";
import "react-animated-slider/build/horizontal.css";

import s1 from "../../../assets/slider/1.jpg";
import s2 from "../../../assets/slider/2.jpg";
import s3 from "../../../assets/slider/3.jpg";
import s4 from "../../../assets/slider/4.jpg";

function SliderComponent() {
  const sliderImages = [s1, s2, s3, s4];

  const content = [
    {
      title: "Shop Icon",
      subtitle:
        "Your Online Destination for Solution-Oriented Products and Career Opportunities!",
      image: sliderImages[0],
    },
    {
      title: "Discover Icon",
      subtitle:
        "Empower Your Career with Icon's Direct Selling and Affiliate Program.",
      image: sliderImages[1],
    },
    {
      title: "Solutions for Your Success",
      subtitle:
        "Join Icon's Affiliate Program for Career Settling Opportunities. Your Path to Success and Financial Freedom!",
      image: sliderImages[2],
    },
    {
      title: "Online Success Starts Here",
      subtitle:
        "Elevate Your Online Shopping Experience with Icon and Earn while You Settle Your Career.",
      image: sliderImages[3],
    },
  ];
  return (
    <div className="main_slider_container">
      <Slider direction="horizontal" duration={800} autoplay={2000}>
        {content.map((item, index) => (
          <div
            key={index}
            style={{
              background: `url('${item.image}') no-repeat center center`,
              backgroundSize: "cover",
            }}
            className="slider_image_container"
          >
            <div className="centered_slider_items">
              <h1>{item.title}</h1>
              <p>{item.subtitle}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="slider_scroll_helper"></div>
    </div>
  );
}

export default SliderComponent;
