import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {HashRouter, Route, Routes,} from 'react-router-dom';
import HomeScreen from './Screen/HomeScreen';
import ProductScreen from './Screen/ProductScreen';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useContext, useState} from 'react';
import {Store} from './Store';
import CartScreen from './Screen/CartScreen';
import SigningScreen from './Screen/SigningScreen';
import ShippingAddressScreen from './Screen/ShippingAddressScreen';
import SignupScreen from './Screen/SignupScreen';
import PaymentMethodScreen from './Screen/PaymentMethodScreen';
import PlaceOrderScreen from './Screen/PlaceOrderScreen';
import OrderScreen from './Screen/OrderScreen';
import OrderHistory from './Screen/OrderHistory';
import ProfileScreen from './Screen/ProfileScreen';
import TreeScreen from './Screen/TreeScreen';
import ProtectedRoute from './component/ProtectedRoute';
import AdminRoute from './component/AdminRoute';
import DashboardScreen from './Screen/Admin/DashboardScreen';
import ProductListScreen from './Screen/Admin/ProductListScreen';
import ProductEditScreen from './Screen/Admin/ProductEditScreen';
import OrderListScreen from './Screen/Admin/OrderListScreen';
import UserListScreen from './Screen/Admin/UserListScreen';
import AddUserScreen from './Screen/AddUserScreen';
import {Helmet} from 'react-helmet-async';
import PointsScreen from './Screen/Admin/PointScreen';
import CommissionScreen from './Screen/Admin/CommissionScreen';
import AvailableBalanceListScreen from './Screen/Admin/AvailableBalanceListScreen';
import UserNotificationScreen from './Screen/userNotificationScreen';
import DesignationListScreen from './Screen/Admin/DesignationListScreen';
import UserPanelScreen from './Screen/UserPanelScreen';
import axios from 'axios';
import TransectionScreen from './Screen/TransectionScreen';
import MyWalletScreen from './Screen/MyWalletScreen';
import MyRegisterWalletScreen from './Screen/RegisterWalletScreen';
import TransferandHistory from './Screen/TransferandHistoryScreen';
import ConvertScreen from './Screen/ConvertScreen';
import TransferScreen from './Screen/TransferScreen';
import HistoryScreen from './Screen/HistoryScreen';
import ConvertHistoryScreen from './Screen/ConvertHistory';
import TransferHistoryScreen from './Screen/TransferHisotryScreen';
import ReferenceHistoryScreen from './Screen/ReferenceHistory';
import ShopScreen from './Screen/ShopScreen';
import EcommerceScreen from './Screen/EcommerceScreen';
import SalesandPromotionScreen from './Screen/SalesandPromotion';
import WithdrawScreen from './Screen/WithdrawScreen';
import WithdrawHistoryScreen from './Screen/WithdrawHistoryScreen';
import SalesScreen from './Screen/SalesScreen';
import MySalesScreen from './Screen/MySalesScreen';
import SettingScreen from './Screen/SettingScreen';
import ConfigScreen from './Screen/Admin/ConfigScreen';
import AgentListScreen from './Screen/Admin/AgentListScreen';
import AddAgentScreen from './Screen/Admin/AddAgentScreen';
import AdminWithdrawScreen from './Screen/Admin/AdminWithdrawScreen';
import UserReportScreen from './Screen/Admin/UserReportScreen';
import GroupSalesHistoryScreen from './Screen/GroupSalesHistoryScreen';
import AdminPositionHistory from './Screen/Admin/AdminPostionHistory';
import MyPromotionalStatus from './Screen/MyPromotionalStaus';
import MyPromotionalHistory from './Screen/MyPromotionalHistory';
import MyTeamPromotionalHistory from './Screen/MyTeamPromotionalHistory';
import TeamSales from './Screen/TeamSales';
import RewardHistory from './Screen/RewardHistory';
import AdminSideBar from './component/AdminSideBar';
import GlobalSales from './Screen/Admin/GlobalSales';
import AllBrandScreen from './Screen/AllBrandScreen';
import SearchScreen from './Screen/SearchScreen';
import AgentDashboardScreen from './Screen/Agent/AgentDashboardScreen';
import AgentRoute from './Screen/Agent/AgentRoute';
import AgentSideBar from './component/AgentSideBar';
import ReceivedHistory from './Screen/ReceivedHistory';
import NoticeListScreen from './Screen/Admin/NoticeListScreen';
import ChatScreen from './Screen/ChatScreen';
import ChatListScreen from './Screen/Admin/ChatListScreen';
import EditUser from './Screen/Admin/EditUser';
import InvoicePDF from './Screen/InvoicePDF';
import WithdrawInvoicePDF from './Screen/WithdrawInvoicePDF';
import GlobalScreen from './Screen/Admin/GlobalScreen';
import TodaySalesScreen from './Screen/TodaySalesScreen';

import CreatedIdsScreen from './Screen/CreatedIdsScreen';

import DistributeScreen from './Screen/Admin/DistributeScreen';
import Rokey from './ecommerce/Rokey';
import GlobalSalesScreen from './Screen/GlobalSalesScreen';
import Home from './icon/pages/home/Home';
import IncomeGenerationHistory from './Screen/IncomeGenerationHistory';
import SelectGeneration from './Screen/SelectGeneration';
import {api} from "./Api";

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;
  const [config, setConfig] = useState();

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    window.location.href = '/signin';
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.get(api + `/api/users/getconfig`);
      if (data.siteStatus === 0 && !userInfo.isAdmin) {
        signoutHandler();
      }
      setConfig(data);
    } catch (error) {
      //toast.error(getError(error));
    }
  };

  return (
		<HashRouter>
			<Helmet>
				<title>Icon Marketing </title>
			</Helmet>
			<div className="d-flex flex-column site-container">
				<ToastContainer position="bottom-center" limit={1} autoClose={1000} />
				<header></header>

				<main>
					{userInfo && userInfo.isAdmin && <AdminSideBar />}
					{userInfo && userInfo.isAgent && <AgentSideBar />}
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/old" element={<HomeScreen />} />
						<Route path="/rokey" element={<Rokey />} />
						<Route path="/cart" element={<CartScreen />} />
						<Route path="/allbrand" element={<AllBrandScreen />} />
						<Route path="/search" element={<SearchScreen />} />
						<Route path="/signin" element={<SigningScreen />} />
						<Route path="/signup" element={<SignupScreen />} />
						<Route path="/shipping" element={<ShippingAddressScreen />} />
						<Route path="/payment" element={<PaymentMethodScreen />} />
						<Route path="/placeorder" element={<PlaceOrderScreen />} />
						<Route path="/notification" element={<UserNotificationScreen />} />
						<Route path="/product/:slug" element={<ProductScreen />} />

						<Route
							path="/chat"
							element={
								<ProtectedRoute>
									<ChatScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/order/:id"
							element={
								<ProtectedRoute>
									<OrderScreen />
								</ProtectedRoute>
							}
						/>
						<Route path="/invoice/:id" element={<InvoicePDF />} />
						<Route
							path="/withdrawinvoice/:id"
							element={
								<ProtectedRoute>
									<WithdrawInvoicePDF />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/orderhistory"
							element={
								<ProtectedRoute>
									<OrderHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/user-panel"
							element={
								<ProtectedRoute>
									<UserPanelScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/profile"
							element={
								<ProtectedRoute>
									<ProfileScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/treeview"
							element={
								<ProtectedRoute>
									<TreeScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/adduser"
							element={
								<ProtectedRoute>
									<AddUserScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/transection"
							element={
								<ProtectedRoute>
									<TransectionScreen />
								</ProtectedRoute>
							}
						></Route>
						<Route
							path="/mywallet"
							element={
								<ProtectedRoute>
									<MyWalletScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/myregisterwallet"
							element={
								<ProtectedRoute>
									<MyRegisterWalletScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/transferandhistory"
							element={
								<ProtectedRoute>
									<TransferandHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/convert"
							element={
								<ProtectedRoute>
									<ConvertScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/transfer"
							element={
								<ProtectedRoute>
									<TransferScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/history"
							element={
								<ProtectedRoute>
									<HistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/converthistory"
							element={
								<ProtectedRoute>
									<ConvertHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/transferhistory"
							element={
								<ProtectedRoute>
									<TransferHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/selectgeneration"
							element={
								<ProtectedRoute>
									<SelectGeneration/>
								</ProtectedRoute>
							}
						/>
						<Route path='/incomegenerationhistory/:id' 
						element={<ProtectedRoute>
							<IncomeGenerationHistory/>
						</ProtectedRoute>} />

						<Route
							path="/receivedhistory"
							element={
								<ProtectedRoute>
									<ReceivedHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/referencehistory"
							element={
								<ProtectedRoute>
									<ReferenceHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/shop"
							element={
								<ProtectedRoute>
									<ShopScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/ecommerce"
							element={
								<ProtectedRoute>
									<EcommerceScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/salesandpromotion"
							element={
								<ProtectedRoute>
									<SalesandPromotionScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mypromotionalstatus"
							element={
								<ProtectedRoute>
									<MyPromotionalStatus />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mypromotionalhistory"
							element={
								<ProtectedRoute>
									<MyPromotionalHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/myteampromotionalhistory"
							element={
								<ProtectedRoute>
									<MyTeamPromotionalHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/withdraw"
							element={
								<ProtectedRoute>
									<WithdrawScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/withdrawhistory"
							element={
								<ProtectedRoute>
									<WithdrawHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/groupsaleshistory"
							element={
								<ProtectedRoute>
									<GroupSalesHistoryScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/rewardhistory"
							element={
								<ProtectedRoute>
									<RewardHistory />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/sales"
							element={
								<ProtectedRoute>
									<SalesScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mysales"
							element={
								<ProtectedRoute>
									<MySalesScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/mysales"
							element={
								<ProtectedRoute>
									<MySalesScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/createdids"
							element={
								<ProtectedRoute>
									<CreatedIdsScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/todaysales"
							element={
								<ProtectedRoute>
									<TodaySalesScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/globalsales"
							element={
								<ProtectedRoute>
									<GlobalSalesScreen />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/teamsales"
							element={
								<ProtectedRoute>
									<TeamSales />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/settings"
							element={
								<ProtectedRoute>
									<SettingScreen />
								</ProtectedRoute>
							}
						/>

						{/* AGENT ROUTES */}
						<Route
							path="/agent/dashboard"
							element={
								<AgentRoute>
									<AgentDashboardScreen />
								</AgentRoute>
							}
						/>
						<Route
							path="/agent/pointlog"
							element={
								<AgentRoute>
									<PointsScreen />
								</AgentRoute>
							}
						/>
						<Route
							path="/agent/orders"
							element={
								<AgentRoute>
									<OrderListScreen />
								</AgentRoute>
							}
						/>
						<Route
							path="/agent/withdraw"
							element={
								<AgentRoute>
									<AdminWithdrawScreen />
								</AgentRoute>
							}
						/>
						{/* Admin Routes */}
						<Route
							path="/admin/userreport/:id"
							element={
								<AdminRoute>
									<UserReportScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/config"
							element={
								<AdminRoute>
									<ConfigScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/globalsales"
							element={
								<AdminRoute>
									<GlobalSales />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/dashboard"
							element={
								<AdminRoute>
									<DashboardScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/products"
							element={
								<AdminRoute>
									<ProductListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/product/:id"
							element={
								<AdminRoute>
									<ProductEditScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/orders"
							element={
								<AdminRoute>
									<OrderListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/users"
							element={
								<AdminRoute>
									<UserListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/agents"
							element={
								<AdminRoute>
									<AgentListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/addagent"
							element={
								<AdminRoute>
									<AddAgentScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/edituser/:id"
							element={
								<AdminRoute>
									<EditUser />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/pointlog"
							element={
								<AdminRoute>
									<PointsScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/commissions"
							element={
								<AdminRoute>
									<CommissionScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/availablebalance"
							element={
								<AdminRoute>
									<AvailableBalanceListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/designationlist"
							element={
								<AdminRoute>
									<DesignationListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/withdraw"
							element={
								<AdminRoute>
									<AdminWithdrawScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/positionhistory"
							element={
								<AdminRoute>
									<AdminPositionHistory />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/notices"
							element={
								<AdminRoute>
									<NoticeListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/chatlist"
							element={
								<AdminRoute>
									<ChatListScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/global"
							element={
								<AdminRoute>
									<GlobalScreen />
								</AdminRoute>
							}
						/>
						<Route
							path="/admin/global/distribute/:id"
							element={
								<AdminRoute>
									<DistributeScreen />
								</AdminRoute>
							}
						/>
						{/* END OF ADMIN ROUTE */}
					</Routes>
				</main>
				<footer></footer>
			</div>
		</HashRouter>
	);
}

export default App;
