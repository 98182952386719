import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Axios from 'axios';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {Store} from '../Store';
import {toast} from 'react-toastify';
import Header from '../icon/components/global/header/Header';
import Footer from '../icon/components/global/footer/Footer';
import {api} from '../Api';

export default function SigningScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/user-panel';

  // WORKING ON FORM SUBMIT
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(api + '/api/users/signin', {
        phone,
        password,
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (err) {
      toast.error('Unable to Login right now. Please check your internet and try again later.');
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.isAgent) {
        navigate('/agent/dashboard');
      } else if (userInfo.isAdmin) {
        navigate('/admin/dashboard');
      } else {
        navigate(redirect);
      }
    }
  }, [navigate, redirect, userInfo]);
  return (
    <>
      <div>
        <Helmet>
          <title>Sign In </title>
        </Helmet>
        <Header/>
        <Container className="small-container">
          <h1 className="my-3 text-center fp">Please Sign In</h1>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setPhone(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
              ></Form.Control>
            </Form.Group>
            <div className="mb-3 ">
              <Button type="submit" variant="outline-success w-100">
                Sign in
              </Button>
              <div className="mb-3" style={{ display: 'none' }}>
                {' '}
                No Account?{' '}
                <Link to={`/signup?redirect=${redirect}`}>
                  Create a New Account
                </Link>
              </div>
            </div>
          </Form>
        </Container>
      </div>
      <Footer />
    </>
  );
}
