import { Link, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Badge from 'react-bootstrap/Badge';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { api } from '../Api';

function Header() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state; 

  const [categoeies, setCategories] = useState([]);

  async function getCategories() {
    try {
      const data = await Axios.get(api + '/api/products/all_categories');
      setCategories(data.data);
    } catch (error) {
      window.alert(error);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Navbar bg="white" variant="light" expand="lg">
        <Container className="p-0">
          <div className="d-inline-flex">
            <Col className="m-1">
              <Link className="nav-link" to="/signin">
                Account
              </Link>
            </Col>
            <Col md="auto" className="m-1">
              <Link className="nav-link" to="#">
                Track Order
              </Link>
            </Col>
            <Col className="m-1">
              <Link className="nav-link" to="#">
                Support
              </Link>
            </Col>
          </div>
        </Container>
      </Navbar>{' '}
      {['lg'].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                height="30px"
                alt="Icon marketing "
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <div className="d-flex justify-content-end">
                  <Link
                    to="/cart"
                    className="nav-link"
                    style={{ margin: '0.5rem' }}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </Link>
                  <Link
                    to="/cart"
                    className="nav-link"
                    style={{ margin: '0.5rem' }}
                  >
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </Link>
                  <Link
                    to="/cart"
                    className="nav-link"
                    style={{ margin: '0.5rem' }}
                  >
                    <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                    {cart.cartItems.length > 0 && (
                      <Badge pill bg="danger">
                        {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                      </Badge>
                    )}
                  </Link>
                  {userInfo ? (
                    <Link
                      to="/user-panel"
                      className="nav-link"
                      style={{ margin: '0.5rem' }}
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </Link>
                  ) : (
                    <Link
                      className="nav-link"
                      to="/signin"
                      style={{ margin: '0.5rem' }}
                    >
                      <i className="fa fa-user" aria-hidden="true"></i>
                    </Link>
                  )}
                </div>
                <Offcanvas.Title
                  id={`offcanvasNavbarLabel-expand-${expand}`}
                  className="row"
                >
                  Menu{' '}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ marginLeft: '20%' }}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="Search Products..."
                  />{' '}
                  <Button className="btn" style={{ background: '#086404' }}>
                    Search
                  </Button>
                </Nav>
                <ul
                  className="list-group d-none"
                  style={{ marginTop: '0.5rem' }}
                >
                  <li className="list-group-item active text-center  d-none d-md-block">
                    Products Categories
                  </li>
                  {categoeies.map((cat) => (
                    <li className="list-group-item list-group-item-action  d-none d-md-block">
                      <Link to={'/search?category=' + cat._id}>
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                        {'     ' + cat.categoryName}
                      </Link>
                    </li>
                  ))}
                </ul>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link to="/cart" className="nav-link d-none d-lg-block">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </Link>
                  <Link to="/cart" className="nav-link d-none d-lg-block">
                    <i className="fa fa-heart" aria-hidden="true"></i>
                  </Link>
                  <Link to="/cart" className="nav-link d-none d-lg-block">
                    <i className="fa fa-shopping-bag" aria-hidden="true"></i>
                    {cart.cartItems.length > 0 && (
                      <Badge pill bg="danger">
                        {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                      </Badge>
                    )}
                  </Link>

                  <Link className="nav-link d-none d-lg-block" to="/signin">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <Navbar
        className="text-white d-none d-lg-block d-xl-block"
        expand="lg"
        style={{ background: '#086404', position: 'static' }}
      >
        <Container className="p-0">
          <div className="d-inline-flex">
            <Col className="m-1">
              <NavDropdown
                title="All Categories"
                id={`offcanvasNavbarDropdown-expand-lg`}
                style={{
                  background: 'white',
                  color: 'black',
                  borderRadius: '4px',
                  marginBottom: '-6rem',
                  marginTop: '-0.4rem',
                  paddingLeft: '2rem',
                  paddingTop: '0.4rem',
                  paddingRight: '2rem',
                  paddingBottom: '0.4rem',
                }}
              >
                {categoeies.map((cat) => (
                  <NavDropdown.Item href={'/search?category=' + cat._id}>
                    {cat.categoryName}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Col>
            <Link
              className="nav-link"
              to="/"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              Home
            </Link>

            <Link
              className="nav-link"
              to="#"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              Shop
            </Link>
            <Link
              className="nav-link"
              to="#"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              About
            </Link>
            <Link
              className="nav-link"
              to="/allbrand"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              All Brands
            </Link>
            <Link
              className="nav-link"
              to="#"
              style={{ padding: '0px 20px 0px 20px' }}
            >
              Contacts
            </Link>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
