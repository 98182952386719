import React from 'react';
import '../assets/FloatingButton.css';
import { useContext } from 'react';
import { Store } from '../Store';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';

const FloatingButton = ({ onClick }) => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  return (
    <>
      {cart.cartItems.length > 0 && (
        <Link className="floating-button" to="/cart" style={{zIndex:'99999'}}>
          {' '}
          {cart.cartItems.length > 0 && (
            <>
              <i className="fa fa-shopping-bag" aria-hidden="true"></i>
              {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
            </>
          )}
        </Link>
      )}
    </>
  );
};

export default FloatingButton;
