import cl from "./Contact.module.css";

import f1 from "../../../assets/icons/footer/facebook.png";
import f2 from "../../../assets/icons/footer/instagram.png";
import f3 from "../../../assets/icons/footer/youtube.png";
import f4 from "../../../assets/icons/footer/twitter.png";
import f5 from "../../../assets/icons/footer/linkedin.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const API = "AIzaSyCxlIiQcL39XwjsQiDClbIUQXDXoY5UxpM";
const LATITUDE = 23.7809;
const LONGITUDE = 90.422673;
const ZOOM_LEVEL = 16;
const MARKER_LATITUDE = 40.7128;
const MARKER_LONGITUDE = 90.422673;

function Contact() {
  return (
    <div className={cl.container}>
      <div className={cl.address}>
        <LoadScript googleMapsApiKey={API}>
          <GoogleMap
            mapContainerStyle={{ height: "400px", width: "100%" }}
            center={{ lat: LATITUDE, lng: LONGITUDE }}
            zoom={ZOOM_LEVEL}
          >
            <Marker
              position={{ lat: MARKER_LATITUDE, lng: MARKER_LONGITUDE }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className={cl.form}>
        <div className={cl.inner}>
          <h1>Contact Us</h1>
          <input type="text" placeholder="Enter your name" />
          <input type="text" placeholder="Enter your number" />
          <input type="text" placeholder="Enter your message" />
          <div className={cl.button}>
            <button>Submit</button>
          </div>
        </div>
        <div className={cl.inner}>
          <h1>Follow Us</h1>
          <ul>
            <li>
              <a href="#!!">
                <img src={f1} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f2} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f3} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f4} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f5} alt="Icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Contact;
