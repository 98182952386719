import cl from "./Features.module.css";

import icon1 from "../../../assets/icons/features/1.png";
import icon2 from "../../../assets/icons/features/2.png";
import icon3 from "../../../assets/icons/features/3.png";
import icon4 from "../../../assets/icons/features/4.png";
import Feature from "./Feature";

function Features() {
  return (
    <div className={cl.container}>
      <div className={cl.inner}>
        <Feature
          img={icon1}
          title={"Seamless Shopping Experience"}
          description={
            "With our intuitive and user-friendly interface, shopping on our website is a breeze. Easily browse through categories, filter search results, view product details, and add items to your cart. Our streamlined checkout process ensures a seamless and hassle-free shopping experience."
          }
        />
        <Feature
          img={icon2}
          title={"Secure Payment Options For You"}
          description={
            "We offer secure and convenient payment options to give our customers peace of mind. From trusted credit card processors to popular digital wallets, our website supports a variety of secure payment methods, allowing you to choose the option that suits you best."
          }
        />
        <Feature
          img={icon3}
          title={"Customer Reviews and Ratings"}
          description={
            "Transparency and trust are important to us. That's why we prominently feature customer reviews and ratings for our products. Read feedback from other shoppers to make informed purchasing decisions. Leave your own reviews to help other customers and contribute to our vibrant community."
          }
        />
        <Feature
          img={icon4}
          title={"Order Tracking and Delivery Updates"}
          description={
            "Once you've made a purchase, our website provides a robust order tracking system. Stay updated on the status of your order and receive real-time notifications about its progress. Track your package's journey from our warehouse to your doorstep, ensuring you're always in the loop."
          }
        />
      </div>
    </div>
  );
}

export default Features;
