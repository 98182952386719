import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Store } from '../Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from '../Api';

export default function TodaySalesScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;
  const initData = [
    {
      from: '',
      to: 'Loading',
      amount: '',
      created: '',
    },
  ];

  const [history, setHistory] = useState(initData);
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/users/todaySales?page=${page}`,
        { username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setHistory(data);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  function gotoHome() {
    navigate('/sales');
  }

  return (
    <div>
      <Helmet>
        <title>Today Sales</title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Today Sales
          </div>
          <div className="card-body" style={{ margin: 'auto' }}></div>

          <table
            className="table table-responsive"
            style={{
              margin: 'auto',

              padding: '1rem',
              textAlign: 'center',
            }}
          >
            <tr>
              <td>
                <div style={{ background: '#42b4e7', margin: '8%' }}>
                  <h2>Team Sales A</h2> <hr></hr>
                  <h2>{history.left}</h2>
                </div>
              </td>
              <td>
                <div style={{ background: '#42b4e7', margin: '8%' }}>
                  <h2>Team Sales B </h2>
                  <hr></hr>
                  <h2>{history.right}</h2>
                </div>
              </td>
            </tr>
          </table>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
