import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import CheckoutSteps from '../component/CheckoutSteps';

export default function SignupScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get('redirect');
  const redirect = redirectInUrl ? redirectInUrl : '/';

  // WORKING ON FORM SUBMIT
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [reference, setReference] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [formerr, setFormError] = useState('0');

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmpassword) {
      toast.error('Confirm Passowrd doest not match with Password.');
      return;
    }
    if (formerr === '1') {
      toast.success('Username Already Taken.');
      setFormError('1');
      return;
    }
    try {
      const { data } = await Axios.post('/api/users/signup', {
        name,
        username,
        phone,
        email,
        reference,
        password,
      });
      ctxDispatch({ type: 'USER_SIGNIN', payload: data });
      localStorage.setItem('userInfo', JSON.stringify(data));
      navigate(redirect || '/');
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const checkUsername = async (value) => {
    try {
      const data = await Axios.post('/api/users/checkusername', {
        value,
      });
      if (data.status === 200) {
        // toast.success('Username available.');
        setUsername(value);
        setFormError('0');
      } else {
        setFormError('1');
        //  toast.success('Username Already Taken.');
      }
    } catch (error) {
      toast.error(getError(error));
      setFormError('1');
    }
  };
  return (
    <div>
      <Helmet>
        <title>Sign Up | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="small-container">
        <CheckoutSteps step1></CheckoutSteps>

        <h1 className="my-3 text-center fp">Creating New Account</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Full Name </Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setName(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => checkUsername(e.target.value)}
              on
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="phone"
              onChange={(e) => setPhone(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="reference">
            <Form.Label>Reference</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setReference(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmpassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <div className="mb-3 ">
            <Button
              type="submit"
              variant="outline-success w-100 "
              className={formerr === '1' ? 'disabled' : 'n'}
            >
              Sign Up
            </Button>
            <div className="mb-3">
              {' '}
              Already Have an Account?{' '}
              <Link to={`/signin?redirect=${redirect}`}>Login Here</Link>
            </div>
          </div>
        </Form>
      </Container>
    </div>
  );
}
