import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from '../Api';

export default function ConvertScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [RegisterPoint, setRegisterPoint] = useState(0);
  const [AvilablePoint, setAvilablePoint] = useState(0);
  const [ShoppingPoint, setShoppingPoint] = useState(0);
  const [MatchingPoint, setmatchingPoint] = useState(0);
  const [convertFrom, setConvertFrom] = useState(0);
  const [convertTo, setConvertTo] = useState(0);
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(0);
  const [convertCharge, setConvertCharge] = useState();
  const fetchData = async () => {
    try {
      const { data } = await Axios.get(
        api + `/api/users/point/${userInfo._id}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      setConvertCharge(data.convertCharge);
      setRegisterPoint(data.point);
      setAvilablePoint(data.availablebalance);
      setShoppingPoint(data.shoppingbalance);
      setmatchingPoint(
        data.matchingPoint.length > 1
          ? data.matchingPoint
              .map((item) => item.amount)
              .reduce((prev, next) => prev + next)
          : data.matchingPoint[0].amount
      );
    } catch (error) {
      // window.alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userInfo]);

  const convert = async () => {
    if (
      convertFrom !== 0 &&
      convertTo !== 0 &&
      amount > 0 &&
      password.length > 5
    ) {
      if (convertFrom === convertTo) {
        toast.error('Invalid Information. Please check again');
      } else {
        if (convertFrom === 'availablebalance' && amount > AvilablePoint) {
          toast.error('Invalid Amount. Please check again');
        } else if (convertFrom === 'point' && amount > RegisterPoint) {
          toast.error('Invalid Amount. Please check again');
        } else if (
          convertFrom === 'shoppingbalance' &&
          amount > ShoppingPoint
        ) {
          toast.error('Invalid Amount. Please check again');
        } else {
          try {
            setloading(1);

            const { data } = await Axios.post(
              api + `/api/users/convert/`,
              {
                from: convertFrom,
                to: convertTo,
                amount: amount,
                password: password,
              },
              {
                headers: { authorization: `Bearer ${userInfo.token}` },
              }
            );
            setloading(0);
            toast.info(data.message);
            fetchData();
            setConvertFrom(0);
            setConvertTo(0);
            setAmount(0);
            setPassword(0);
          } catch (error) {
            setloading(0);
            toast.error(getError(error));
          }
        }
      }
    } else {
      toast.error('Invalid Information. Please check again. ');
    }
  };

  function gotoHome() {
    navigate('/transferandhistory');
  }

  function setConvertFromF(value) {
    setConvertFrom(value);
    if (value === 'matching') {
      setConvertTo('availablebalance');
      calculate(MatchingPoint);
    }
  }

  function calculate(amount) {
    if (convertCharge > 0) {
      const am = amount - (amount * convertCharge) / 100;
      setAmount(am);
      if (convertFrom === 'matching') {
        const am = amount - (amount * convertCharge) / 100;
        setAmount(am);
      }
    } else {
      setAmount(amount);
    }
  }
  return (
    <div>
      <Helmet>
        <title>Convert </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Convert
          </div>

          <div className="card-body w-90" style={{ margin: 'auto' }}>
            <span>
              {convertFrom === 'availablebalance'
                ? 'Available Point: ' + AvilablePoint
                : convertFrom === 'shoppingbalance'
                ? 'Shopping Point: ' + ShoppingPoint
                : convertFrom === 'matching'
                ? 'Group Sales: ' + MatchingPoint
                : 'Please Select Option'}{' '}
              | Convert Fee {convertCharge}%
            </span>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i className="fa fa-level-down" aria-hidden="true"></i>
              </div>
              <select
                className="form-control "
                required
                onChange={(e) => setConvertFromF(e.target.value)}
              >
                <option value="0">Convert From</option>
                <option value="shoppingbalance">Shopping Balance</option>
                <option value="matching">Group Sales</option>
              </select>{' '}
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i className="fa fa-mail-forward" aria-hidden="true"></i>
              </div>
              <select
                className="form-control"
                required
                value={
                  convertFrom === 'matching' ? 'availablebalance' : convertTo
                }
                readOnly={convertFrom === 'matching' ? 'true' : ''}
                onChange={(e) => setConvertTo(e.target.value)}
              >
                <option value="0">Convert To</option>

                {convertFrom === 'matching' ? (
                  <option value="availablebalance">Available Balance</option>
                ) : (
                  <option value="point">Register Balance Balance</option>
                )}
              </select>
            </div>
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-dollar" aria-hidden="true"></i>
              </div>
              <input
                className="form-control"
                type="number"
                placeholder="Amount"
                readOnly={convertFrom === 'matching' ? 'true' : ''}
                required
                value={convertFrom === 'matching' ? MatchingPoint : null}
                onChange={(e) => calculate(e.target.value)}
              ></input>
            </div>
            <cite className=" mb-1">Net Payable: {amount} </cite>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i className="fa fa-asterisk" aria-hidden="true"></i>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              variant="outline-success w-100"
              className={loading ? 'disabled' : ''}
              onClick={convert}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ''
              )}{' '}
              Submit
            </Button>
          </div>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
