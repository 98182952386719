import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Axios from 'axios';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from "../Api";


export default function TransferScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const [RegisterPoint, setRegisterPoint] = useState(0);
  const [AvilablePoint, setAvilablePoint] = useState(0);
  const [ShoppingPoint, setShoppingPoint] = useState(0);
  const [convertFrom, setConvertFrom] = useState(0);
  const [convertTo, setConvertTo] = useState(0);
  const [amount, setAmount] = useState(0);
  const [password, setPassword] = useState('');
  const [loading, setloading] = useState(0);
  const [minimumTransfer, setMinimumTransfer] = useState();

  const fetchData = async () => {
    try {
      const { data } = await Axios.get(
        api + `/api/users/point/${userInfo._id}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      setMinimumTransfer(data.minimumTransfer);
      setRegisterPoint(data.point);
      setAvilablePoint(data.availablebalance);
      setShoppingPoint(data.shoppingbalance);
    } catch (error) {
      // window.alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userInfo]);

  const convert = async () => {
    if (amount < minimumTransfer) {
      toast.error('Minimum Transfer Amount is ' + minimumTransfer);
    } else {
      if (
        convertFrom !== '0' &&
        convertTo !== '0' &&
        amount > 499 &&
        password.length > 5
      ) {
        if (convertFrom === convertTo) {
          toast.error('Invalid Information. Please check again');
        } else {
          if (convertFrom === 'availablebalance' && amount > AvilablePoint) {
            toast.error('Invalid Amount. Please check again');
          } else if (convertFrom === 'point' && amount > RegisterPoint) {
            toast.error('Invalid Amount. Please check again');
          } else if (
            convertFrom === 'shoppingbalance' &&
            amount > ShoppingPoint
          ) {
            toast.error('Invalid Amount. Please check again');
          } else {
            try {
              setloading(1);

              const { data } = await Axios.post(
                api + `/api/users/transfer/`,
                {
                  from: convertFrom,
                  senderUsername: userInfo.username,
                  to: convertTo,
                  amount: amount,
                  password: password,
                },
                {
                  headers: { authorization: `Bearer ${userInfo.token}` },
                }
              );
              setloading(0);
              toast.info(data.message);
              fetchData();
              setAmount(0);
              setPassword();
            } catch (error) {
              setloading(0);
              toast.error(getError(error));
            }
          }
        }
      } else {
        toast.error('Invalid Information. Please check again. ');
      }
    }
  };
  const [name, setName] = useState('');
  const [userLoading, setUserLoading] = useState(0);
  const checkUsername = async (username) => {
    try {
      setUserLoading(1);
      const { data } = await Axios.post(
        api + `/api/users/checkusernamefortransfer/`,
        {
          value: username,
        },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      setUserLoading(0);
      setName(data.name);
      setConvertTo(username);
    } catch (error) {
      setUserLoading(0);
      toast.error('Invalid Username');
    }
  };
  function gotoHome() {
    navigate('/transferandhistory');
  }
  return (
    <div>
      <Helmet>
        <title>Transfer </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Transfer
          </div>

          <div className="card-body w-90" style={{ margin: 'auto' }}>
            <span>
              {convertFrom === 'availablebalance'
                ? 'Available Point:' + AvilablePoint
                : convertFrom === 'point'
                ? 'Register Point: ' + RegisterPoint
                : 'Please Select a Option'}
            </span>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i class="fa fa-level-down" aria-hidden="true"></i>
              </div>
              <select
                className="form-control "
                required
                onChange={(e) => setConvertFrom(e.target.value)}
              >
                <option value="0">Transfer From</option>
                {/* <option value="availablebalance">Available Balance</option> */}
                <option value="point">Register Balance Balance</option>
              </select>{' '}
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i class="fa fa-user" aria-hidden="true"></i>
              </div>
              <input
                type="text"
                className="form-control"
                required
                placeholder="Username"
                onBlur={(e) => checkUsername(e.target.value)}
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                {userLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  ''
                )}
                {<i class="fa fa-check" aria-hidden="true"></i>}
              </div>
              <input
                type="text"
                className="form-control"
                readOnly
                value={name}
                placeholder="Please Wait"
              />
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i class="fa fa-dollar" aria-hidden="true"></i>
              </div>
              <input
                className="form-control"
                type="number"
                placeholder="Amount"
                required
                onChange={(e) => setAmount(e.target.value)}
              ></input>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i class="fa fa-asterisk" aria-hidden="true"></i>
              </div>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              variant="outline-success w-100"
              className={loading ? 'disabled' : ''}
              onClick={convert}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ''
              )}{' '}
              Submit
            </Button>
          </div>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
