import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";

var mark = [];


const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return {...state, loading: true};
        case 'FETCH_SUCCESS':
            return {
                ...state,
                products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function AgentListScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  const fetchData = async () => {
    try {
      const { data } = await axios.get(api + `/api/users/agents?page=${page}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  const deleteHandler = async (product) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(api + `/api/users/${product._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('User deleted successfully');
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  const [searchvalue, setSearch] = useState('');
  const [serachResult, setSearchResult] = useState();
  const doSearch = async (value) => {
    if (value.length > 0) {
      try {
        const { data } = await axios.get(
          api + `/api/users/admin/search?page=${page}&value=${value}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
        setTotalPages(data.pages);
        setTotalUsers(data.countProducts);
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      fetchData();
    }
  };

  const [totalMarked, settotalMarked] = useState(0);

  function setMark(value) {
    if (mark.find((e) => e === value)) {
      console.log('found');
      var index = mark.indexOf(value);
      if (index > -1) {
        mark.splice(index, 1);
        settotalMarked(mark.length);
      }
    } else {
      console.log('Not found');
      mark.push(value);
      settotalMarked(mark.length);
    }
    console.log(mark);
  }

  async function deleteMarked() {
    if (mark.length > 0) {
      try {
        const { data } = await axios.post(
          api + `/api/users/admin/disableuser`,
          { users: mark },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        fetchData();
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      toast.error('No User Selected');
    }
  }

  return (
    <div>
      <Helmet>
        <title>Manage Agents | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="">
        <Row className="my-3">
          <Col>
            <h2 className=" ">Manage Agents </h2>
          </Col>
          <Col>
            <Row className="d-none">
              {totalMarked > 0 ? (
                <Button
                  variant="danger"
                  style={{ width: '20%' }}
                  onClick={() => deleteMarked()}
                >
                  Delete {totalMarked}
                </Button>
              ) : (
                ''
              )}
              <input
                type="search"
                style={{ width: '35%' }}
                placeholder="Search Anything"
                className="form-control"
                onChange={(e) => doSearch(e.target.value)}
              />{' '}
              <Button
                style={{ width: '20%', marginRight: '0.5rem' }}
                onClick={() => doSearch(search)}
              >
                Search
              </Button>{' '}
            </Row>
            <Row style={{ float: 'right' }}>
              <Link to="/admin/addagent" className="btn btn-success ">
                Add Agent
              </Link>
            </Row>
          </Col>
        </Row>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div
              className="table-responsive"
              style={{
                background: 'white',
                border: '1px solid black',
                padding: '5px',
                borderRadius: '15px',
              }}
            >
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Available Balance</th>
                    <th>Shopping Balance</th>
                    <th>Register Balance</th>
                    <th>Joining</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td className="d-none">
                        <input
                          type="checkbox"
                          variant="checked"
                          onChange={(e) => setMark(e.target.value)}
                          value={product._id}
                        />{' '}
                      </td>
                      <td>{product.name}</td>
                      <td>{product.username}</td>
                      <td>{product.email}</td>
                      <td>{product.phone}</td>
                      <td>{product.availablebalance}</td>
                      <td>{product.shoppingbalance}</td>
                      <td>{product.point}</td>
                      <td>{product.createdAt}</td>
                      <td>
                        <Link
                          to={`/admin/edituser/${product._id}`}
                          variant="outline-success"
                        >
                          <i
                            className="fa fa-pencil-square"
                            aria-hidden="true"
                          ></i>
                        </Link>{' '}
                        &nbsp;
                        <Link
                          onClick={() => deleteHandler(product)}
                          variant="outline-success"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {'Total Users: ' + totalUsers + ', Total Pages: ' + totalPages}
                <nav aria-label="..." style={{ float: 'right' }}>
                  <ul className="pagination">
                    <Link
                      className={
                        parseInt(page) - 2 < 1
                          ? 'page-link disabled'
                          : 'page-link'
                      }
                      to={`/admin/users?page=${parseInt(page) - 2}`}
                    >
                      Previous
                    </Link>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) - 1 < 1
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/users?page=${parseInt(page) - 1}`}
                      >
                        {parseInt(page) - 1}
                      </Link>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        {parseInt(page)}
                        <span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 1 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/users?page=${parseInt(page) + 1}`}
                      >
                        {parseInt(page) + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 2 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/users?page=${page + 2}`}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>

                {[...Array(pages).keys()].map((x) => (
                  <Link
                    style={{ display: 'none' }}
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/products?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
