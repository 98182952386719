import cl from "./Footer.module.css";
import logo from "../../../assets/identity/icon-logo-l.png";
import payment from "../../../assets/footer/sslcommerz.png";

import f1 from "../../../assets/icons/footer/facebook.png";
import f2 from "../../../assets/icons/footer/instagram.png";
import f3 from "../../../assets/icons/footer/youtube.png";
import f4 from "../../../assets/icons/footer/twitter.png";
import f5 from "../../../assets/icons/footer/linkedin.png";

function Footer() {
  return (
    <>
      <div className={cl.container}>
        <div className={cl.logo_news}>
          <img src={logo} alt="Icon" />
          <p>
            We are a direct selling company that offers a range of
            solution-oriented products designed to enhance your everyday life.
            With a commitment to fair pricing, we provide high-quality goods
            that cater to your needs.
          </p>
          <h1>Join Our Newsletter!</h1>
          <p>
            Joining our newsletter brings exclusive benefits towards you. Don't
            miss out!
          </p>
          <div className={cl.newsletter}>
            <input type="email" placeholder="Enter your Email" />
            <button>Subscribe</button>
          </div>
          <div className={cl.payment}>
            <img src={payment} alt="Payment" />
          </div>
        </div>
        <div className={cl.link_list}>
          <h2>Usefull links</h2>
          <ul>
            <li>
              <a href="#!">Shop</a>
            </li>
            <li>
              <a href="#!">Features</a>
            </li>
            <li>
              <a href="#!">About</a>
            </li>
            <li>
              <a href="#!">Contact</a>
            </li>
            <li>
              <a href="#!">Terms</a>
            </li>
            <li>
              <a href="#!">Conditions</a>
            </li>
          </ul>
        </div>
        <div className={cl.link_list}>
          <h2>Your journey</h2>
          <ul>
            <li>
              <a href="#!">Account</a>
            </li>
            <li>
              <a href="#!">Orders</a>
            </li>
            <li>
              <a href="#!">Track order</a>
            </li>
            <li>
              <a href="#!">Support</a>
            </li>
          </ul>
        </div>
        <div className={cl.address_payment}>
          <h2>Address</h2>
          <p>
            House - Ja-80/b, Bir uttom A.K Khondokar road, Badda link road,
            Badda, Dhaka-1212
          </p>
          <h2>Call Us</h2>
          <p>+09603-000-123</p>
          <h2>E-mail Us</h2>
          <p>support@iconmarketing.com.bd</p>
          <h2>Follow Us</h2>
          <ul>
            <li>
              <a href="#!!">
                <img src={f1} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f2} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f3} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f4} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#!!">
                <img src={f5} alt="Icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className={cl.payment_mobile}>
          <img src={payment} alt="Payment" />
        </div>
      </div>
      <div className={cl.copyright}>
        <p>
          Copyright © 2023, All Right Reserved <span>Icon</span>
        </p>
      </div>
    </>
  );
}

export default Footer;
