// ProfileCard.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../Api";
import '../assets/ProfileCard.css';
const ProfileCard = (props) => {
	const userId = props.withdrawId;
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const response = await axios.get(
					api + `/api/users/getIncomeGenerationHistoryUser/${userId}`
				); // Adjust the API endpoint accordingly
				setUserData(response.data);
			} catch (error) {
				console.error("Error fetching user data:", error);
			}
		};

		if (userId) {
			fetchUserData();
		}
	}, [userId]);

  const profileCardStyle = {
		border: "1px solid #ccc",
		padding: "20px",
		margin: "10px",
		maxWidth: "200px",
		textAlign: "center",
	};

	const profilePhotoStyle = {
		width: "100px",
		height: "100px",
		borderRadius: "50%",
	};


	return (
		<div className="col-md-4">
			{userData ? (
				<>
					<div className="box1 box">
						<div className="content">
							<div className="image">
								<img
									src={
										userData.profilePhoto ||
										"https://i.postimg.cc/bryMmCQB/profile-image.jpg"
									}
									alt="Profile Image"
								/>
							</div>
							<div className="text">
								<p className="name">{userData.name}</p>
								<p className="job_title">{userData.username}</p>
							</div>
							<div className="button">
								<div>
									<button className="message" type="button">
										{Number(props.amount * 5).toFixed(0)} <br></br> Total
										Withdraw
									</button>
								</div>
								<div>
									<button className="connect" type="button">
										{Number(props.amount).toFixed(2)} <br></br> Your Earnings
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<p>NO User Information Found. {userId}</p>
			)}
		</div>
	);
};

export default ProfileCard;
