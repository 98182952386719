import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import { Helmet } from 'react-helmet-async';
import Product from '../component/Product';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import Footer from '../ecommerce/Footer';
import Header from '../ecommerce/Header';
import { api } from "../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


function SearchScreen() {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  const st = String(params);
  const arr = st.split('=');
  const type = arr[0];
  const id = arr[1];
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    products: [],
  });

  const fetchData = async () => {
    dispatch({ type: 'FETCH_REQUEST' });
    try {
      const result = await axios.post(api + '/api/products/search', {
        type,
        id,
      });
      dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
    } catch (error) {
      dispatch({ type: 'FETCH_FAILED', payload: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <title>Icon Marketing</title>
        </Helmet>
        <Header />

        <h3
          className="text-left"
          style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
        >
          Search Result
        </h3>
        <div className="products" style={{ maxWidth: '80%', margin: 'auto' }}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Row>
                {products.map((product) => (
                  <Col key={product.slug} className="mb-5">
                    {<Product product={product} />}
                  </Col>
                ))}
                {products.length < 1 ? (
                  <Badge variant="info" className="m-3">
                    <h2>NO Product Found</h2>
                  </Badge>
                ) : (
                  ''
                )}
              </Row>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SearchScreen;
