import Axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, usePharams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Store } from '../Store';
import { getError } from '../utils';
import { Link } from 'react-router-dom';
import Header from '../ecommerce/Header';
import Footer from '../ecommerce/Footer';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { jsPDF } from 'jspdf';
import '../assets/a4page.css';
import { api } from '../Api';
function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };
    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}

export default function WithdrawInvoicePDF() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const fetechOrder = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await Axios.get(
        api + `/api/orders/withdraw/${orderId}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
    }
  };

  useEffect(() => {
    if (!userInfo) {
      toast.error('Please Login');
      navigate('/signin');
    }
    if (!order._id || (order._id && order._id !== orderId)) {
      fetechOrder();
    }
    if (successPay) {
      dispatch({ type: 'PAY_RESET' });
    }
    // setTimeout(function () {
    //   pdf();
    // }, 1000);
  }, [order, userInfo, orderId, navigate]);

  function pdf() {
    htmlToImage
      .toPng(document.getElementById('myPage'), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('Invoice_' + order._id + '.pdf');
        // setTimeout('window.close()', 2000);
      });
  }

  function getDate(value) {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  }

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="error">{error} </MessageBox>
  ) : (
    <div>
      {' '}
      <Button
        style={{
          float: 'right',
          height: '20%',
          width: '20%',
          marginTop: '30%',
        }}
        onClick={pdf}
      >
        Download PDF
      </Button>
      <Button
        variant="danger"
        style={{
          float: 'right',
          height: '20%',
          width: '20%',
          marginTop: '30%',
        }}
        onClick={() => {
          window.close();
        }}
      >
        Close
      </Button>
      <div
        id="myPage"
        style={{
          borderTop: '1rem solid #026634',

          minHeight: '11in',
          width: '8in',
          background: 'white',
        }}
      >
        <div className="book">
          <div className="page" id="myPage">
            <div className="subpageNN">
              <div className="card-header bg-white">
                <Row className={userInfo.isAdmin ? 'admin' : ''}>
                  <Col md={5} style={{ marginBottom: '10px' }}>
                    <img
                      src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                      style={{ height: '60px', marginLeft: '-140px' }}
                    />
                  </Col>
                  <Col md={7}>
                    <Row
                      style={{
                        height: '60px',
                        background: '#026634',
                        paddingTop: '10px',
                        color: 'white',
                        width: '450px',
                        position: 'relative',
                      }}
                    >
                      <Col md={4}>
                        <h4>INVOICE</h4>
                        {order.isPaid ? (
                          <img
                            src="/paidSeal.png"
                            style={{
                              height: '55px',
                              float: 'right',
                              marginTop: '-50px',
                              marginRight: '-150px',
                            }}
                          />
                        ) : (
                          <img
                            src="/dueSeal.png"
                            style={{
                              height: '55px',
                              float: 'right',
                              marginTop: '-50px',
                              marginRight: '-150px',
                            }}
                          />
                        )}
                      </Col>
                      <Col md={7} style={{ paddingTop: '10px' }}>
                        <p className="text-end">#{order._id.slice(-6)}</p>{' '}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} style={{ marginLeft: '-37px' }}>
                    <Row style={{ marginBottom: '10px' }}>
                      <img
                        src={order.userId.profilePhoto}
                        style={{
                          height: '84px',
                          width: '112px',
                          borderRadius: '30%',
                          border: '1px solid white',
                        }}
                      />
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Username </b>
                      <h6>{order.userId.username}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Name </b>
                      <h6>{order.userId.fullName}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Phone </b>
                      <h6>{order.userId.phone}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Address </b>
                      <h6>{order.userId.address}</h6>
                      <img src={order.userId.sign} style={{ height: '50px' }} />
                    </Row>
                  </Col>
                  <Col md={7} style={{ marginLeft: '41px' }}>
                    <Row
                      style={{
                        height: '60px',
                        background: '#d2f9e2',
                        paddingTop: '20px',
                        marginLeft: '95px',
                        marginTop: '-26px',
                        width: '450px',
                      }}
                    >
                      <Col md={7}>
                        <b> Invoice Date</b>
                        <h6>{getDate(order.createdAt)}</h6>
                      </Col>
                      <Col md={4} className="text-end">
                        <b>Due Date</b>
                        <h6>{getDate(order.createdAt)}</h6>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        background: '#d2f9e2',
                        marginTop: '10px',
                        marginLeft: '95px',
                        width: '450px',
                      }}
                    >
                      <table className="table table-responsive">
                        <thead>
                          <tr
                            style={{
                              fontWeight: '600',
                              borderBottom: '1px solid black',
                            }}
                          >
                            <td className="text-center" colSpan={3}>
                              Description
                            </td>
                            <td>Point</td>
                            <td>Total</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={3}>
                              {order.withdrawsource === 'matching'
                                ? 'Group Sales Commission'
                                : 'Refer Commission'}
                            </th>
                            <th>{order.amount + order.charge}</th>
                            <th>
                              {parseInt(order.amount + order.charge) * 12}
                            </th>
                          </tr>

                          <tr>
                            <th>{}</th>

                            <th colSpan={2}>Charge</th>
                            <th>{order.charge}</th>
                            <th>{order.charge * 12}</th>
                          </tr>
                          <tr style={{ background: '#026634', color: 'white' }}>
                            <th>{}</th>
                            <th>{}</th>
                            <th>Total</th>
                            <th>{}</th>
                            <th>{order.amount * 12}</th>
                          </tr>
                          <tr>
                            <th colSpan={2}>
                              Payment Method <br></br>{' '}
                              {order.paymentmethod +
                                '(' +
                                order.userId.bankaccount +
                                ')'}{' '}
                            </th>

                            <th>{order.status ? 'Paid' : 'Not Paid'}</th>
                            <th>{}</th>
                            <th>{order.amount * 12}</th>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  </Col>
                </Row>

                <Row
                  style={{
                    width: '80%',
                    textAlign: 'center',
                    marginLeft: '30%',
                    marginTop: '-13%',
                    padding: '5px',
                    position: 'relative',
                    bottom: 0,
                  }}
                >
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Receiver
                  </Col>
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Accounts
                  </Col>
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Director
                  </Col>
                </Row>
                <div
                  style={{
                    background: '#026634',
                    color: 'white',
                    margin: 'auto',
                    padding: '2px',
                    width: '123.5%',
                    marginLeft: '-12%',
                  }}
                >
                  <h6 className="text-center">
                    JA-80/b, Bir Uttam A.K Khondokar Road, Badda Link Road,
                    Badda. Dhaka
                  </h6>
                </div>
              </div>
            </div>
            <div className="subpageNN" style={{ marginTop: '0.3in' }}>
              <div className="card-header bg-white">
                <Row className={userInfo.isAdmin ? 'admin' : ''}>
                  <Col md={5} style={{ marginBottom: '10px' }}>
                    <img
                      src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                      style={{ height: '60px', marginLeft: '-140px' }}
                    />
                  </Col>
                  <Col md={7}>
                    <Row
                      style={{
                        height: '60px',
                        background: '#026634',
                        paddingTop: '10px',
                        color: 'white',
                        width: '450px',
                        position: 'relative',
                      }}
                    >
                      <Col md={4}>
                        <h4>INVOICE</h4>
                        {order.isPaid ? (
                          <img
                            src="/paidSeal.png"
                            style={{
                              height: '55px',
                              float: 'right',
                              marginTop: '-50px',
                              marginRight: '-150px',
                            }}
                          />
                        ) : (
                          <img
                            src="/dueSeal.png"
                            style={{
                              height: '55px',
                              float: 'right',
                              marginTop: '-50px',
                              marginRight: '-150px',
                            }}
                          />
                        )}
                      </Col>
                      <Col md={7} style={{ paddingTop: '10px' }}>
                        <p className="text-end">#{order._id.slice(-6)}</p>{' '}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} style={{ marginLeft: '-37px' }}>
                    <Row style={{ marginBottom: '10px' }}>
                      <img
                        src={order.userId.profilePhoto}
                        style={{
                          height: '84px',
                          width: '112px',
                          borderRadius: '30%',
                          border: '1px solid white',
                        }}
                      />
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Username </b>
                      <h6>{order.userId.username}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Name </b>
                      <h6>{order.userId.fullName}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Phone </b>
                      <h6>{order.userId.phone}</h6>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                      <b> Address </b>
                      <h6>{order.userId.address}</h6>
                      <img src={order.userId.sign} style={{ height: '50px' }} />
                    </Row>
                  </Col>
                  <Col md={7} style={{ marginLeft: '41px' }}>
                    <Row
                      style={{
                        height: '60px',
                        background: '#d2f9e2',
                        paddingTop: '20px',
                        marginLeft: '95px',
                        marginTop: '-10px',
                        width: '450px',
                      }}
                    >
                      <Col md={7}>
                        <b> Invoice Date</b>
                        <h6>{getDate(order.createdAt)}</h6>
                      </Col>
                      <Col md={4} className="text-end">
                        <b>Due Date</b>
                        <h6>{getDate(order.createdAt)}</h6>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        background: '#d2f9e2',
                        marginTop: '10px',
                        marginLeft: '95px',
                        width: '450px',
                      }}
                    >
                      <table className="table table-responsive">
                        <thead>
                          <tr
                            style={{
                              fontWeight: '600',
                              borderBottom: '1px solid black',
                            }}
                          >
                            <td className="text-center" colSpan={3}>
                              Description
                            </td>
                            <td>Point</td>
                            <td>Total</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th colSpan={3}>
                              {order.withdrawsource === 'matching'
                                ? 'Group Sales Commission'
                                : 'Refer Commission'}
                            </th>
                            <th>{order.amount + order.charge}</th>
                            <th>
                              {parseInt(order.amount + order.charge) * 12}
                            </th>
                          </tr>

                          <tr>
                            <th>{}</th>

                            <th colSpan={2}>Charge</th>
                            <th>{order.charge}</th>
                            <th>{order.charge * 12}</th>
                          </tr>
                          <tr style={{ background: '#026634', color: 'white' }}>
                            <th>{}</th>
                            <th>{}</th>
                            <th>Total</th>
                            <th>{}</th>
                            <th>{order.amount * 12}</th>
                          </tr>
                          <tr>
                            <th colSpan={2}>
                              Payment Method <br></br>{' '}
                              {order.paymentmethod +
                                '(' +
                                order.userId.bankaccount +
                                ')'}{' '}
                            </th>

                            <th>{order.status ? 'Paid' : 'Not Paid'}</th>
                            <th>{}</th>
                            <th>{order.amount * 12}</th>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  </Col>
                </Row>

                <Row
                  style={{
                    width: '80%',
                    textAlign: 'center',
                    marginLeft: '30%',
                    marginTop: '-13%',
                    padding: '5px',
                    position: 'relative',
                    bottom: 0,
                  }}
                >
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Receiver
                  </Col>
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Accounts
                  </Col>
                  <Col md={4}>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/2048px-Solid_white.svg.png"
                      style={{ height: '50px' }}
                    />
                    <hr></hr>
                    Director
                  </Col>
                </Row>
                <div
                  style={{
                    background: '#026634',
                    color: 'white',
                    margin: 'auto',
                    padding: '2px',
                    width: '123.5%',
                    marginLeft: '-12%',
                  }}
                >
                  <h6 className="text-center">
                    JA-80/b, Bir Uttam A.K Khondokar Road, Badda Link Road,
                    Badda. Dhaka
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
