import React, { useContext, useEffect, useState } from 'react';
import CheckoutSteps from '../component/CheckoutSteps';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function PaymentMethodScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { shippingAddress },
  } = state;
  useEffect(() => {
    if (!shippingAddress.fullName) {
      toast.error('Please Enter Shipping Address First.');
      navigate('/shipping');
    }
  }, [shippingAddress, navigate]);

  const [Method, setMethod] = useState('cod');

  const submitHandler = (e) => {
    // e.PreventDefault();
    ctxDispatch({
      type: 'SAVE_PAYMENT_METHOD',
      payload: Method,
    });
    localStorage.setItem('paymentMethod', Method);
    navigate('/placeorder');
  };

  function gotoHome() {
    navigate('/shipping');
  }

  return (
    <div>
      <Helmet>
        <title>Payment </title>
      </Helmet>
      <Container className="my-3" style={{ width: '80%', margin: 'auto' }}>
        <div className="card">
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            {' '}
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Select Payment Method
          </div>
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <div className="mb-3">
                <Form.Check
                  type="radio"
                  id="bKash"
                  className="d-none"
                  label="Pay with bKash"
                  value="bKash"
                  checked={Method === 'bKash'}
                  onChange={(e) => setMethod(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type="radio"
                  id="cod"
                  label="Cash On Delivery"
                  value="cod"
                  checked={Method === 'cod'}
                  onChange={(e) => setMethod(e.target.value)}
                ></Form.Check>
              </div>
              <div className="mb-3 ">
                <Button type="submit" variant="outline-success w-100">
                  Submit Payment
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
