import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate} from 'react-router-dom';
import {Store} from '../Store';
import {toast} from 'react-toastify';
import {api} from "../Api";
import '../component/tree.css';

var clicked = new Array();


export default function UserListScreen() {
    const {state} = useContext(Store);
    const {userInfo} = state;

    const navigate = useNavigate();

    const location = useLocation();
    const [username, setUsername] = useState(userInfo && userInfo.username);

    const [left, setLeft] = useState('');
    const [right, setRight] = useState('');
    const [root, setroot] = useState('');

    useEffect(() => {
        if (userInfo) {
            const placement = new URLSearchParams(location.search).get('placement');
            if (placement) {
                setUsername(atob(placement));
                nodeuserroot(atob(placement));
                nodeusersright(atob(placement));
                nodeusersleft(atob(placement));
            } else {
                nodeuserroot(username);
                nodeusersright(username);
                nodeusersleft(username);
            }

        } else {
            window.alert('please login');
        }
    }, [userInfo, username, location]);

  const [rootdata, setrootdata] = useState({});
  const nodeuserroot = async (username) => {
    try {
      const { data } = await axios.post(
        api+`/api/users/nodeusersroot`,
        { username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      if (data) {
        setroot(data.username);
        setrootdata(data);
      } else {
        setroot('Null');
        setrootdata('Null');
      }
    } catch (err) {
     // window.alert(getError(err));
    }
  };
  const [rdata, setRdata] = useState({});
  const nodeusersright = async (username) => {
    try {
      const { data } = await axios.post(
        api + `/api/users/nodeusersright`,
        { username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      if (data) {
        setRight(data.username);
        setRdata(data);
      } else {
        setRight('Null');
        setRdata('Null');
      }
    } catch (err) {
      // window.alert(getError(err));
    }
  };

  const [ldata, setLdata] = useState({});

  const nodeusersleft = async (username) => {
    try {
      const { data } = await axios.post(
        api + `/api/users/nodeusersleft`,
        { username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      if (data) {
        setLeft(data.username);
        setLdata(data);
      } else {
        setLeft('Null');
        setLdata('Null');
      }
    } catch (err) {
      // window.alert(getError(err));
    }
  };
  const redirectOAdduser = (placement, position) => {
    navigate('/adduser', {
      state: { placement: placement, position: position },
      replace: false,
    });
  };

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(0);

  const doSearch = async (search) => {
      setLoading(1);
    try {
      const { data } = await axios.post(
        api+`/api/users/search`,
        { root: userInfo.username, placement: search },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
        if (data && data.isChild) {
            if (userInfo.isAdmin) {
                setUsername(search);
            } else {
                setUsername(search);
                //comparePlacement(data.user.placement);
                setLdata(0);
            }
        } else {
            toast.error('User is not your team member.');
        }
    } catch (error) {
        // toast.error('No User Found');
    }
      setLoading(0);
  };

  function comparePlacement(data) {
    if (userInfo.username === data) {
      setLdata(0);
      setUsername(search);
      setLoading(0);
    } else {
      doSearch(data);
    }
  }

  function goback(user) {
    const length = clicked.length - 1;
    setUsername(clicked[length]);
    clicked.pop();
  }

  function gonext(user) {
    clicked.push(user.placement);
    setUsername(user.username);
  }

  function gotoHome() {
    navigate('/user-panel');
  }

  function getClassName(date) {
    const createdAtDate = new Date(date);

    // Date to compare against (December 18, 2022)
    const comparisonDate = new Date('2022-12-18T00:00:00.000Z');

    // Check if createdAtDate is older than the comparisonDate
    const isOlder = createdAtDate < comparisonDate;

    // Set the className based on the comparison
    return isOlder ? 'text-danger' : '';
  }

  return (
      <div>
        <Helmet>
          <title>Sales Team Maintenance </title>
        </Helmet>
        <Container className="no-gutter" style={{}}>
          <div className="card ">
            <Row>
              <Col style={{textAlign: 'center'}}>
                <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Sales Team Maintenance
          </div>
          <Row style={{ width: '99%', margin: 'auto', paddingTop: '1rem' }}>
            <Col>
              <span
                className="btn"
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  background: 'white',
                }}
                onClick={() => setUsername(userInfo.username)}
              >
                {username ? (
                  <i class="fa fa-user" aria-hidden="true"></i>
                ) : (
                  'Username'
                )}
              </span>

              {clicked.length > 0 ? (
                <span
                  className="btn"
                  style={{
                    cursor: 'pointer',
                    background: 'white',
                    marginLeft: '1rem',
                  }}
                  onClick={() => goback()}
                >
                  <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                </span>
              ) : (
                ''
              )}
            </Col>
            <Col>
              <div className="float-right" style={{}}>
                <input
                  style={{ width: '70%' }}
                  className="search"
                  id="search"
                  type="search"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Username"
                />
                <Button type="button" onClick={() => doSearch(search)}>
                  {loading ? (
                    <span
                      class="spinner-border spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <i class="fa fa-search" aria-hidden="true"></i>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
          <div className="card-body" style={{ margin: 'auto' }}>
            <div class="tree" style={{ margin: 'auto' }}>
              <ul>
                <li>
                  <div
                    id="rootdata"
                    style={{
                      margin: 'auto',
                      background: '#42b4e7',
                      padding: '1rem',
                    }}
                  >
                    <Row>
                      <img
                        variant="top"
                        style={{
                          height: '80px',
                          width: '100px',
                          margin: 'auto',
                          marginTop: '1rem',
                          borderRadius: '20%',
                        }}
                        src={
                          rootdata.profilePhoto
                            ? rootdata.profilePhoto
                            : 'https://cdn-icons-png.flaticon.com/512/2521/2521826.png'
                        }
                      />
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Full Name
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.name}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Username
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        <span className={getClassName(rootdata.createdAt)}>{rootdata.username}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Reference
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.reference}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Number
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.phone}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Joining Date
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {' '}
                        {rootdata.createdAt
                          ? rootdata.createdAt.substring(0, 10)
                          : ''}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Current Position
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.designation}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Sales Team A
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.leftuser}
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        Sales Team B
                      </Col>
                      <Col style={{ maxWidth: '10%', padding: '0' }}>:</Col>
                      <Col style={{ maxWidth: '45%', padding: '0' }}>
                        {rootdata.rightuser}
                      </Col>
                    </Row>
                  </div>
                  <ul>
                    <li
                      style={{ width: '50%', float: 'left' }}
                      onClick={() =>
                        left === 'Null'
                          ? redirectOAdduser(username, 'left')
                          : gonext(ldata)
                      }
                    >
                      <div
                        id="left"
                        style={{
                          margin: 'auto',
                          background: '#42b4e7',
                          padding: '1rem',
                          textAlign: 'left',
                        }}
                      >
                        <Row>
                          <Col style={{ padding: '0px' }}>
                            <h2
                              className="text-center"
                              style={{
                                background: '#68d4f4',
                                marginBottom: '0',
                              }}
                            >
                              Sales Team A{' '}
                            </h2>
                          </Col>
                        </Row>
                        <Row>
                          <img
                            variant="top"
                            style={{
                              height: '80px',
                              width: '100px',
                              margin: 'auto',
                              marginTop: '1rem',
                              borderRadius: '20%',
                            }}
                            src={
                              left !== 'Null'
                                ? ldata.profilePhoto
                                : 'https://cdn-icons-png.flaticon.com/512/2521/2521826.png'
                            }
                          />
                        </Row>
                        <div id="ldata" className={ldata.name ? '' : 'd-none'}>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Full Name
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {ldata.name}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Username
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              <span className={getClassName(ldata.createdAt)}>{ldata.username}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Reference
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {ldata.reference}
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Joining
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {ldata.createdAt
                                ? ldata.createdAt.substring(0, 10)
                                : ''}
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              S Team A
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {ldata.leftuser}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              S Team B
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {ldata.rightuser}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </li>
                    <li
                      style={{ width: '50%', float: 'right' }}
                      onClick={() =>
                        right === 'Null'
                          ? redirectOAdduser(username, 'right')
                          : setUsername(right)
                      }
                    >
                      <div
                        id="right"
                        style={{
                          margin: 'auto',
                          background: '#42b4e7',
                          padding: '1rem',
                          textAlign: 'left',
                        }}
                      >
                        <Row>
                          <Col style={{ padding: '0px' }}>
                            <h2
                              className="text-center"
                              style={{
                                background: '#68d4f4',
                                marginBottom: '0',
                              }}
                            >
                              Sales Team B{' '}
                            </h2>
                          </Col>
                        </Row>

                        <Row>
                          <img
                            variant="top"
                            style={{
                              height: '80px',
                              width: '100px',
                              margin: 'auto',
                              marginTop: '1rem',
                              borderRadius: '20%',
                            }}
                            src={
                              right !== 'Null'
                                ? rdata.profilePhoto
                                : 'https://cdn-icons-png.flaticon.com/512/2521/2521826.png'
                            }
                          />
                        </Row>
                        <div id="rdata" className={rdata.name ? '' : 'd-none'}>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Full Name
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {rdata.name}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Username
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              <span className={getClassName(rdata.createdAt)}>{right}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Reference
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {rdata.reference}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              Joining
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {rdata.createdAt
                                ? rdata.createdAt.substring(0, 10)
                                : ''}
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              S Team A
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {rdata.leftuser}
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              S Team B
                            </Col>
                            <Col style={{ maxWidth: '10%', padding: '0' }}>
                              :
                            </Col>
                            <Col style={{ maxWidth: '45%', padding: '0' }}>
                              {rdata.rightuser}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
