import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet-async';
import {Link, useNavigate} from 'react-router-dom';

export default function TransferandHistory() {
  const navigate = useNavigate();

  function gotoHome() {
    navigate('/user-panel');
  }
  return (
    <div>
      <Helmet>
        <title>Transfer & History </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Transfer & History
          </div>

          <div className="card-body" style={{ margin: 'auto' }}></div>
          <Link to="/convert" className="inbody-button mb-3">
            Convert
          </Link>
          <Link to="/transfer" className="inbody-button mb-3">
            Transfer
          </Link>
          <Link to="/history" className="inbody-button mb-3">
            History
          </Link>
          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
