import Alert from 'react-bootstrap/Alert';
export default function MessageBox(props) {
  return (
    <Alert
      style={{ width: '60%', margin: 'auto' }}
      variant={props.variant || 'info'}
    >
      {props.children}
    </Alert>
  );
}
