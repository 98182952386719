import Product from "../../global/product/Product";
import cl from "./Products.module.css";
import {useEffect, useState} from "react";
import axios from "axios";
import {api} from "../../../../Api";

function Products() {

	const [newArrival, setNewArrival] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const result = await axios.get(api + "/api/products/web?limit=4");
				setNewArrival(result?.data||[]);
			} catch (error) {
				// alert(error)
			}
		};
		fetchData();
	}, []);

	return (
		<div className={cl.container}>
			{newArrival?.length ? newArrival.map((p) => (
				<Product product={p}/>
			)) : (
				<div className="alert alert-danger w-100 text-center"> Oops! Looks like we're having trouble connecting
					to the internet. Our tech team is on it. Please check your connection and try again </div>)}

		</div>
	);
}

export default Products;
