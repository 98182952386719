import React, { useContext } from 'react';
import Table from 'react-bootstrap/esm/Table';
import { Store } from '../Store';
import Accordion from 'react-bootstrap/Accordion';
import './sidebar.css';
import { Link } from 'react-router-dom';

export default function AdminSideBar() {
  const { state, dispatch: ctxDispatch } = useContext(Store);

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    // window.location.href = '/signin';
    window.location.reload();
  };

  return (
    <div
      id="adminSideBar"
      style={{
        width: '12%',
        background: '#42b4e7',
        height: '100%',
        float: 'left',
      }}
    >
      <Table>
        <thead>
          <tr>
            <td>
              <a href="/#/admin/dashboard" className="navbar-brand">
                <img
                  src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                  height="30px"
                  alt="Icon marketing "
                  style={{ background: 'white', borderRadius: '15px' }}
                />
              </a>
            </td>
          </tr>
        </thead>
      </Table>
      <Accordion style={{ width: '90%', margin: 'auto' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-cogs"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Settings
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/config">General </Link>
              </li>
              <li>
                <Link to="/admin/designationlist">Commission </Link>
              </li>
              <li>
                <Link to="/admin/globalsales">Global Sales </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-bell"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Manage Notices
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/notices">Manage Notices </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="16">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-comments"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Manage Chat
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/chatlist">Manage Chat </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-user"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Manage Users
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/users">Manage Users </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-bath"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Promotion History
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/positionhistory">Promotion History </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-user-secret"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Agents
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/agents">Manage Agents </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-bar-chart"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Transactions
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/pointlog">Point Transfer </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="05">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-tachometer"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} History
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/pointlog">Point Transfer History </Link>
              </li>
              <li>
                <Link to="/admin/withdraw">Withdraw History </Link>
              </li>
              <li>
                <Link to="/admin/availablebalance">Transaction History </Link>
              </li>
              <li>
                <Link to="/admin/global">Global History </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="06">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-random"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Commission Dist.
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/commissions">Commission Dist. </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="07">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-tasks"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Manage Orders
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/orders">Manage Orders </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="08">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-gift"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Products
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/admin/products">Manage Products </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="09" onClick={signoutHandler}>
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-sign-out"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Logout
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
