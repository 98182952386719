import cl from "./Quotes.module.css";
import leftQuote from "../../../assets/icons/quotes/quote-left-solid.svg";
import rightQuote from "../../../assets/icons/quotes/quote-right-solid.svg";

function Quote({ quoteObj }) {
  return (
    <div className={cl.quote}>
      <div className={cl.image}>
        <img src={quoteObj.image} alt="Icon" />
      </div>
      <div className={cl.text_info}>
        <div className={cl.text}>
          <p>
            <img src={leftQuote} alt="Icon" />
            {quoteObj.quote}
            <img src={rightQuote} alt="Icon" />
          </p>
        </div>
        <div className={cl.info}>
          <p>{quoteObj.name}</p>
          <p>{quoteObj.info}</p>
        </div>
      </div>
    </div>
  );
}

export default Quote;
