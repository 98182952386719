import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import '../component/tree.css';
import { api } from "../Api";
var clicked = new Array();


export default function TeamSales() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const navigate = useNavigate();

  let city = new URLSearchParams(window.location.search).get('placement');
  let userid = new URLSearchParams(window.location.search).get('userid');
  if (!city) {
    city = userInfo.username;
  }

  const [username, setUsername] = useState(city);

  const [root, setroot] = useState('');

  useEffect(() => {
    if (userInfo) {
      nodeuserroot(username);
    } else {
      window.alert('please login');
    }
  }, [userInfo, username]);

  const [rootdata, setrootdata] = useState({});
  const nodeuserroot = async (username) => {
    try {
      const { data } = await axios.post(
        api+`/api/users/nodeusersroot`,
        { username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      if (data) {
        setroot(data.username);
        setrootdata(data);
      } else {
        setroot('Null');
        setrootdata('Null');
      }
    } catch (err) {
      window.alert(getError(err));
    }
  };

  function gotoHome() {
    navigate('/user-panel');
  }

  function getPositiveNumber(v1, v2) {
    return Math.abs(v1 - v2);
  }
  return (
    <div>
      <Helmet>
        <title> Sales Team</title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card ">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i className="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Sales Team
          </div>

          <div className="card-body" style={{ margin: 'auto' }}>
            <div className="tree" style={{ margin: 'auto' }}>
              <table
                className="table table-responsive"
                style={{
                  margin: 'auto',

                  padding: '1rem',
                  textAlign: 'center',
                }}
              >
                <tr>
                  <td>
                    <div style={{ background: '#42b4e7', margin: '8%' }}>
                      <h2>Team Sales A</h2> <hr></hr>
                      <h2>{rootdata.leftuser}</h2>
                    </div>
                  </td>
                  <td>
                    <div style={{ background: '#42b4e7', margin: '8%' }}>
                      <h2>Team Sales B </h2>
                      <hr></hr>
                      <h2>{rootdata.rightuser}</h2>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div style={{ background: '#42b4e7', margin: '8%' }}>
                      <h2>My Credit</h2> <hr></hr>{' '}
                      <h2>
                        {getPositiveNumber(
                          rootdata.rightuser,
                          rootdata.leftuser
                        )}
                      </h2>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
