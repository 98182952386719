import React, {useContext, useEffect, useReducer} from 'react';
import {Helmet} from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Axios from 'axios';
import {getError} from '../../utils';
import Button from 'react-bootstrap/Button';
import {api} from "../../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, orders: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function UserReportScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: userid } = params;

  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await Axios.post(
          api + `/api/orders/admin/userorder`,
          { _id: userid },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchData();
  }, [userInfo]);

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="error">{error} </MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Shopping Report </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <div className="card-header">Shopping Report</div>
          <div className="card-body" style={{ margin: 'auto' }}></div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Order Date</th>
                  <th>Total Amount</th>
                  <th>Payment Status</th>
                  <th>Delivery Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={6}>
                      No Order Yet. <Link to={'/'}>Continue Shopping</Link>{' '}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id.slice(-4)}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice.toFixed(2)}</td>
                    <td>
                      {order.isPaid
                        ? 'Paid at ' + order.paidAt.substring(0, 10)
                        : 'Pending'}
                    </td>
                    <td>
                      {order.isDelivered
                        ? 'Delivered at ' + order.deliveredAt.substring(0, 10)
                        : 'Pending'}
                    </td>
                    <td>
                      <Button
                        type="button"
                        variant="outline-success"
                        onClick={() => {
                          navigate(`/order/${order._id}`);
                        }}
                      >
                        Details
                      </Button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
}
