import React, { useContext } from 'react';
import Table from 'react-bootstrap/esm/Table';
import { Store } from '../Store';
import Accordion from 'react-bootstrap/Accordion';
import './sidebar.css';
import { Link } from 'react-router-dom';

export default function AgentSideBar() {
  const { state, dispatch: ctxDispatch } = useContext(Store);

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    // window.location.href = '/signin';
    window.location.reload();
  };

  return (
    <div
      style={{
        width: '12%',
        background: '#42b4e7',
        height: '100vh',
        float: 'left',
      }}
    >
      <Table>
        <thead>
          <tr>
            <td>
              <a href="/agent/dashboard" class="navbar-brand">
                <img
                  src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                  height="30px"
                  alt="Icon marketing "
                  style={{ background: 'white', borderRadius: '15px' }}
                />
              </a>
            </td>
          </tr>
        </thead>
      </Table>
      <Accordion style={{ width: '90%', margin: 'auto' }}>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-tachometer"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} General
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/agent/dashboard">Dashboard</Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-bar-chart"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Transactions
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/agent/pointlog">Point Transfer & History </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="07">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-tasks"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Orders
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/agent/orders">Manage Orders </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="05">
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-dollar"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Withdraw
          </Accordion.Header>
          <Accordion.Body>
            <ul>
              <li>
                <Link to="/agent/withdraw">Manage Withdraw </Link>
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="09" onClick={signoutHandler}>
          <Accordion.Header className="sidebar-menu">
            <i
              className="fa fa-sign-out"
              style={{ paddingRight: '10px' }}
              aria-hidden="true"
            ></i>{' '}
            {'  '} Logout
          </Accordion.Header>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
