import { NavLink } from "react-router-dom";
import cl from "./Header.module.css";

function Nav() {
  return (
    <ul>
      <li>
        <NavLink
          to={"/"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/signin"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          Shop
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/signin"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          Team
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/signin"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          Earn
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/signin"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          About
        </NavLink>
      </li>
      <li>
        <NavLink
          to={"/signin"}
          className={({ isActive }) => (isActive ? cl.active : "")}
        >
          Contact
        </NavLink>
      </li>
    </ul>
  );
}

export default Nav;
