import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { api } from "../Api";


export default function ProfileScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const myId = userInfo._id;
  const [mypoint, setMypoint] = useState('');
  const [availablebalance, setavailablebalance] = useState('');
  const [totalRight, setTotalRight] = useState(0);
  const [totalLeft, settotalLeft] = useState(0);

  const fetchData = async (id) => {
    try {
      const { data } = await Axios.get(
        api+`/api/users/point/${myId}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      setMypoint(data.point);
      setavailablebalance(data.availablebalance);
      settotalLeft(data.totalLeft);
      setTotalRight(data.totalRight);
    } catch (error) {
      // window.alert(error);
    }
  };

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const submitHandler = async () => {
    if (newPassword && confirmNewPassword) {
      if (newPassword === confirmNewPassword) {
        try {
          await Axios.put(
            api+'/api/users/changepassword',
            {
              newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          toast.success('Password Changed.');
        } catch (error) {
          toast.error(getError(error));
        }
      } else {
        toast.error('New Passowrd does not match with Confirm New Password');
      }
    } else {
      toast.error('Please Enter Confirm Password');
    }
  };

  useEffect(() => {
    fetchData(myId);
  }, [userInfo, myId]);

  const navigate = useNavigate();

  function gotoHome() {
    navigate('/user-panel');
  }
  return (
    <div>
      <Helmet>
        <title>Profile | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="small-container">
        <div className="card mb-3">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Profile{' '}
          </div>
          <div className="card-body">
            <table className="table table-responsive">
              <thead>
                <tr>
                  <td>Full Name</td>
                  <td>:</td>
                  <td>{userInfo.name}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Username</td>
                  <td>:</td>
                  <td>{userInfo.username}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>:</td>
                  <td>{userInfo.phone}</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>:</td>
                  <td>{userInfo.email}</td>
                </tr>
                <tr>
                  <td>Reference</td>
                  <td>:</td>
                  <td>{userInfo.reference}</td>
                </tr>
                <tr>
                  <td>Point</td>
                  <td>:</td>
                  <td>{mypoint > 499 ? mypoint - 500 : 0} </td>
                </tr>
                <tr>
                  <td>Available Balance</td>
                  <td>:</td>
                  <td>{availablebalance} </td>
                </tr>
                <tr>
                  <td>Team Left</td>
                  <td>:</td>
                  <td>{totalLeft}</td>
                </tr>
                <tr>
                  <td>Team Right</td>
                  <td>:</td>
                  <td>{totalRight}</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td>:</td>
                  <td>{totalRight + totalLeft}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-header text-center">Change Password </div>
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="d-grid">
                <Button variant="outline-success" onClick={submitHandler}>
                  Change Password
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
