import {useEffect, useReducer, useState} from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Helmet} from 'react-helmet-async';
import Product from '../component/Product';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import Slider from '../ecommerce/Slider';
import Footer from '../ecommerce/Footer';
import Header from '../ecommerce/Header';
import {api} from '../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    products: [],
  });

  // const [newArrival, setNewArrival] = useState([]);
  // const [featureProducts, setfeatureProducts] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     dispatch({ type: 'FETCH_REQUEST' });
  //     try {
  //         const result = await axios.get(api + "/api/products/web");
  //         if (result) {
  //             dispatch({type: 'FETCH_SUCCESS', payload: result.data});
  //             setNewArrival(result.data.slice(0, 6));
  //             setfeatureProducts(result.data.slice(6, 20));
  //         }
  //     } catch (error) {
  //         dispatch({type: 'FETCH_FAILED', payload: error.message});
  //     }
  //   };
  //     fetchData();
  // }, [newArrival, featureProducts]);
  return (
		<>
			<div>
				<Helmet>
					<title>Icon Marketing</title>
				</Helmet>
				<Header />

				<Row className="w-80 my-3" style={{ maxWidth: "90%", margin: "auto" }}>
					<Slider />
				</Row>
				<h3
					className="text-left"
					style={{ maxWidth: "90%", margin: "auto", paddingBottom: "1rem" }}
				>
					New Arrivals
				</h3>
				<div className="products" style={{ maxWidth: "90%", margin: "auto" }}>
					{loading ? (
						<LoadingBox />
					) : error ? (
						<MessageBox variant="danger">{error}</MessageBox>
					) : (
						<>
							{/* <Row>
								{newArrival &&
									newArrival.map((product) => (
										<Col
											key={product.slug}
											className="mb-5 col-xs-3 col-sm-2 col-lg-2 col-md-2"
										>
											<Product product={product} />
										</Col>
									))}
							</Row> */}
						</>
					)}
					<img src="./images/ad-1.jpeg" alt="" className="w-100 mb-4"></img>
				</div>

				<h3
					className="text-left"
					style={{ maxWidth: "90%", margin: "auto", paddingBottom: "1rem" }}
				>
					Feature Products
				</h3>
				{/* <div className="products" style={{ maxWidth: '90%', margin: 'auto' }}>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{error}</MessageBox>
          ) : (
            <>
              <Row>
                  {featureProducts && featureProducts.map((product) => (
                      <Col
                          key={product.slug}
                          className="mb-5 col-xs-3 col-sm-2 col-lg-2 col-md-2"
                      >
                          <Product product={product}/>
                      </Col>
                  ))}
              </Row>
            </>
          )}
        </div> */}

				{/* <div className="products" style={{ maxWidth: "90%", margin: "auto" }}>
					{loading ? (
						<LoadingBox />
					) : featureProducts && featureProducts.length  ? (
						<>
							<Row>
								{featureProducts &&
									featureProducts.map((product) => (
										<Col
											key={product.slug}
											className="mb-5 col-xs-3 col-sm-2 col-lg-2 col-md-2"
										>
											<Product product={product} />
										</Col>
									))}
							</Row>
						</>
					) : (
						<MessageBox variant="danger">{error}</MessageBox>
					) }
				</div> */}
			</div>
			<Footer />
		</>
	);
}

export default HomeScreen;
