import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import Header from '../ecommerce/Header';

export default function ShippingAddressScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    userInfo,
    cart: { shippingAddress },
  } = state;

  useEffect(() => {
    if (!userInfo) {
      navigate('/signin?redirect=/shipping');
    }
  }, [userInfo, navigate]);

  const [delivery_method, setdelivery_method] = useState('home_delivery');
  const [fullName, setfullName] = useState(shippingAddress.fullName || '');
  const [streetAddress, setstreetAddress] = useState(
    shippingAddress.streetAddress || ''
  );
  const [city, setcity] = useState(shippingAddress.city || '');
  const [phone, setphone] = useState(shippingAddress.phone || '');

  const submitHandler = (e) => {
    // e.PreventDefault();
    ctxDispatch({
      type: 'SAVE_SHIPPING_ADDRESS',
      payload: {
        delivery_method,
        fullName,
        streetAddress,
        city,
        phone,
      },
    });
    localStorage.setItem(
      'shippingAddress',
      JSON.stringify({
        delivery_method,
        fullName,
        streetAddress,
        city,
        phone,
      })
    );
    navigate('/placeorder');
  };

  return (
    <div>
      <Helmet>
        <title>Delivery Address | Icon Marketing.</title>
      </Helmet>
      <Header />
      <Container
        className="w-80 "
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <div className="card bg-white">
          <div className="card-header bg-white ">Confirm Shipping Address</div>
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <div>
                <Form.Group className="mb-3">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="fullName"
                    value={fullName}
                    onChange={(e) => setfullName(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="streetAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    value={streetAddress}
                    onChange={(e) => setstreetAddress(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City </Form.Label>
                  <Form.Control
                    value={city}
                    type="text"
                    onChange={(e) => setcity(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
              </div>
              <div className="mb-3 ">
                <Button type="submit" variant="outline-success w-100">
                  Save & Continue
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
