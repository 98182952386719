import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
export default function SelectGeneration() {
	const navigate = useNavigate();

	function gotoHome() {
		navigate("/transferandhistory");
	}
	return (
		<div>
			<Helmet>
				<title>Select Generation </title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i class="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						History
					</div>
					<div className="card-body" style={{ margin: "auto" }}></div>
					<Link to="/incomegenerationhistory/1" className="inbody-button mb-3">
						First Generation
					</Link>
					<Link to="/incomegenerationhistory/2" className="inbody-button mb-3">
						Second Generation
					</Link>
					<Link to="/incomegenerationhistory/3" className="inbody-button mb-3">
						Third Generation
					</Link>
					<Link to="/incomegenerationhistory/4" className="inbody-button mb-3">
						Fourth Generation
					</Link>
					<Link to="/incomegenerationhistory/5" className="inbody-button mb-3">
						Fiveth Generation
					</Link>

					<div style={{ height: "31vh" }}> {""}</div>
				</div>
			</Container>
		</div>
	);
}
