import cl from "./Team.module.css";

import t1 from "../../../assets/team/1.png";
import t2 from "../../../assets/team/2.png";
import t3 from "../../../assets/team/3.png";
import t4 from "../../../assets/team/4.png";
import Card from "./Card";

function Team() {
  return (
    <div className={cl.container}>
      <Card
        img={t1}
        text={"Atik Ullah"}
        designation={"Founder & HO"}
        quote={"This is a placeholder for quotes of the team members"}
      />
      <Card
        img={t2}
        text={"Debu Datta"}
        designation={"Head of Training"}
        quote={"This is a placeholder for quotes of the team members"}
      />
      <Card
        img={t3}
        text={"Saif-Uz-Zaman"}
        designation={"Head of Purchase"}
        quote={"This is a placeholder for quotes of the team members"}
      />
      <Card
        img={t4}
        text={"Sajid Ahmed Badhon"}
        designation={"Head of Marketing"}
        quote={"This is a placeholder for quotes of the team members"}
      />
    </div>
  );
}

export default Team;
