import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { api } from "../Api";


export default function MyPromotionalHistory() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;

  function gotoHome() {
    navigate('/salesandpromotion');
  }
  const [history, sethistory] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/designation/mypromotionalhistory`,
        { username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      sethistory(data.history);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [userInfo]);

  return (
    <div>
      <Helmet>
        <title>My Promotional History | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '} My
            Promotional History
          </div>

          <div className="card-body" style={{ margin: 'auto' }}>
            <div className="card">
              <div className="card-header">Previous Status</div>
              <div className="card-body">
                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th>Promotion Name</th>
                      <th>Reward</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length > 0
                      ? history.map((product) => (
                          <tr key={product._id}>
                            <td>{product.position_name}</td>
                            <td>
                              <Badge> Received</Badge>
                            </td>

                            <td>{product.updated_at.substring(0, 10)}</td>
                          </tr>
                        ))
                      : 'No history'}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
