import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import Form from 'react-bootstrap/Form';
import {toast} from 'react-toastify';
import Badge from 'react-bootstrap/Badge';
import {getError} from '../../utils';
import {api} from "../../Api";


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function AdminPositionHistory() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  const fetchData = async (type) => {
    try {
      const { data } = await axios.get(
        api +
          `/api/designation/promotionhistory/admin?page=${page}&type=${type}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  async function view(id, username, serial) {
    try {
      const { data } = await axios.post(
        api + '/api/designation/approveposition',
        {
          history_id: id,
          username: username,
          position: serial,
          admin: userInfo.username,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.info(data.message);
      fetchData();
    } catch (error) {
      toast.error(getError(error));
    }
  }

  async function filterPosition(value) {
    fetchData(value);
  }

  const [username, setUsername] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function filter(username, startDate, endDate) {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.post(
        api + `/api/designation/promotionhistory/admin/filter`,
        {
          pages: page,
          username: username,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  }

  return (
    <div>
      <Helmet>
        <title>Promotion History </title>
      </Helmet>
      <Container className="">
        <Row className="my-3">
          <Col md={3}>
            <h2 className=" ">Promotion History | Total : {totalUsers} </h2>
          </Col>
          <Col md={6}>
            <Row
              style={{
                border: '1px solid blue ',
                padding: '5px',
                borderRadius: 10,
              }}
            >
              <Col md={4}>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Control
                    placeholder="Username"
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control
                    type="date"
                    placeholder="Start Date"
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                  value={endDate}
                />
              </Col>

              <Col md={6} style={{ margin: 'auto' }}>
                <div className="d-grid">
                  <Button
                    variant="outline-success"
                    onClick={() => filter(username, startDate, endDate)}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={3}>
            <select
              className="form-control"
              onChange={(e) => filterPosition(e.target.value)}
            >
              <option value={0}>All Position</option>
              <option value={1}>Development Officer</option>
              <option value={2}>Assistant Manager</option>
              <option value={3}>Deputy Manager </option>
              <option value={4}>Manager</option>
              <option value={5}>General Manager</option>
              <option value={6}>Ambassador</option>
              <option value={7}>Global Ambassador</option>
            </select>
          </Col>
        </Row>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div className={'table-responsive'} style={{}}>
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th>Username</th>
                    <th>Position</th>
                    <th>Carry</th>
                    <th>Created At</th>
                    <th>Reward</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product.username}</td>
                      <td>{product.position_name}</td>
                      <td>{product.carry_left + '/' + product.carry_right}</td>
                      <td>{product.createdAt}</td>
                      <td>
                        {product.reward === 'Pending' ? (
                          <Badge bg="danger">Pending</Badge>
                        ) : (
                          <Badge bg="success">Approved</Badge>
                        )}
                      </td>
                      <td>
                        {product.status === 'Pending' ? (
                          <Badge bg="danger">Pending</Badge>
                        ) : (
                          <Badge bg="success">Approved</Badge>
                        )}
                      </td>
                      <td>
                        <Button
                          variant={
                            product.status === 'Pending'
                              ? 'success'
                              : 'success disabled'
                          }
                          onClick={() =>
                            view(
                              product._id,
                              product.username,
                              product.position_serial
                            )
                          }
                        >
                          Approve
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {'Total : ' + totalUsers + ', Total Pages: ' + totalPages}
                <nav aria-label="..." style={{ float: 'right' }}>
                  <ul className="pagination">
                    <Link
                      className={
                        parseInt(page) - 2 < 1
                          ? 'page-link disabled'
                          : 'page-link'
                      }
                      to={`/admin/positionhistory?page=${parseInt(page) - 2}`}
                    >
                      Previous
                    </Link>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) - 1 < 1
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/positionhistory?page=${parseInt(page) - 1}`}
                      >
                        {parseInt(page) - 1}
                      </Link>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        {parseInt(page)}
                        <span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 1 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/positionhistory?page=${parseInt(page) + 1}`}
                      >
                        {parseInt(page) + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 2 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/positionhistory?page=${page + 2}`}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>

                {[...Array(pages).keys()].map((x) => (
                  <Link
                    style={{ display: 'none' }}
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/products?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
