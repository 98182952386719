import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Store } from '../Store';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import FloatingButton from '../component/FloatingButton';
import { api } from '../Api';
export default function UserPanelScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
    localStorage.removeItem('shippingAddress');
    localStorage.removeItem('paymentMethod');
    // localStorage.removeItem('token');
    // perform any other necessary cleanup or API calls
    window.location.reload();
    //  window.location.href = '/signin';
  };
  const [designation, setDesigntion] = useState('...');

  useEffect(() => {
    if (userInfo) {
      checkNotice();
      userInfo.designation === 0
        ? setDesigntion('Royal Customer')
        : getDesignation();
      checkDesignation();
    } else {
      window.location.href = '/signin';
    }
  }, [userInfo]);

  async function getDesignation() {
    try {
      const { data } = await axios.post(
        api + '/api/designation/getuserdesignation',
        { serial: userInfo.designation },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setDesigntion(data.name);
    } catch (error) {
      toast.error(getError(error));
    }
  }

  async function checkDesignation() {
    try {
      const data = await axios.post(
        api + '/api/users/getlivedata',
        { username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
    } catch (error) {
      toast.error(getError('CheckDesignation: ' + error));
    }
  }
  const [notice, setNotice] = useState(false);
  const [chat, setChat] = useState(0);
  async function checkNotice() {
    try {
      const { data } = await axios.post(
        api + '/api/users/checkNotice',
        { userInfo },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      console.log('CheckNotice: ' + data);
      setNotice(data.data);
      setChat(data.chat);
    } catch (error) {
      toast.error(getError('checkNotice: ' + error));
    }
  }

  function update() {
    userInfo.profilePhoto = 'Hiiiiiii';
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    window.alert('Alert ' + userInfo.profilePhoto);
  }
  return (
    <Container style={{ background: '#a8dcf4', height: '97.4vh' }}>
      <FloatingButton />
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <img
            src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
            style={{ width: '15rem', margin: '2rem' }}
            alt="Logo"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Row style={{ marginTop: '0.5rem' }}>
            <Col>
              <img
                src={userInfo.profilePhoto}
                style={{
                  width: '100px',
                  border: '1px solid white',
                  borderRadius: '10px',
                  padding: '0px',
                }}
                alt={userInfo.username}
              />
              <div
                style={{
                  background: '#48b4e4',
                  width: '70%',
                  float: 'right',
                  padding: '0.45rem',
                  color: 'white',
                  borderRadius: '10px',
                }}
              >
                <h2 style={{ margin: '0px', fontWeight: '700' }}>
                  {userInfo.name}{' '}
                </h2>
                @username: {userInfo.username}
                <br></br>Designation : {designation}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              background: '#68d4f4',
              padding: '0.3rem',
              margin: '2rem',
            }}
          >
            <Col>
              <Link to="/settings" style={{ color: 'white' }}>
                <i className="fa fa-cog" aria-hidden="true"></i>
              </Link>
            </Col>
            <Col>
              <Link to="/notification" style={{ color: 'white' }}>
                {notice === 0 ? (
                  <i
                    className="fa fa-bell"
                    style={{ color: 'red' }}
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i className="fa fa-bell  " aria-hidden="true"></i>
                )}
              </Link>
            </Col>
            <Col>
              <Link style={{ color: 'white' }} to="/chat">
                {chat === 0 ? (
                  <i className="fa fa-comments" aria-hidden="true"></i>
                ) : (
                  <i
                    className="fa fa-comments  "
                    style={{ color: 'red' }}
                    aria-hidden="true"
                  ></i>
                )}
              </Link>
            </Col>
            <Col>
              <Link
                to="#signout"
                onClick={signoutHandler}
                style={{ color: 'white' }}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </Link>
            </Col>
          </Row>
        </Col>
        <Col sm={6} style={{ marginTop: '0.5rem' }}>
          <Row className="mb-3">
            <Col>
              <Link
                to="/treeview"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-link  " aria-hidden="true"></i> <br></br>
                Team Maintenance
              </Link>{' '}
            </Col>
            <Col>
              <Link
                to="/transection"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-credit-card-alt  " aria-hidden="true"></i>
                <br></br>
                Transaction
              </Link>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link
                to="/transferandhistory"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-exchange  " aria-hidden="true"></i>{' '}
                <br></br>
                Transfer & History
              </Link>{' '}
            </Col>
            <Col>
              <Link
                to="/shop"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-cart-plus  " aria-hidden="true"></i>
                <br></br>
                Shop
              </Link>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link
                to="/salesandpromotion"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-gift  " aria-hidden="true"></i> <br></br>
                Sales man Promotion
              </Link>{' '}
            </Col>
            <Col>
              <Link
                to="/withdraw"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-credit-card" aria-hidden="true"></i>
                <br></br>
                Withdraw
              </Link>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Link
                to="/sales"
                style={{
                  fontSize: '1rem',
                  height: '100%',
                  width: '100%',
                  background: '#48b4e4',
                  border: '0px',
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-balance-scale  " aria-hidden="true"></i>{' '}
                <br></br>
                Sales
              </Link>{' '}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
