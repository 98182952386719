import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function NoticeListScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        api + `/api/costs/allnotices/admin?page=${page}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  const deleteHandler = async (product) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(api + `/api/costs/delete/${product._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('Notice deleted successfully');
        fetchData();
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  const [text, setText] = useState();

  async function saveNotice() {
    try {
      const data = await axios.post(
        api + `/api/costs/addnotice`,
        { text: text, postedBy: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success(data.message);
      fetchData();
    } catch (err) {
      toast.error(getError(err));
    }
  }

  return (
    <div>
      <Helmet>
        <title>Manage Notices</title>
      </Helmet>
      <Container className="" style={{ marginLeft: '13%' }}>
        <h3>Manage Notice</h3>
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">Add New Notice</h4>
          <p>
            <input
              type="text"
              id="noticeText"
              className="form-control"
              placeholder="Notice Text"
              onChange={(e) => setText(e.target.value)}
            />
          </p>
          <p className="mb-0">
            <div className="d-grid gap-2 col-6 mx-auto">
              <Button
                className="btn btn-primary"
                onClick={saveNotice}
                type="button"
              >
                Save Notice
              </Button>
            </div>
          </p>
        </div>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div
              className="table-responsive"
              style={{
                background: 'white',
              }}
            >
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th>Notice</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((product) => (
                      <tr key={product._id}>
                        <td>{product.text}</td>
                        <td>{product.createdAt}</td>
                        <td>
                          <Link
                            onClick={() => deleteHandler(product)}
                            variant="outline-success"
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div>
                {'Total Notices: ' +
                  totalUsers +
                  ', Total Pages: ' +
                  totalPages}
                <nav aria-label="..." style={{ float: 'right' }}>
                  <ul className="pagination">
                    <Link
                      className={
                        parseInt(page) - 2 < 1
                          ? 'page-link disabled'
                          : 'page-link'
                      }
                      to={`/admin/notices?page=${parseInt(page) - 2}`}
                    >
                      Previous
                    </Link>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) - 1 < 1
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/notices?page=${parseInt(page) - 1}`}
                      >
                        {parseInt(page) - 1}
                      </Link>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        {parseInt(page)}
                        <span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 1 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/notices?page=${parseInt(page) + 1}`}
                      >
                        {parseInt(page) + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 2 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/notices?page=${page + 2}`}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>

                {[...Array(pages).keys()].map((x) => (
                  <Link
                    style={{ display: 'none' }}
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/notices?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
