import { useState } from "react";
import cl from "./Brand.module.css";

import b1 from "../../../assets/brands/1.png";
import b2 from "../../../assets/brands/2.png";
import b3 from "../../../assets/brands/5.png";

import left from "../../../assets/icons/banner/arrow-left-solid.svg";
import right from "../../../assets/icons/banner/arrow-right-solid.svg";

function Brands() {
  const brandsImages = [b1, b2, b3];
  const brandTexts = [
    "Adidas is a leading sportswear brand recognized worldwide for its commitment to performance, style, and sustainability. With a rich heritage spanning decades, Adidas offers a diverse range of athletic apparel, footwear, and accessories for athletes and enthusiasts alike. Their constant innovation, collaborations, and iconic designs have solidified Adidas' position as a prominent force in the sporting industry.",
    "Apple is a global technology company renowned for its innovative products and services. With a commitment to excellence and design, Apple has transformed industries with iconic devices like the iPhone, Mac, and iPad. Their ecosystem of software and services, including the App Store and iCloud, provides a seamless user experience, captivating millions worldwide.",
    "Nokia is a renowned telecommunications and technology company with a storied history in the mobile industry. Known for its durability and reliability, Nokia has been a pioneer in mobile phone technology, shaping the early days of the industry. Today, Nokia focuses on network infrastructure, software, and services, contributing to the advancement of 5G, Internet of Things (IoT), and digital transformation globally.",
  ];
  const [curSlide, setCurSlide] = useState(0);
  const maxSlide = brandsImages.length;

  function nextSlide() {
    if (curSlide === maxSlide - 1) {
      setCurSlide(0);
    } else {
      setCurSlide(curSlide + 1);
    }
  }

  function prevSlide() {
    if (curSlide === 0) {
      setCurSlide(maxSlide - 1);
    } else {
      setCurSlide(curSlide - 1);
    }
  }
  return (
    <div className={cl.container}>
      <div className={cl.brand}>
        <div className={cl.image}>
          <img src={brandsImages[curSlide]} alt="Icon" />
        </div>
        <div className={cl.text}>
          <p>{brandTexts[curSlide]}</p>
        </div>
      </div>
      <div className={cl.buttons}>
        <button onClick={prevSlide}>
          <img src={left} alt="Icon" />
        </button>
        <button onClick={nextSlide}>
          <img src={right} alt="Icon" />
        </button>
      </div>
    </div>
  );
}

export default Brands;
