import cl from "./Earn.module.css";
import logo from "../../../assets/identity/icon-logo-l.png";
import earn from "../../../assets/earn/1.png";
import { NavLink } from "react-router-dom";

function Earn() {
  return (
    <div className={cl.container}>
      <div className={cl.text}>
        <img src={logo} alt="Icon" />
        <h1>Earn With Icon Program</h1>
        <h3>
          Earn and prosper with "Icon's" direct selling and affiliate program,
          setting the stage for a fulfilling career.
        </h3>
        <p>
          With "Icon's" direct selling model and affiliate program, individuals
          have a golden opportunity to earn and shape their careers. By
          promoting and selling solution-oriented products, they can unlock a
          stream of income while enjoying the flexibility of being their own
          boss. Settle your career with "Icon" and reap the rewards of
          entrepreneurial success <NavLink to={"/earn"}>Read more...</NavLink>
        </p>
      </div>
      <div className={cl.image}>
        <img src={earn} alt="Icon" />
      </div>
    </div>
  );
}

export default Earn;
