import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from '../Api';

export default function GlobalSalesScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;
  const initData = [
    {
      from: '',
      to: 'Loading',
      amount: '',
      created: '',
    },
  ];

  const [history, setHistory] = useState(initData);
  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/costs/globalSales`,
        { designation: userInfo.designation },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setHistory(data);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  function gotoHome() {
    navigate('/sales');
  }

  return (
    <div>
      <Helmet>
        <title>Global Sales</title>
      </Helmet>
      <Container className="no-gutter full-height" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Global Sales
          </div>
          <div
            className="card-body"
            style={{ margin: 'auto', textAlign: 'center' }}
          >
            {history.designationInfo ? (
              <>
                <h4>
                  Requirement: {history.designationInfo.gsb_req_match} Group
                  Sales <br></br>
                  Qualifying Sales: {history.matchingInfo.length} Group Sales{' '}
                  <br></br>
                  Remaining Sales:{' '}
                  {history.designationInfo.gsb_req_match -
                    history.matchingInfo.length}{' '}
                  Group Sales<br></br>
                  Remaining Day:{' '}
                  {history.designationInfo.gsb_req_match_time -
                    history.days -
                    1}{' '}
                  Days<br></br>
                </h4>
                <br></br>
                <i>
                  Global sales commission is deposited in your account. You can
                  cash the money if you complete the next{' '}
                  {history.designationInfo.gsb_req_match -
                    history.matchingInfo.length}{' '}
                  group sales within{' '}
                  {history.designationInfo.gsb_req_match_time -
                    history.days -
                    1}{' '}
                  days.
                </i>
              </>
            ) : (
              'You are not qualified for Global Sales '
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
