import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Axios from 'axios';
import {Helmet} from 'react-helmet-async';
import {useLocation, useNavigate} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";

export default function AddAgentScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const values = useLocation();

  const navigate = useNavigate();

  // WORKING ON FORM SUBMIT
  const [name, setName] = useState('');
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState('');
  const [reference, setReference] = useState(userInfo.username || '');
  const [placement, setPlacement] = useState(userInfo.username || '');
  const [password, setPassword] = useState('');
  const [position, setPosition] = useState(userInfo.position || '');
  const [formerr, setFormError] = useState('0');
  const [username, setUsername] = useState('');
  const [gender, setGender] = useState();
  const [nid, setNid] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (formerr === '1') {
        toast.success('Username Already Taken.');
        setFormError('1');
        return;
      }

      if (name.length < 6 || name.length > 30) {
        toast.error('Please Enter Valid Full Name');
      } else if (phone.length < 10 || phone.length > 15) {
        toast.error('Please Enter a Valid Phone Number');
      } else if (!reference) {
        toast.error('Valid Reference is Required');
      } else if (!placement) {
        toast.error('Valid Placement is Required');
      } else if (position === '0') {
        toast.error('Please Select the available position.');
      } else if (password.length < 6 || password.length > 30) {
        toast.error('Please Enter a Strong Password');
      } else {
        const { data } = await Axios.post(
          api + '/api/users/addagent',
          {
            name,
            username,
            phone,
            email,
            nid,
            gender,
            reference,
            placement,
            position,
            password,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.success('New Agent Added');
        navigate('/admin/agents');
      }
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [navigate, userInfo]);

  const checkPosition = async (value) => {
    try {
      const data = await Axios.post(
          api + '/api/users/checkposition',
          {
              reference,
              placement,
              value,
          }
      );
      if (data.status === 200) {
        toast.success('Position available.');
        setPosition(value);
      } else {
        setPosition('0');
      }
    } catch (error) {
      toast.error(getError(error));
      setPosition('0');
    }
  };

  const checkUsername = async (value) => {
    try {
      const data = await Axios.post(
          api + '/api/users/checkusername',
          {
              value,
          }
      );
      if (data.status === 200) {
        setUsername(value);
        setFormError('0');
      } else {
        setFormError('1');
        //  toast.success('Username Already Taken.');
      }
    } catch (error) {
      toast.error(getError(error));
      setFormError('1');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Add Agent </title>
      </Helmet>
      <Container className="small-container">
        <div className="card mb-3 my-3">
          <div className="card-header">Add New Agent</div>
          <div className="card-body">
            <Form
              onSubmit={submitHandler}
              style={{ width: '60%', margin: 'auto' }}
            >
              <Form.Group className="mb-3" controlId="name">
                <label
                  class="visually-hidden"
                  for="inlineFormInputGroupUsername"
                >
                  Username
                </label>
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-arrows-alt" aria-hidden="true"></i>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="form-control"
                    id="fullName"
                    placeholder="Full Name"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="username">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </div>
                  <input
                    type="text"
                    onChange={(e) =>
                      setUsername(e.target.value.toLowerCase().trim())
                    }
                    onBlur={() => checkUsername(username)}
                    value={username}
                    required
                    className="form-control"
                    id="username"
                    placeholder="Username"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    type="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    placeholder="Phone"
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) =>
                      setEmail(e.target.value.toLowerCase().trim())
                    }
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-id-badge" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    placeholder="NID"
                    type="number"
                    value={nid}
                    onChange={(e) => setNid(e.target.value.trim())}
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-child" aria-hidden="true"></i>
                  </div>
                  <select
                    className="form-control"
                    type="select"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    required
                  >
                    <option value="0">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group className="mb-3 d-none" controlId="reference">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-address-book" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    placeholder="Reference"
                    type="text"
                    value={reference}
                    required
                    onChange={(e) =>
                      setReference(e.target.value.toLowerCase().trim())
                    }
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="mb-3  d-none" controlId="reference">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-id-card" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Placment ID"
                    value={placement}
                    readOnly
                    onChange={(e) => setPlacement(e.target.value)}
                    required
                  ></Form.Control>
                </div>
              </Form.Group>
              <Form.Group className="mb-3  d-none" controlId="reference">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-anchor" aria-hidden="true"></i>
                  </div>
                  <select
                    className="form-control"
                    type="select"
                    value={position}
                    readOnly
                    onChange={(e) => checkPosition(e.target.value)}
                    required
                  >
                    <option value="0">Select Position</option>
                    <option value="right">Right</option>
                    <option value="left">Left</option>
                  </select>
                </div>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <div className="input-group">
                  <div className="input-group-text">
                    <i class="fa fa-asterisk" aria-hidden="true"></i>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  ></Form.Control>
                </div>
              </Form.Group>
              <div className="mb-3 ">
                {formerr === 1 ? (
                  <Alert variant="danger">This is the dana</Alert>
                ) : (
                  ''
                )}
                <Button
                  type="submit"
                  className={formerr === '1' ? 'disabled' : 'n'}
                  variant="outline-success w-100"
                >
                  Add User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}
