import cl from "./SectionHeader.module.css";

function SectionHeader({ title }) {
  return (
    <div className={cl.container}>
      <div className={cl.inner}>
        <span></span>
        <h1>{title}</h1>
        <span></span>
      </div>
    </div>
  );
}

export default SectionHeader;
