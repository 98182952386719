import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet-async';
import {Link, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import Form from 'react-bootstrap/Form';
import {getError} from '../../utils';
import {api} from '../../Api';


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function OrderListScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [type, setType] = useState('pending');
  const [username, setUsername] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [formerror, setFormError] = useState();
  const [promotion, setPromotion] = useState([]); 

function getPosition(username) {
	let position_name = "";

	// Sort the promotion array in descending order based on the updated_at field
	let sorted_promotion = promotion.sort(
		(a, b) => new Date(b.updated_at) - new Date(a.updated_at)
	);

	// Iterate through the sorted promotion array to find the position name for the given username
	for (let i = 0; i < sorted_promotion.length; i++) {
		if (sorted_promotion[i].username === username) {
			position_name = sorted_promotion[i].position_name;
			break;
		}
	}

	// console.log(position_name);
	return position_name;
}

  const fetchData = async (type) => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.post(
        api + `/api/orders`,
        {
          type: type,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setPromotion(data.promotion)
      dispatch({ type: 'FETCH_SUCCESS', payload: data.orders });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  };

  async function filter(username, startDate, endDate) {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.post(
        api + `/api/orders/filter`,
        {
          username: username,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  }

  useEffect(() => {
    fetchData(type);
  }, [userInfo]);

  const deleteHandler = async (order) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(
          api+`/api/orders/${order._id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        toast.success('Order deleted successfully');
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  function update(value) {
    setType(value);
    fetchData(value);
  }

  return (
		<div>
			<Helmet>
				<title>Manage Orders </title>
			</Helmet>
			<Container className="mb-3">
				<Row className="my-3">
					<Col md="3" xl="3">
						<h2 className=" ">{type.toUpperCase()} Orders</h2>
					</Col>

					<Col md={6}>
						<Row
							style={{
								border: "1px solid blue ",
								padding: "5px",
								borderRadius: 10,
							}}
						>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="username">
									<Form.Control
										placeholder="Username"
										onChange={(e) => setUsername(e.target.value)}
										required
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Control
										type="date"
										placeholder="Start Date"
										onChange={(e) => setStartDate(e.target.value)}
										value={startDate}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Control
									type="date"
									placeholder="End Date"
									onChange={(e) => setEndDate(e.target.value)}
									value={endDate}
								/>
							</Col>

							<Col md={6} style={{ margin: "auto" }}>
								<div className="d-grid">
									<Button
										variant="outline-success"
										onClick={() => filter(username, startDate, endDate)}
									>
										Search
									</Button>
								</div>
							</Col>
						</Row>
					</Col>

					<Col className="col text-end" md="3" xl="3">
						<Button
							onClick={() => update("pending")}
							variant="danger"
							style={{ marginRight: "1rem" }}
						>
							Pending Orders
						</Button>
						<Button onClick={() => update("approved")}>Approved Orders</Button>
					</Col>
				</Row>

				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<>
						<div
							className="table-responsive bg-white"
							style={{
								padding: "5px",
							}}
						>
							<div className="alert alert-info text-center">
								IN THIS PAGE: {orders.length || 0} Entries | |{" "}
								{orders.reduce((total, item) => total + item.totalPrice, 0) ||
									0}{" "}
								TK ||{" "} 
								{orders.reduce(
									(total, item) => total + item.orderItems.length,
									0
								) || 0}{" "}
								Items
							</div>
							<table className="table table-striped">
								<thead className="table-light">
									<tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Order Date</th>
                                        <th>Point</th>
                                        <th>TK</th>
                                        <th>Delivery</th>
                                        <th>Action</th>
                                        <th>Invoice</th>
                                    </tr>
								</thead>
								<tbody>
									{orders.map((order) => (
                                        <tr key={order._id}>
                                            <td>{order._id}</td>
                                            <td>
                                                {order.user
                                                    ? order.user.username +
                                                    " - " +
                                                    getPosition(order.user.username)
                                                    : "DELETED USER"}
                                            </td>
                                            <td>{order.createdAt.substring(0, 10)}</td>
                                            <td>{order.totalPoint}</td>
                                            <td>
                                                {order.totalPrice}{" "}
                                                {order.isPaid
                                                    ? "Paid At " + order.paidAt.substring(0, 10)
                                                    : "Not Paid"}
                                            </td>
                                            <td>
                                                {order.isDelivered
                                                    ? order.deliveredAt.substring(0, 10)
                                                    : "Not Delivered"}
											</td>
											<td>
												<Link
													to={`/order/${order._id}`}
													className=" btn btn-success"
												>
													Details
												</Link>{" "}
												&nbsp;
												<Link
													onClick={() => deleteHandler(order)}
													variant="outline-success"
													className={userInfo.isAdmin ? "d-block" : "d-none"}
												>
													Delete
												</Link>
											</td>
											<td>
												{" "}
												<Link
													className="btn btn-info"
													target="_blank"
													to={`/invoice/${order._id}`}
												>
													Invoice
												</Link>{" "}
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<th scope="col" colSpan={2}>
											{orders.length || 0} Entries
										</th>
										<th scope="col" colSpan={2}>
											{orders.reduce(
												(total, item) => total + item.totalPrice,
												0
											) || 0}{" "}
											TK{" || "}
											{orders.reduce(
												(total, item) => total + item.orderItems.length,
												0
											) || 0}{" "}
											Items
										</th>
										<th scope="col" colSpan={3}>
											IN THIS PAGE
										</th>
									</tr>
								</tfoot>
							</table>
							<div></div>
						</div>
					</>
				)}
			</Container>
		</div>
	);
}
