import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { Store } from '../Store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from "../Api";

export default function MyRegisterWalletScreen() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;
  const initData = [
    {
      from: '',
      to: 'Loading',
      amount: '',
      created: '',
    },
  ];

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  const [history, setHistory] = useState(initData);
  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        api + `/api/users/myregisterwallet?page=${page}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setHistory(data.products);
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  function gotoHome() {
    navigate('/transection');
  }

  return (
    <div>
      <Helmet>
        <title>My Register Wallet </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '} My
            Register Wallet
          </div>
          <div className="card-body" style={{ margin: 'auto' }}></div>
          <Table responsive style={{ width: '95%', margin: 'auto' }}>
            <thead>
              <tr>
                <th> Details</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {history.map((product) => (
                <tr key={product._id}>
                  <td>{product.from ? 'Register Balance ' : 'Loading'}</td>
                  <td>{product.amount}</td>
                  <td>
                    {product.createdAt
                      ? product.createdAt.substring(0, 10)
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <nav aria-label="..." style={{ margin: '1rem' }}>
            <ul className="pagination">
              <Link
                className={
                  parseInt(page) - 2 < 1 ? 'page-link disabled' : 'page-link'
                }
                to={`/myregisterwallet?page=${parseInt(page) - 2}`}
              >
                Previous
              </Link>
              <li className="page-item">
                <Link
                  className={
                    parseInt(page) - 1 < 1 ? 'page-link disabled' : 'page-link'
                  }
                  to={`/myregisterwallet?page=${parseInt(page) - 1}`}
                >
                  {parseInt(page) - 1}
                </Link>
              </li>
              <li className="page-item active">
                <span className="page-link">
                  {parseInt(page)}
                  <span className="sr-only">(current)</span>
                </span>
              </li>
              <li className="page-item">
                <Link
                  className={
                    parseInt(page) + 1 > totalPages
                      ? 'page-link disabled'
                      : 'page-link'
                  }
                  to={`/myregisterwallet?page=${parseInt(page) + 1}`}
                >
                  {parseInt(page) + 1}
                </Link>
              </li>
              <li className="page-item">
                <Link
                  className={
                    parseInt(page) + 2 > totalPages
                      ? 'page-link disabled'
                      : 'page-link'
                  }
                  to={`/myregisterwallet?page=${page + 2}`}
                >
                  Next
                </Link>
              </li>
            </ul>
          </nav>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
