import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import { Store } from '../../Store';
import { toast } from 'react-toastify';
import { getError } from '../../utils';
import { api } from '../../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function PointsScreen() {
const navigate = useNavigate();
const { state } = useContext(Store);
const { userInfo } = state;
const { search } = useLocation();
const sp = new URLSearchParams(search);
const page = sp.get("page") || 1;

  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [totalPages, setTotalPages] = useState();

  const fetchData = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.get(api + `/api/points?page=${page}`, {
				headers: { Authorization: `Bearer ${userInfo.token}` },
			});
      setTotalPages(data.pages);
      dispatch({ type: 'FETCH_SUCCESS', payload: data.points });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  };
  useEffect(() => {
		fetchData();
	}, [page,userInfo]);

  const [username, setUsername] = useState('');
  const [PointAmount, setPointAmount] = useState();
  const [pointType, setPointType] = useState();
  const [formerror, setFormError] = useState();

  const checkUsername = async (value) => {
    try {
      const data = await axios.post(
        api+'/api/users/checkusernameforpoint',
        {
          value,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data.status === 200) {
        // toast.success('Username available.');
        setUsername(value);
        setFormError('0');
      } else {
        setFormError('1');
        //  toast.success('Username Already Taken.');
      }
    } catch (error) {
      //toast.error(getError(error));
      setFormError('1');
    }
  };

  const submitHandler = async () => {
    if (formerror === '0') {
      try {
        const data = await axios.post(
          api + '/api/users/sendpoint',
          {
            username: username,
            pointType: pointType,
            PointAmount: PointAmount,
          },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        if (data.status === 200) {
          toast.success('Point Send Successful');
          fetchData();
        } else {
          toast.error('Something went wrong.');
        }
      } catch (error) {
        window.alert(getError(error));
      }
    } else {
      window.alert('Invalid Username');
    }
  };

  const [usernamef, setUsernamef] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const filter = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.post(
        api + `/api/points/filter/`,
        {
          username: usernamef,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  };

  return (
		<div>
			<Helmet>
				<title>Point Transfer</title>
			</Helmet>
			<Container className="mb-3">
				<Row className="my-3">
					<Col md={4}>
						<h2 className=" ">Point Transfer Log</h2>
					</Col>
					<Col md={8}>
						<Form onSubmit={submitHandler}>
							<Row>
								<Col md={2}>
									<Form.Group className="mb-3" controlId="username">
										<Form.Control
											placeholder="Username"
											onChange={(e) => checkUsername(e.target.value)}
											required
										/>
									</Form.Group>
								</Col>
								<Col md={4}>
									<Form.Group className="mb-3" controlId="name">
										<select
											className="form-control"
											onChange={(e) => {
												setPointType(e.target.value);
											}}
										>
											<option value="availablebalance">
												Available Balance
											</option>
											<option value="shoppingbalance">Shopping Balance</option>{" "}
											<option value="point">Register Balance</option>
											<option value="matchingpoint">Matching Point</option>
										</select>
									</Form.Group>
								</Col>
								<Col md={2}>
									<Form.Control
										type="number"
										placeholder=" Amount"
										onChange={(e) => setPointAmount(e.target.value)}
										required
									/>
								</Col>

								<Col md={2}>
									<div className="d-grid">
										<Button variant="outline-success" onClick={submitHandler}>
											Send Point
										</Button>
									</div>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row>
				<Row>
					<Col md={8} style={{ margin: "auto" }}>
						<Row
							style={{
								border: "1px solid blue ",
								padding: "5px",
								borderRadius: 10,
							}}
						>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="username">
									<Form.Control
										placeholder="Username"
										onChange={(e) => setUsernamef(e.target.value)}
										required
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Control
										type="date"
										placeholder="Start Date"
										onChange={(e) => setStartDate(e.target.value)}
										value={startDate}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Control
									type="date"
									placeholder="End Date"
									onChange={(e) => setEndDate(e.target.value)}
									value={endDate}
								/>
							</Col>

							<Col md={6} style={{ margin: "auto" }}>
								<div className="d-grid">
									<Button
										variant="outline-success"
										onClick={() => filter(username, startDate, endDate)}
									>
										Search
									</Button>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<>
						<div
							className="table-responsive"
							style={{
								background: "white",
								padding: "5px",
							}}
						>
							<table className="table table-striped">
								<thead className="table-light">
									<tr>
										<th>ID</th>
										<th>Amount</th>
										<th>Receiver</th>
										<th>Details</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
									{orders.map((order) => (
										<tr key={order._id}>
											<td>{order._id.substring(5, 0)}</td>
											<td>{order.amount}</td>
											<td>{order.receiverUsername}</td>
											<td>{order.sendInfo}</td>
											<td>
												{order.createdAt
													? order.createdAt.substring(0, 10)
													: "Not Delivered"}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<div>
								<nav aria-label="..." style={{ margin: "1rem" }}>
									<ul className="pagination">
										<Link
											className={
												parseInt(page) - 2 < 1
													? "page-link disabled"
													: "page-link"
											}
											to={`/admin/pointlog?page=${parseInt(page) - 2}`}
										>
											Previous
										</Link>
										<li className="page-item">
											<Link
												className={
													parseInt(page) - 1 < 1
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/pointlog?page=${parseInt(page) - 1}`}
											>
												{parseInt(page) - 1}
											</Link>
										</li>
										<li className="page-item active">
											<span className="page-link">
												{parseInt(page) }
												<span className="sr-only">(current )</span>
											</span>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 1 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/pointlog?page=${parseInt(page) + 1}`}
											>
												{parseInt(page) + 1}
											</Link>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 2 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`/admin/pointlog?page=${page + 2}`}
											>
												Next
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</>
				)}
			</Container>
		</div>
	);
}
