import Axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import CheckoutSteps from '../component/CheckoutSteps';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getError } from '../utils';
import LoadingBox from '../component/LoadingBox';
import Header from '../ecommerce/Header';
import Footer from '../ecommerce/Footer';
import { api } from "../Api";


const reducer = (state, action) => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_SUCCESS':
      return { ...state, loading: false };
    case 'CREATE_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function PlaceOrderScreen() {
  const navigate = useNavigate();
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false,
  });

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart, userInfo } = state;

  const delivery_charge =
    cart.shippingAddress.delivery_method === 'pick_from_store' ? 0 : 60;
  const vat = 0;

  var total_items = cart.cartItems.reduce((a, c) => a + c.quantity, 0);
  var total_product = cart.cartItems.reduce(
    (a, c) => a + c.price * c.quantity,
    0
  );
  var total_delivery_charge = Math.round(total_items * delivery_charge);
  var total_tax =
    Math.round((total_delivery_charge + total_product) * vat) / 100;
  var grand_total = total_product + total_delivery_charge + total_tax;
  var totalPoint = cart.cartItems.reduce((a, c) => a + c.point * c.quantity, 0);
  useEffect(() => {
    if (!cart.shippingAddress) {
      navigate('/shipping');
    }
  }, [cart, navigate]);

  const placeOrderHandler = async () => {
    try {
      dispatch({ type: 'CREATE_REQUEST' });

      const { data } = await Axios.post(
        api + '/api/orders/createneworder',
        {
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          itemsPrice: total_product,
          shippingPrice: total_delivery_charge,
          taxPrice: total_tax,
          totalPrice: grand_total,
          totalPoint: totalPoint,
        },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      ctxDispatch({ type: 'CART_CLEAR' });
      dispatch({ type: 'CREATE_SUCCESS' });
      localStorage.removeItem('cartItems');
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' });
      toast.error(getError(err));
    }
  };

  return (
    <div>
      <Helmet>
        <title>Place Order | Icon Marketing.</title>
      </Helmet>
      <Header />
      <div
        className="w-80 "
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <Row>
          <Col md={8}>
            <div className="card mb-3">
              <div className="card-header bg-white ">
                Order Summary{' '}
                <b style={{ float: 'right' }}>
                  <Link to="/cart">Edit</Link>{' '}
                </b>{' '}
              </div>
              <div className="card-body  bg-white">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th className="text-center">Products</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart.cartItems.map((item) => (
                      <tr key={item.slug}>
                        <td>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="img-fluid rounded"
                            style={{ width: '5rem', height: '3rem' }}
                          ></img>{' '}
                          <Link to={`/product/${item.slug}`}>
                            {item.name.substring(0, 20) + '...'}
                          </Link>
                        </td>
                        <td>৳{item.price}</td>
                        <td>{item.quantity}</td>
                        <td>৳ {item.price * item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-3 bg-white">
              <div className="card-header bg-white">
                Delivery Address Overview{' '}
                <Link style={{ float: 'right' }} to="/shipping">
                  Edit
                </Link>{' '}
              </div>
              <div className="card-body">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <td>Delivery Method </td>
                      <td>:</td>
                      <td>
                        {cart.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'Pick from the Store'
                          : 'Home Delivery'}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className={
                        cart.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                    >
                      <td>Receiver Name </td>
                      <td>:</td>
                      <td>{cart.shippingAddress.fullName}</td>
                    </tr>
                    <tr
                      className={
                        cart.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                    >
                      <td>Street Address </td>
                      <td>:</td>
                      <td>{cart.shippingAddress.streetAddress}</td>
                    </tr>
                    <tr
                      className={
                        cart.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                      paymentMethod
                    >
                      <td>City </td>
                      <td>:</td>
                      <td>{cart.shippingAddress.city}</td>
                    </tr>
                    <tr
                      className={
                        cart.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                      paymentMethod
                    >
                      <td>Phone </td>
                      <td>:</td>
                      <td>{cart.shippingAddress.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="card mb-3 bg-white">
              <div className="card-header bg-white">Order Confirmation </div>
              <div className="card-body">
                <table className="table table-responsive">
                  <tr key="total_items">
                    <td>Total Quantity </td>
                    <td>:</td>
                    <td>{total_items} </td>
                  </tr>
                  <tr key="total_product">
                    <td>Total Products </td>
                    <td>:</td>
                    <td>৳{total_product}</td>
                  </tr>
                  <tr key="total_delivery_charge">
                    <td>Total Delivery Charge </td>
                    <td>:</td>
                    <td>৳{total_delivery_charge}</td>
                  </tr>

                  <tr key="total_tax">
                    <td>Total Tax </td>
                    <td>:</td>
                    <td>৳{total_tax}</td>
                  </tr>
                  <tr key="grand_total">
                    <td>Grand Total </td>
                    <td>:</td>
                    <td>৳{grand_total}</td>
                  </tr>
                </table>
              </div>
              <div className="card-footer text-muted">
                <div className="d-grid">
                  <Button
                    type="button"
                    variant="outline-success"
                    disabled={cart.cartItems.length === 0}
                    onClick={() => placeOrderHandler()}
                  >
                    Confirm Order
                  </Button>
                  {loading && <LoadingBox />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
