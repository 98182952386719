import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { Store } from '../Store';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { api } from '../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function UserNotificationScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;
  const userID = userInfo._id;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post(
          api + `/api/costs/allnotices?page=${page}`,
          { userInfo },

          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
        setTotalPages(data.pages);
        setTotalUsers(data.countProducts);
      } catch (error) {
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [page, userInfo]);

  function gotoHome() {
    navigate('/user-panel');
  }
  return (
    <div>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <div className="card my-3" style={{ width: '80%', margin: 'auto' }}>
        <div
          className="card-header"
          onClick={() => {
            gotoHome();
          }}
        >
          {' '}
          <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
          Notifications
        </div>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th className="text-center">Details</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product.text}</td>
                      <td>{product.createdAt.substring(0, 10)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {'Total : ' + totalUsers + ', Total Pages: ' + totalPages}
                <nav aria-label="..." style={{ float: 'right' }}>
                  <ul className="pagination">
                    <Link
                      className={
                        parseInt(page) - 2 < 1
                          ? 'page-link disabled'
                          : 'page-link'
                      }
                      to={`/admin/availablebalance?page=${parseInt(page) - 2}`}
                    >
                      Previous
                    </Link>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) - 1 < 1
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/availablebalance?page=${
                          parseInt(page) - 1
                        }`}
                      >
                        {parseInt(page) - 1}
                      </Link>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        {parseInt(page)}
                        <span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 1 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/availablebalance?page=${
                          parseInt(page) + 1
                        }`}
                      >
                        {parseInt(page) + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 2 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/availablebalance?page=${page + 2}`}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>

                {[...Array(pages).keys()].map((x) => (
                  <Link
                    style={{ display: 'none' }}
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/products?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
