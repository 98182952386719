import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {Helmet} from 'react-helmet-async';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";


export default function CommissionScreen() {
    const {state} = useContext(Store);
    const {userInfo} = state;

    const current = new Date();
    const today = `${current.getFullYear()}-${
        current.getMonth() + 1
    }-${current.getDate()}`;

  const initialValue = [
    {
      _id: '0',
      userID: '0',
      reference: 0,
      matching: 0,
      global: 0,
      reward: 0,
      headoffice: 0,
      product: 0,
      marketing: 0,
      gift: 0,
      profit: 0,
      hub: 0,
      status: true,
      createdAt: '2022-11-27T18:24:46.441Z',
      updatedAt: '0',
      __v: 0,
      total: 0,
    },
  ];

  const [todayData, setTodayData] = useState(initialValue);
  const [TotalData, setTotalData] = useState(initialValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
            api + `/api/costs/admin`,
            {
                headers: {Authorization: `Bearer ${userInfo.token}`},
            }
        );

        const current = new Date();
        const today = `${current.getFullYear()}-${
          current.getMonth() + 1
        }-${current.getDate()}`;

        // SETTING TODAY DATA
        const todayf = data.filter((cost) => {
          return cost.createdAt.substring(0, 10) === today;
        });
        setTodayData(todayf.length > 0 ? todayf : initialValue);

        // SETTING TOTAL DATA
        setTotalData(data.length > 0 ? data : initialValue);

        // llll
      } catch (error) {
        toast.error(getError(error));
      }
    };
    fetchData();
  }, [userInfo]);

  return (
    <div>
      <Helmet>
        <title>Manage Commission | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="">
        <Card className="m-3 ">
          <Card.Header className="text-center">
            All Commission <Badge style={{ float: 'right' }}>100%</Badge>
          </Card.Header>
          <Row className="mb-3 mt-3">
            <Col>
              <Card>
                <Card.Header>
                  Reference Cost{' '}
                  <Badge style={{ float: 'right' }}>53 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.reference)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.reference).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Matching Cost
                  <Badge style={{ float: 'right' }}>105 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.matching)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.matching).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Global Sales
                  <Badge style={{ float: 'right' }}> 59.75 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.global)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.global).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Header>
                  Reward Cost
                  <Badge style={{ float: 'right' }}>73.33 Point </Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.reward)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.reward).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Head Office Maintenance
                  <Badge style={{ float: 'right' }}> 50.42 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.headoffice)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.headoffice).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Products Cost
                  <Badge style={{ float: 'right' }}>68 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.product)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.product).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Header>
                  Marketing Cost
                  <Badge style={{ float: 'right' }}>9 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.marketing)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.marketing).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Gift & office Maintenance
                  <Badge style={{ float: 'right' }}>6 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.gift)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.gift).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Company Profit
                  <Badge style={{ float: 'right' }}>50.50 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.profit)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.profit).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={4}>
              <Card>
                <Card.Header>
                  Hub Maintenance
                  <Badge style={{ float: 'right' }}>25 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.hub)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.hub).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header>
                  Grand Total Cost
                  <Badge style={{ float: 'right' }}>500 Point</Badge>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Today</Col>
                      <Col>:</Col>
                      <Col>
                        {todayData
                          .map((datum) => datum.total)
                          .reduce((a, b) => a + b)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col md={6}>Grand Total</Col>
                      <Col>:</Col>
                      <Col>
                        {TotalData.map((datum) => datum.total).reduce(
                          (a, b) => a + b
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Card.Footer className="text-center">
            {TotalData.length + ' Users'}
          </Card.Footer>
        </Card>
      </Container>
    </div>
  );
}
