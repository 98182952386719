import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Chart } from 'react-google-charts';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import { Store } from '../../Store';
import { getError } from '../../utils';
import { api } from '../../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, summery: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


export default function DashboardScreen() {
  const [{ loading, summery, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(api + '/api/orders/summary', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        console.log(data);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchData();
  }, [userInfo]);
  return (
    <div style={{ width: '87%', float: 'right' }}>
      <Helmet>
        <title>Admin Dashboard </title>
      </Helmet>
      <Container className="">
        <h1 className="my-3 text-center fp">Admin Dashboard</h1>
        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <Row style={{ marginBottom: '1rem' }}>
              <Col md={4}>
                <div className="card text-bg-success mb-3">
                  <div className="card-header bg-success">Total Users</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery.users && summery.users[0]
                        ? summery.users[0].numUsers
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-info mb-3">
                  <div className="card-header bg-info">Verified Users</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery.users && summery.users
                        ? summery.verifiedUsers
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-danger mb-3">
                  <div className="card-header bg-danger">UnVerified Users</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery.users && summery.users
                        ? summery.users[0].numUsers - summery.verifiedUsers
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="card text-bg-danger mb-3">
                  <div className="card-header bg-danger">Pending Orders</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.unPaidOrders && summery?.unPaidOrders[0]
                        ? summery?.unPaidOrders[0]?.numOrders +
                          '  Orders || ৳' +
                          summery?.unPaidOrders[0]?.totalSales
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-success mb-3">
                  <div className="card-header bg-success">Approved Orders</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.paidOrders?.length
                        ? summery?.paidOrders[0]?.numOrders +
                          '  Orders || ৳' +
                          summery?.paidOrders[0]?.totalSales
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-info mb-3">
                  <div className="card-header bg-info">Total Orders</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.paidOrders && summery?.paidOrders[0]
                        ? summery?.paidOrders[0]?.numOrders +
                          summery?.unPaidOrders[0]?.numOrders +
                          '  Orders '
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="card text-bg-danger mb-3">
                  <div className="card-header bg-danger">Pending Withdraw</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.unPaidWithdraw && summery?.unPaidWithdraw[0]
                        ? summery?.unPaidWithdraw[0]?.numOrders +
                          '  Requests || ৳' +
                          summery?.unPaidWithdraw[0]?.totalSales * 12
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-success mb-3">
                  <div className="card-header bg-success">
                    Approved Withdraw
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.paidWithdraw && summery?.paidWithdraw[0]
                        ? summery?.paidWithdraw[0]?.numOrders +
                          '  Requests || ৳' +
                          summery?.paidWithdraw[0]?.totalSales * 12
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="card text-bg-info mb-3">
                  <div className="card-header bg-info">Total Withdraw</div>
                  <div className="card-body">
                    <h5 className="card-title">
                      {summery?.paidOrders && summery?.paidOrders[0]
                        ? summery?.paidWithdraw[0]?.numOrders +
                          summery?.unPaidWithdraw[0]?.numOrders +
                          '  Requests '
                        : 0}
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="my-3">
              <h2>Sales</h2>
              {summery.dailyOrders.length === 0 ? (
                <MessageBox variant="warning">No Sales</MessageBox>
              ) : (
                <Chart
                  width="100%"
                  height="100%"
                  chartType="AreaChart"
                  loader={<div>Loading Chart...</div>}
                  data={[
                    ['Date', 'Sales'],
                    ...summery.dailyOrders.map((x) => [x._id, x.sales]),
                  ]}
                ></Chart>
              )}
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
