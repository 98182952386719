import cl from "./Header.module.css";
import logo from "../../../assets/identity/icon-logo-l.png";

import icon1 from "../../../assets/icons/header/user-regular.svg";
import icon2 from "../../../assets/icons/header/cart-shopping-solid.svg";
import icon3 from "../../../assets/icons/header/magnifying-glass-solid.svg";
import icon4 from "../../../assets/icons/header/xmark-solid.svg";
import icon5 from "../../../assets/icons/header/bars-solid.svg";

import Nav from "./Nav";

function Header() {
  function toggleSearchBox(e) {
    e.preventDefault();
    document.getElementById("searchIcon").classList.toggle(`${cl.active}`);
    document.getElementById("searchBox").classList.toggle(`${cl.display}`);
    document.getElementById("search").classList.toggle(`${cl.hide}`);
    document.getElementById("crossMark").classList.toggle(`${cl.hide}`);
  }
  function toggleMenu(e) {
    e.preventDefault();
    document.getElementById("menuIcon").classList.toggle(`${cl.active}`);
    document.getElementById("navMobile").classList.toggle(`${cl.display}`);
    document.getElementById("menuBar").classList.toggle(`${cl.hide}`);
    document.getElementById("crossMarkMenu").classList.toggle(`${cl.hide}`);
    document.body.classList.toggle(`${cl.overflow_hide}`);
  }

  return (
    <>
      <div className={cl.container}>
        <div className={cl.logo}>
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className={cl.nav}>
          <Nav />
        </div>
        <div className={cl.icons}>
          <ul>
            <li>
              <a href="#/signin">
                <img src={icon1} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#/cart">
                <img src={icon2} alt="Icon" />
              </a>
            </li>
            <li>
              <a href="#/signin" onClick={(e) => toggleSearchBox(e)} id="searchIcon">
                <img src={icon3} alt="Icon" id="search" />
                <img
                  src={icon4}
                  alt="Icon"
                  className={cl.hide}
                  id="crossMark"
                />
              </a>
            </li>
            <li>
              <a href="#/signin" id="menuIcon" onClick={(e) => toggleMenu(e)}>
                <img src={icon5} alt="Icon" id="menuBar" />
                <img
                  src={icon4}
                  alt="Icon"
                  className={cl.hide}
                  id="crossMarkMenu"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className={cl.search_box} id="searchBox">
          <div className={cl.input}>
            <input type="text" placeholder="Type here" />
            <button>Search</button>
          </div>
        </div>
      </div>
      <div className={cl.navMobile} id="navMobile">
        <Nav />
      </div>
    </>
  );
}

export default Header;
