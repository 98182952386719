import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import LoadingBox from '../component/LoadingBox';
import { api } from '../Api';

export default function SettingScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const myId = userInfo._id;

  const [oldname, setnameold] = useState();
  const [oldphone, setphoneold] = useState();
  const [oldemail, setemailold] = useState();
  const [oldfatherphone, setfatherphoneold] = useState();
  const [oldmotherphone, setmotherphoneold] = useState();
  const [oldspousephone, setspousephoneold] = useState();
  const [oldgender, setgenderold] = useState();
  const [oldnid, setnidold] = useState();
  const [olddob, setdobold] = useState();
  const [oldaddress, setaddressold] = useState();
  const [oldbankaccount, setbankaccountold] = useState();
  const [oldbankname, setbanknameold] = useState();
  const [oldbankbranch, setbankbranchold] = useState();
  const [oldmobilebankname, setmobilebanknameold] = useState();
  const [oldmobilebankaccount, setmobilebankaccountold] = useState();
  const [oldbenificiaryname, setbenificiarynameold] = useState();
  const [oldbenificiarynid, setbenificiarynidold] = useState();
  const [oldbenificiaryrelation, setbenificiaryrelationold] = useState();
  const [oldfblink, setfblinkold] = useState();

  const [ref, setref] = useState();
  const [plac, setplac] = useState();
  const [oldPasswordold, setOldPasswordold] = useState();
  const fetchData = async (id) => {
    try {
      setLoading(1);
      const { data } = await Axios.get(api + `/api/users/getsetting`, {
        headers: { authorization: `Bearer ${userInfo.token}` },
      });
      setref(data.reference);
      setplac(data.placement);
      setLoading(0);
      setname(data.name);
      setphone(data.phone);
      setemail(data.email);
      setfatherphone(data.fatherphone);
      setmotherphone(data.motherphone);
      setspousephone(data.spousephone);
      setgender(data.gender);
      setnid(data.nid);
      setdob(data.dob);
      setaddress(data.address);
      setbankaccount(data.bankaccount);
      setbankbranch(data.bankbranch);
      setmobilebankname(data.mobilebankname);
      setmobilebankaccount(data.mobilebankaccount);
      setbenificiaryname(data.benificiaryname);
      setbenificiarynid(data.benificiarynid);
      setbenificiaryrelation(data.benificiaryrelation);
      setfblink(data.fblink);
      setOldPhoto(data.profilePhoto);
      setOldSign(data.sign);
      setOldPasswordold(data.password);

      // asigning old

      setnameold(data.name);
      setphoneold(data.phone);
      setemailold(data.email);
      setfatherphoneold(data.fatherphone);
      setmotherphoneold(data.motherphone);
      setspousephoneold(data.spousephone);
      setgenderold(data.gender);
      setnidold(data.nid);
      setdobold(data.dob);
      setaddressold(data.address);
      setbankaccountold(data.bankaccount);
      setbankbranchold(data.bankbranch);
      setmobilebanknameold(data.mobilebankname);
      setmobilebankaccountold(data.mobilebankaccount);
      setbenificiarynameold(data.benificiaryname);
      setbenificiarynidold(data.benificiarynid);
      setbenificiaryrelationold(data.benificiaryrelation);
      setfblinkold(data.fblink);
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const [oldSign, setOldSign] = useState();
  const [oldPhoto, setOldPhoto] = useState();
  const [name, setname] = useState();
  const [phone, setphone] = useState();
  const [email, setemail] = useState();
  const [fatherphone, setfatherphone] = useState();
  const [motherphone, setmotherphone] = useState();
  const [spousephone, setspousephone] = useState();
  const [gender, setgender] = useState();
  const [nid, setnid] = useState();
  const [dob, setdob] = useState();
  const [address, setaddress] = useState();
  const [bankaccount, setbankaccount] = useState();
  const [bankname, setbankname] = useState();
  const [bankbranch, setbankbranch] = useState();
  const [mobilebankname, setmobilebankname] = useState();
  const [mobilebankaccount, setmobilebankaccount] = useState();
  const [benificiaryname, setbenificiaryname] = useState();
  const [benificiarynid, setbenificiarynid] = useState();
  const [benificiaryrelation, setbenificiaryrelation] = useState();
  const [fblink, setfblink] = useState();
  const [loading, setLoading] = useState();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState();

  const submitHandler = async () => {
    if (oldPasswordold === oldPassword) {
      if (newPassword && confirmNewPassword) {
        if (newPassword === confirmNewPassword) {
          try {
            await Axios.put(
              api+'/api/users/changepassword',
              {
                newPassword,
              },
              {
                headers: {
                  Authorization: `Bearer ${userInfo.token}`,
                },
              }
            );
            toast.success('Password Changed.');
          } catch (error) {
            toast.error(getError(error));
          }
        } else {
          toast.error('New Passowrd does not match with Confirm New Password');
        }
      } else {
        toast.error('Please Enter Confirm Password');
      }
    } else {
      toast.error('Wrong Old Password');
    }
  };

  useEffect(() => {
    fetchData(myId);
  }, [userInfo, myId]);

  const navigate = useNavigate();

  function gotoHome() {
    navigate('/user-panel');
  }

  async function save() {
    try {
      setLoading(1);
      const { save } = await Axios.put(
        api + '/api/users/savesetting',
        {
          name: name,
          phone: phone,
          email: email,
          nid: nid,
          gender: gender,
          dob: dob,
          address: address,
          fatherphone: fatherphone,
          motherphone: motherphone,
          spousephone: spousephone,
          bankaccount: bankaccount,
          bankname: bankname,
          bankbranch: bankbranch,
          benificiaryname: benificiaryname,
          benificiarynid: benificiarynid,
          benificiaryrelation: benificiaryrelation,
          fblink: fblink,
          mobilebankaccount: mobilebankaccount,
          mobilebankname: mobilebankname,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('Data Saved');
      fetchData();
    } catch (error) {
      toast.error(getError(error));
    }
  }

  const [photo, setPhoto] = useState();
  const [photoupload, setPhotoUpload] = useState(0);
  const photoUploader = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    if (file.size > 266536) {
      toast.error('Max Image Size is 250 KB.');
    } else {
      if (file.type === 'image/jpeg') {
        try {
          setPhotoUpload(1);
          const { data } = await Axios.post(
            api + '/api/upload/userphoto',
            bodyFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          setPhotoUpload(0);

          setPhoto(data.secure_url);
          userInfo.profilePhoto = data.secure_url;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          savePhoto('profilePhoto', data.secure_url);
        } catch (err) {
          setPhotoUpload(0);
          toast.error(getError(err));
        }
      } else {
        toast.error('Only JPG Images are Allowed.');
      }
    }
  };

  const [sign, setsign] = useState();
  const signUploader = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    if (file.size > 166536) {
      toast.error('Max Image Size is 150 KB.');
    } else {
      if (file.type === 'image/jpeg') {
        try {
          setPhotoUpload(1);
          const { data } = await Axios.post(
            api + '/api/upload/userphoto',
            bodyFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${userInfo.token}`,
              },
            }
          );
          setPhotoUpload(0);

          setsign(data.secure_url);
          savePhoto('sign', data.secure_url);
        } catch (err) {
          setPhotoUpload(0);
          toast.error(getError(err));
        }
      } else {
        toast.error('Only JPG Images are Allowed.');
      }
    }
  };

  async function savePhoto(value, link) {
    try {
      const { save } = await Axios.put(
        api + '/api/users/savephoto',
        {
          value: value,
          link: link,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('Image uploaded successfully');
    } catch (error) {
      toast.success('Image uploaded Failed' + getError(error));
    }
  }
  return (
		<div>
			<Helmet>
				<title>Settings </title>
			</Helmet>
			<Container className="small-container">
				<div className="card mb-3">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i class="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						Settings{" "}
					</div>
					<div className="card-body">
						<Accordion defaultActiveKey="0" flush>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Personal Information</Accordion.Header>
								<Accordion.Body>
									<table className="table table-responsive">
										<thead>
											<tr>
												<td colSpan={3}>
													<Form onSubmit={submitHandler}>
														<Form.Group
															className="mb-3 text-center"
															controlId="image"
														>
															<Form.Control
																type="hidden"
																value={photo}
																onChange={(e) => setPhoto(e.target.value)}
																required
															></Form.Control>

															<img
																src={photo ? photo : oldPhoto}
																style={{
																	width: "8rem",
																	height: "8rem",
																	border: "1px solid",
																	borderRadius: "50%",
																}}
															></img>
														</Form.Group>
														<Form.Group
															className={
																oldPhoto !=
																"https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
																	? "d-none"
																	: "mb-3 "
															}
															controlId="imageFile"
														>
															<Form.Label>Upload Profile Photo</Form.Label>
															<Form.Control
																type="file"
																onChange={photoUploader}
															/>
															{photoupload ? <LoadingBox /> : ""}
														</Form.Group>
													</Form>
												</td>
											</tr>
											<tr>
												<td>Full Name</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setname(e.target.value)}
														className="form-control"
														value={name}
														readOnly={oldname ? "readOnly" : ""}
													/>
												</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Phone Number</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setphone(e.target.value)}
														className="form-control"
														value={phone}
														readOnly={oldphone ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Email Address</td>
												<td>:</td>
												<td>
													<input
														type="email"
														onChange={(e) => setemail(e.target.value)}
														className="form-control"
														value={email}
														readOnly={oldemail ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Passport/NID/Birth Certificate Number</td>
												<td>:</td>
												<td>
													<input
														type="number"
														onChange={(e) => setnid(e.target.value)}
														className="form-control"
														value={nid}
														readOnly={oldnid ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Gender</td>
												<td>:</td>
												<td>
													<select
														onChange={(e) => setgender(e.target.value)}
														className="form-control"
														value={gender}
														readOnly={oldgender ? "readOnly" : ""}
													>
														<option>Select Gender </option>
														<option className="form-control" value="male">
															Male
														</option>
														<option className="form-control" value="female">
															female
														</option>
													</select>
												</td>
											</tr>
											<tr>
												<td>Date of Birth</td>
												<td>:</td>
												<td>
													<input
														type="date"
														onChange={(e) => setdob(e.target.value)}
														className="form-control"
														value={dob}
														readOnly={olddob ? "readOnly" : ""}
													/>
												</td>
											</tr>

											<tr>
												<td>Reference</td>
												<td>:</td>
												<td>
													<input
														type="text"
														className="form-control"
														value={ref}
														readOnly={ref ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Placement</td>
												<td>:</td>
												<td>
													<input
														type="text"
														className="form-control"
														value={plac}
														readOnly={plac ? "readOnly" : ""}
													/>
												</td>
											</tr>

											<tr>
												<td>Present Address</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setaddress(e.target.value)}
														className="form-control"
														value={address}
														readOnly={oldaddress ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Facebook URL</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setfblink(e.target.value)}
														className="form-control"
														value={fblink}
														readOnly={oldfblink ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>
													{" "}
													<img
														src={sign ? sign : oldSign}
														style={{
															width: "7rem",
															height: "4rem",
															border: "1px solid",
														}}
													></img>
												</td>
												<td>:</td>
												<td>
													<Form onSubmit={submitHandler}>
														<Form.Group
															className="mb-3 text-center"
															controlId="image"
														>
															<Form.Control
																type="hidden"
																value={sign}
																onChange={(e) => sign(e.target.value)}
																required
															></Form.Control>
														</Form.Group>
														<Form.Group
															className={oldSign ? "d-none" : "mb-3"}
															controlId="imageFile"
														>
															<Form.Label>Upload Signature</Form.Label>
															<Form.Control
																type="file"
																onChange={signUploader}
															/>
															{photoupload ? <LoadingBox /> : ""}
														</Form.Group>
													</Form>
												</td>
											</tr>
											<tr>
												<td colSpan={3}>
													<Button
														variant="outline-primary w-100"
														onClick={save}
													>
														{loading ? (
															<Spinner
																animation="border"
																size="sm"
																variant="primary"
															/>
														) : (
															"Save"
														)}
													</Button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>Family Information</Accordion.Header>
								<Accordion.Body>
									<table className="table table-responsive">
										<tbody>
											<tr>
												<td>Father's Phone</td>
												<td>:</td>
												<td>
													<input
														type="number"
														onChange={(e) => setfatherphone(e.target.value)}
														className="form-control"
														value={fatherphone}
														readOnly={oldfatherphone ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Mother's Phone</td>
												<td>:</td>
												<td>
													<input
														type="number"
														onChange={(e) => setmotherphone(e.target.value)}
														className="form-control"
														value={motherphone}
														readOnly={oldmotherphone ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Spouse Phone</td>
												<td>:</td>
												<td>
													<input
														type="number"
														onChange={(e) => setspousephone(e.target.value)}
														className="form-control"
														value={spousephone}
														readOnly={oldspousephone ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td colSpan={3}>
													<Button
														variant="outline-primary w-100"
														onClick={save}
													>
														{loading ? (
															<Spinner
																animation="border"
																size="sm"
																variant="primary"
															/>
														) : (
															"Save"
														)}
													</Button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>Bank Information</Accordion.Header>
								<Accordion.Body>
									<table className="table table-responsive">
										<tbody>
											<tr>
												<td>Bank Account Number</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setbankaccount(e.target.value)}
														className="form-control"
														value={bankaccount}
														readOnly={oldbankaccount ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Bank Name</td>
												<td>:</td>
												<td>
													<input
														type="email"
														onChange={(e) => setbankname(e.target.value)}
														className="form-control"
														value={bankname}
														readOnly={oldbankname ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Bank Branch</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setbankbranch(e.target.value)}
														className="form-control"
														value={bankbranch}
														readOnly={oldbankbranch ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td colSpan={3}>
													<Button
														variant="outline-primary w-100"
														onClick={save}
													>
														{loading ? (
															<Spinner
																animation="border"
																size="sm"
																variant="primary"
															/>
														) : (
															"Save"
														)}
													</Button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header>Mobie Banking Information</Accordion.Header>
								<Accordion.Body>
									<table className="table table-responsive">
										<tbody>
											<tr>
												<td>Mobile Banking Name</td>
												<td>:</td>
												<td>
													<select
														className="form-control"
														value={mobilebankname}
														onChange={(e) => setmobilebankname(e.target.value)}
														readOnly={oldmobilebankname ? "readOnly" : ""}
													>
														<option>Select Option</option>
														<option value="bKash">bKash</option>
														<option value="Rocket">Rocket</option>
														<option value="Nagad">Nagad</option>
													</select>
												</td>
											</tr>
											<tr>
												<td>Account Number</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) =>
															setmobilebankaccount(e.target.value)
														}
														className="form-control"
														value={mobilebankaccount}
														readOnly={oldmobilebankaccount ? "readOnly" : ""}
													/>
												</td>
											</tr>

											<tr>
												<td colSpan={3}>
													<Button
														variant="outline-primary w-100"
														onClick={save}
													>
														{loading ? (
															<Spinner
																animation="border"
																size="sm"
																variant="primary"
															/>
														) : (
															"Save"
														)}
													</Button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>Benificiary Information</Accordion.Header>
								<Accordion.Body>
									<table className="table table-responsive">
										<tbody>
											<tr>
												<td>Benificiary Name</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) => setbenificiaryname(e.target.value)}
														className="form-control"
														value={benificiaryname}
														readOnly={oldbenificiaryname ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Benificiary NID</td>
												<td>:</td>
												<td>
													<input
														type="number"
														onChange={(e) => setbenificiarynid(e.target.value)}
														className="form-control"
														value={benificiarynid}
														readOnly={oldbenificiarynid ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td>Benificiary Relation</td>
												<td>:</td>
												<td>
													<input
														type="text"
														onChange={(e) =>
															setbenificiaryrelation(e.target.value)
														}
														className="form-control"
														value={benificiaryrelation}
														readOnly={oldbenificiaryrelation ? "readOnly" : ""}
													/>
												</td>
											</tr>
											<tr>
												<td colSpan={3}>
													<Button
														variant="outline-primary w-100"
														onClick={save}
													>
														{loading ? (
															<Spinner
																animation="border"
																size="sm"
																variant="primary"
															/>
														) : (
															"Save"
														)}
													</Button>{" "}
												</td>
											</tr>
										</tbody>
									</table>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header>Change Passowrd</Accordion.Header>
								<Accordion.Body>
									<Form onSubmit={submitHandler}>
										<Form.Group className="mb-3" controlId="name">
											<Form.Label>Old Password</Form.Label>
											<Form.Control
												value={oldPassword}
												onChange={(e) => setOldPassword(e.target.value)}
												required
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="name">
											<Form.Label>New Password</Form.Label>
											<Form.Control
												value={newPassword}
												onChange={(e) => setNewPassword(e.target.value)}
												required
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="name">
											<Form.Label>Confirm New Password</Form.Label>
											<Form.Control
												value={confirmNewPassword}
												onChange={(e) => setConfirmNewPassword(e.target.value)}
												required
											/>
										</Form.Group>
										<div className="d-grid">
											<Button variant="outline-success" onClick={submitHandler}>
												Change Password
											</Button>
										</div>
									</Form>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
				<div className="card mb-3" style={{ display: "none" }}>
					<div className="card-header text-center">Change Password </div>
					<div className="card-body">
						<Form onSubmit={submitHandler}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>New Password</Form.Label>
								<Form.Control
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Confirm New Password</Form.Label>
								<Form.Control
									value={confirmNewPassword}
									onChange={(e) => setConfirmNewPassword(e.target.value)}
									required
								/>
							</Form.Group>
							<div className="d-grid">
								<Button variant="outline-success" onClick={submitHandler}>
									Change Password
								</Button>
							</div>
						</Form>
					</div>
				</div>
			</Container>
		</div>
	);
}
