import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link, useNavigate} from 'react-router-dom';
import Rating from './Rating';
import {Store} from '../Store';
import {useContext} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import {api} from '../Api';

function Product(props) {
  const { product } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;
  const navigate = useNavigate();

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(api + `/api/products/${item._id}`);
    if (data.stock < quantity) {
      window.alert('Sorry, this product is currently stockout.');
      return;
    }
    ctxDispatch({ 
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
    toast.info('Product Added to Cart');
  };

  const buyNow = async (item) => {
    const existItem = cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(api + `/api/products/${item._id}`);
    if (data.stock < quantity) {
      window.alert('Sorry, this product is currently stockout.');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
    toast.info('Product Added to Cart');
    navigate('/cart');
  };

  return (
    <Card style={{ background: 'white' }}>
      <Link to={`/product/${product.slug}`} className="bg-image hover-zoom">
        <img
          src={product.image}
          className="card-img-top"
          style={{
            maxHeight: '173px',
            minWidth: '153px',
            minHeight: '173px',
          }}
          alt={product.name}
        />
      </Link>
      <Card.Body style={{ background: 'white' }}>
        <Row style={{ textAlign: 'center' }}>
          <Col md={12} lg={7}>
            <Rating rating={product.rating} totalRating="5"></Rating>
          </Col>
          <Col
            md={12}
            lg={5}
            className="text-right text-danger"
            style={{ fontWeight: '700' }}
          >
            <strong> ৳ </strong> {product.price}
          </Col>
        </Row>

        <p
          className="mb-3"
          style={{
            border: '1px solid #806767a1',
            textAlign: 'center',
            borderRadius: '2px',
          }}
        >
          <i className="fa fa-trophy text-danger" aria-hidden="true"></i>{' '}
          {product.point} + Points
        </p>

        {product.stock < 1 ? (
          <>
            {' '}
            <h5
              className="card-title heading text-center bg-white"
              style={{ minHeight: '80px', maxHeight: '80px' }}
            >
              <Link to={`/product/${product.slug}`}>
                <b style={{ color: 'black' }}>
                  {product.name.substring(0, 30) + '...'}
                </b>
              </Link>
            </h5>
            <Button variant="outline-dark" style={{ marginLeft: '20%' }}>
              Out of Stock
            </Button>
          </>
        ) : (
          <Row id="tohover">
            <h5
              className="card-title heading text-center bg-white"
              style={{ minHeight: '80px', maxHeight: '80px' }}
            >
              <Link to={`/product/${product.slug}`}>
                <b style={{ color: 'black' }}>
                  {product.name.substring(0, 30) + '...'}
                </b>
              </Link>
            </h5>
            <Col style={{ margin: 'auto', textAlign: 'center' }}>
              <Button
                variant="success"
                onClick={() => addToCartHandler(product)}
              >
                <i className="fa fa-cart-plus" aria-hidden="true"></i> Add to
                Cart
              </Button>
            </Col>
            <Col id="toshowssss" className="d-none">
              <Button variant="outline-primary" onClick={() => buyNow(product)}>
                BUY NOW
              </Button>{' '}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}

export default Product;
