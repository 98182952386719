import axios from 'axios';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useParams} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Store} from '../../Store';
import {getError} from '../../utils';
import {toast} from 'react-toastify';
import {api} from "../../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };
    default:
      return state;
  }
};

export default function ProductEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpload }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [price, setPrice] = useState('');
  const [point, setPoint] = useState('');
  const [image, setImage] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState('');
  const [stock, setStock] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(
            api + `/api/products/${productId}`
        );
        setName(data.name);
        setSlug(data.slug);
        setPrice(data.price);
        setPoint(data.point);
        setImage(data.image);
        setBrand(data.brand);
        setCategory(data.category);
        setStock(data.stock);
        setDescription(data.description);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchData();
  }, [productId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
          api + `/api/products/${productId}`,
          {
              _id: productId,
              name,
              slug,
              price,
              point,
              image,
              brand,
              category,
              stock,
          description,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Product Updated.');
      navigate('/admin/products');
    } catch (error) {
      toast.error(getError('error'));
    }
  };

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post(
          api + '/api/upload',
          bodyFormData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  authorization: `Bearer ${userInfo.token}`,
              },
          }
      );
      dispatch({ type: 'UPLOAD_SUCCESS' });

      toast.success('Image uploaded successfully');
      setImage(data.secure_url);
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <>
      <Helmet>
          <title>Edit Product </title>
      </Helmet>
      <Container className="small-container my-3">
        <div className="card mb-3">
          <div className="card-header  text-center">Edit Product </div>
          <div className="card-body">
            <Form onSubmit={submitHandler}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="slug">
                <Form.Label>Product Slug</Form.Label>
                <Form.Control
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="price">
                <Form.Label>Product Price</Form.Label>
                <Form.Control
                  value={price}
                  type="number"
                  onChange={(e) => setPrice(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="point">
                <Form.Label>Product Point</Form.Label>
                <Form.Control
                  value={point}
                  type="number"
                  onChange={(e) => setPoint(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="image">
                <Form.Label>Product Image</Form.Label>
                <Form.Control
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="imageFile">
                <Form.Label>Upload File</Form.Label>
                <Form.Control type="file" onChange={uploadFileHandler} />
                {loadingUpload && <LoadingBox></LoadingBox>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="category">
                <Form.Label>Product Category</Form.Label>
                <Form.Control
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="brand">
                <Form.Label>Product Brand</Form.Label>
                <Form.Control
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="stock">
                <Form.Label>Product Stock</Form.Label>
                <Form.Control
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label>Product Description</Form.Label>
                <Form.Control
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>
              <div className="mb-3">
                <Button variant="outline-success w-100" type="Submit">
                  Save Product
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>
    </>
  );
}
