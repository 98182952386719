import { NavLink } from "react-router-dom";
import cl from "./Features.module.css";
export default function Feature({ img, title, description }) {
  return (
    <div className={cl.feature}>
      <div className={cl.f_container}>
        <div className={cl.icon_container}>
          <div className={cl.icon}>
            <img src={img} alt="Icon" />
          </div>
        </div>
        <div className={cl.text}>
          <div className={cl.title}>
            <h2>{title}</h2>
          </div>
          <div className={cl.description}>
            <p>{description}</p>
          </div>
        </div>
      </div>
      <div className={cl.read_more}>
        <NavLink to={"/features"}>Read More</NavLink>
      </div>
    </div>
  );
}
