import { NavLink } from "react-router-dom";
import cl from "./ViewMore.module.css";

function ViewMore({ text, link }) {
  return (
    <div className={cl.container}>
      <NavLink to={link}>{text}</NavLink>
    </div>
  );
}

export default ViewMore;
