import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Store } from "../Store";
import { api } from "../Api";
import ProfileCard from "../component/ProfileCard";

export default function IncomeGenerationHistory() {
	const navigate = useNavigate();
	const { state } = useContext(Store);
	const { userInfo } = state;

     const { id } = useParams();

     const gen = Number(id);

     function gotoHome() {
		navigate("/selectgeneration");
	}
	const [history, sethistory] = useState([]);

	const processApiResponse = (apiResponse) => {
		const result = [];
		const usernameToAmountMap = {};

		// Iterate through the API response and consolidate amounts for each withdrawUsername
		for (const item of apiResponse) {
			const { _id, ...rest } = item; // Exclude _id from the new object
			const { withdrawUsername, amount } = rest;

			if (usernameToAmountMap[withdrawUsername]) {
				// If we've seen this withdrawUsername before, sum the amount
				usernameToAmountMap[withdrawUsername] += amount;
			} else {
				// If it's a new withdrawUsername, add it to the map
				usernameToAmountMap[withdrawUsername] = amount;
				result.push({ ...rest });
			}
		}

		// Update the amount for each unique withdrawUsername
		for (const item of result) {
			const { withdrawUsername } = item;
			item.amount = usernameToAmountMap[withdrawUsername];
		}

		return result;
	};


	const fetchData = async () => {
		try {
			const { data } = await axios.get(
				api + `/api/users/getIncomeGenerationHistory`,
				{
					headers: { Authorization: `Bearer ${userInfo.token}` },
				}
			);
             const processedData = processApiResponse(data);

							sethistory(processedData);
		} catch (error) {}
	};
	useEffect(() => {
		fetchData();
	}, [userInfo]);

const filteredHistory = history
	? history.filter((item) => item.generation === gen)
	: []; 


	return (
		<div>
			<Helmet>
				<title>Income Generation History</title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i class="fa fa-long-arrow-left" aria-hidden="true"></i> Income
						Generation History
					</div>

					<div className="card mt-2" style={{ width: "80%", margin: "auto" }}>
						<div className="card-header">
							{gen === 1
								? "First"
								: gen === 2
								? "Second"
								: gen === 3
								? "Third"
								: gen === 4
								? "Fouth"
								: "Fiveth"}{" "}
							Generation
						</div>
						<div className="card-body">
								<div className="row">
										{filteredHistory
											? filteredHistory.map((filteredItem, index) => (
													<ProfileCard key={index} {...filteredItem} />
											  ))
											: " No User Found. "}
							</div>
						</div>
					</div>

					<div style={{ height: "51vh" }}> {""}</div>
				</div>
			</Container>
		</div>
	);
}
