import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from 'react-bootstrap/Container';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { Store } from '../Store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { getError } from '../utils';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { api } from "../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, orders: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function OrderHistory() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  const [{ loading, error, orders }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const { data } = await Axios.get(
          api+`/api/orders/mine`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    fetchData();
  }, [userInfo]);

  function gotoHome() {
    navigate('/shop');
  }

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="error">{error} </MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Shop </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '} Shop
          </div>

          <div className="card-body" style={{ margin: 'auto' }}></div>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead className="table-light">
                <tr>
                  <th>ID</th>
                  <th>Order Date</th>
                  <th>Total Amount</th>
                  <th>Payment Status</th>
                  <th>Delivery Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orders.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan={6}>
                      No Order Yet. <Link to={'/'}>Continue Shopping</Link>{' '}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {orders.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id.slice(-4)}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>{order.totalPrice.toFixed(2)}</td>
                    <td>
                      {order.isPaid
                        ? 'Paid at ' + order.paidAt.substring(0, 10)
                        : 'Pending'}
                    </td>
                    <td>
                      {order.isDelivered
                        ? 'Delivered at ' + order.deliveredAt.substring(0, 10)
                        : 'Pending'}
                    </td>
                    <td>
                      <Button
                        type="button"
                        variant="success"
                        onClick={() => {
                          navigate(`/order/${order._id}`);
                        }}
                      >
                        Details
                      </Button>{' '}
                      <Link
                        style={{ display: 'none' }}
                        className="btn btn-info"
                        target="_blank"
                        to={`/invoice/${order._id}`}
                      >
                        Invoice
                      </Link>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
