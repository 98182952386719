import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";


const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ChatListScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        api + `/api/costs/admin/allChats?page=${page}`,
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData();
  }, [page, userInfo]);

  const deleteHandler = async (product) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(api + `/api/costs/delete/${product._id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('Notice deleted successfully');
        fetchData();
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  const [text, setText] = useState();

  async function saveNotice() {
    try {
      const data = await axios.post(
        api + `/api/costs/addnotice`,
        { text: text, postedBy: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success(data.message);
      fetchData();
    } catch (err) {
      toast.error(getError(err));
    }
  }
  const [reply, setReply] = useState();
  const now = new Date().toLocaleString();

  async function sendReply(id) {
    if (reply) {
      try {
        const data = await axios.post(
          api + `/api/costs/admin/sendReply`,
          { reply: reply, id: id, replyAt: now },
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setReply('');
        toast.success('Reply Send');
        fetchData();
      } catch (err) {
        toast.error(getError(err));
      }
    } else {
      toast.error('No reply');
    }
  }

  return (
    <div>
      <Helmet>
        <title>Chat List</title>
      </Helmet>
      <Container className="" style={{ marginLeft: '13%' }}>
        <h3>Chat List</h3>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div
              className="table-responsive"
              style={{
                background: 'white',
              }}
            >
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th>Username</th>
                    <th>Message</th>
                    <th>Reply</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product.userName}</td>
                      <td>
                        {product.message}{' '}
                        <cite
                          className="text-muted text-end"
                          style={{ fontSize: '0.7rem' }}
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>{' '}
                          {product.messageAt}
                        </cite>{' '}
                      </td>
                      <td>
                        <input
                          type={text}
                          className="form-control"
                          placeholder={
                            product.reply
                              ? product.reply + ' At ' + product.replyAt
                              : ''
                          }
                          onChange={(e) => setReply(e.target.value)}
                        />

                        <Button
                          className={
                            reply
                              ? 'btn btn-primary'
                              : 'btn btn-primary disabled'
                          }
                          onClick={() => sendReply(product._id)}
                        >
                          Send
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {'Total Chats: ' + totalUsers + ', Total Pages: ' + totalPages}
                <nav aria-label="..." style={{ float: 'right' }}>
                  <ul className="pagination">
                    <Link
                      className={
                        parseInt(page) - 2 < 1
                          ? 'page-link disabled'
                          : 'page-link'
                      }
                      to={`/admin/chatlist?page=${parseInt(page) - 2}`}
                    >
                      Previous
                    </Link>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) - 1 < 1
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/chatlist?page=${parseInt(page) - 1}`}
                      >
                        {parseInt(page) - 1}
                      </Link>
                    </li>
                    <li className="page-item active">
                      <span className="page-link">
                        {parseInt(page)}
                        <span className="sr-only">(current)</span>
                      </span>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 1 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/chatlist?page=${parseInt(page) + 1}`}
                      >
                        {parseInt(page) + 1}
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          parseInt(page) + 2 > totalPages
                            ? 'page-link disabled'
                            : 'page-link'
                        }
                        to={`/admin/chatlist?page=${page + 2}`}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>

                {[...Array(pages).keys()].map((x) => (
                  <Link
                    style={{ display: 'none' }}
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/chatlist?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
