import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";
import Accordion from 'react-bootstrap/Accordion';

export default function GlobalSales() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const initialValue = [
    {
      gsb: 0,
      gsb_req_match: 0,
      gsb_req_match_time: 0,
      name: 'Loading',
      req_left: 0,
      req_right: 0,
      reward: 'Loading',
    },
  ];
  const [designation, setDesignation] = useState(initialValue);

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
          api + `/api/designation`,
          {
              headers: {Authorization: `Bearer ${userInfo.token}`},
          }
      );
      setDesignation(data);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    fetchData();
  }, [userInfo]);

  return (
    <div>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Container>
        <div className="card">
          <div className="card-body">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Global Sales Settings</Accordion.Header>
                <Accordion.Body>
                  <table className="table table-striped">
                    <thead className="table-light">
                      <tr>
                        <th>Position Name</th>
                        <th colSpan={2} style={{ textAlign: 'center' }}>
                          Sales Target
                        </th>
                        <th>Achievement</th>
                      </tr>
                    </thead>
                    <tbody>
                      {designation.map((order) => (
                        <tr key={order._id}>
                          <td>{order.name}</td>
                          <td>{'Team A: ' + order.req_left + ' Points'}</td>
                          <td>{'Team B: ' + order.req_right + ' Points '}</td>
                          <td>{order.gsb + ' Point Per Global Sales.'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
}
