import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Store } from '../Store';
import { api } from "../Api";


export default function MyTeamPromotionalHistory() {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;

  function gotoHome() {
    navigate('/salesandpromotion');
  }
  const [history, sethistory] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.post(
        api + `/api/designation/mypromotionalhistory`,
        { username: userInfo.username },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      sethistory(data.history);
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, [userInfo]);

  const [name, setName] = useState('');
  const [team, setteam] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [promotion, setPromotion] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <div>
      <Helmet>
        <title>My Promotional History | Icon Marketing.</title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '} My
            Team Promotional History
          </div>

          <div className="card-body" style={{ margin: 'auto' }}>
            <div className="card">
              <div className="card-header">My Team Promotional History</div>
              <div className="card-body">
                <div
                  className="bg-info"
                  style={{ padding: '15px', borderRadius: '15px' }}
                >
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Username"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>{' '}
                    </Row>{' '}
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Select Sales Team</Form.Label>
                          <Form.Control
                            as="select"
                            value={team}
                            onChange={(e) => setteam(e.target.value)}
                          >
                            <option value="left">Team A</option>
                            <option value="right">Team B</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Select Promotion Type</Form.Label>
                          <Form.Control
                            as="select"
                            value={promotion}
                            onChange={(e) => setPromotion(e.target.value)}
                          >
                            <option value={1}>Development Officer</option>
                            <option value={2}>Assistant Manager</option>
                            <option value={3}>Deputy Manager</option>
                            <option value={4}> Manager</option>
                            <option value={5}>General Manager</option>
                            <option value={6}> Ambassador</option>
                            <option value={7}>Global Ambassador</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      variant="primary"
                      className="mt-3 w-100"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Form>
                </div>

                <Table responsive striped hover>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Promotion Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.length > 0
                      ? history.map((product) => (
                          <tr key={product._id}>
                            <td>{product.username}</td>
                            <td>{product.phone}</td>
                            <td>{product.created_at.substring(0, 10)}</td>
                          </tr>
                        ))
                      : 'No history'}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
