import { useContext } from 'react';
import { Store } from '../Store';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../component/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';
import { toast } from 'react-toastify';
import Header from '../icon/components/global/header/Header';
import Footer from '../icon/components/global/footer/Footer';
import { api } from "../Api";


export default function CartScreen() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const updateCartHandler = async (item, quantity) => {
    const { data } = await axios.get(
      api+`/api/products/${item._id}`
    );
    if (data.stock < quantity) {
      toast.error('Sorry, this product is currently stockout.');
      return;
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...item, quantity },
    });
  };

  const removeItemHandler = (item) => {
    ctxDispatch({
      type: 'CART_REMOVE_ITEM',
      payload: item,
    });
  };
  const checkoutHandler = () => {
    navigate('/shipping');
  };

  return (
    <div>
      <Helmet>
        <title>Shopping Cart</title>
      </Helmet>
      <Header />
      <Card
        className="w-80 my-3 bg-white"
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <Card.Body>
          <Row>
            <Col md={12} className="mb-5">
              {cartItems.length === 0 ? (
                <MessageBox>
                  Cart is empty. <Link to="/">Continue Shopping</Link>
                </MessageBox>
              ) : (
                <ListGroup>
                  <Row
                    className="align-items-center"
                    style={{ fontWeight: '700' }}
                  >
                    <Col md={4} className="text-center">
                      Products
                    </Col>
                    <Col md={2}>Price</Col>

                    <Col md={3}>Quantity</Col>
                    <Col md={1}>Remove</Col>
                    <Col md={2}>Total</Col>
                  </Row>
                  {cartItems.map((item) => (
                    <ListGroup.Item key={item._id}>
                      <Row className="align-items-center ">
                        <Col md={4}>
                          <img
                            src={item.image}
                            alt={item.name}
                            className="img-fluid rounded"
                            style={{ width: '5rem', height: '3rem' }}
                          ></img>{' '}
                          <Link to={`/product/${item.slug}`}>
                            {item.name.substring(0, 20) + '...'}
                          </Link>
                        </Col>
                        <Col md={2}>BDT {item.price}</Col>
                        <Col md={3}>
                          <Button
                            variant="light"
                            disabled={item.quantity === 1}
                            onClick={() =>
                              updateCartHandler(item, item.quantity - 1)
                            }
                          >
                            <i className="fas fa-minus-circle"></i>
                          </Button>{' '}
                          <span>{item.quantity}</span>{' '}
                          <Button
                            variant="light"
                            disabled={item.quantity === item.stock}
                            onClick={() =>
                              updateCartHandler(item, item.quantity + 1)
                            }
                          >
                            <i className="fas fa-plus-circle"></i>
                          </Button>
                        </Col>
                        <Col md={1}>
                          <Button
                            variant="light"
                            onClick={() => removeItemHandler(item)}
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </Col>
                        <Col md={2}>BDT {item.price * item.quantity}</Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                  <Row
                    className="align-items-center"
                    style={{ fontWeight: '700' }}
                  >
                    <Col md={4} className="text-center"></Col>
                    <Col md={2}> </Col>

                    <Col md={3}>
                      Total {cartItems.reduce((a, c) => a + c.quantity, 0)}{' '}
                      items
                    </Col>
                    <Col md={1}></Col>
                    <Col md={2}>
                      BDT{' '}
                      {cartItems.reduce((a, c) => a + c.price * c.quantity, 0)}
                    </Col>
                  </Row>
                </ListGroup>
              )}
            </Col>

            <div className="d-grid">
              <Button
                type="button"
                variant="outline-success"
                disabled={cartItems.length === 0}
                onClick={() => checkoutHandler()}
              >
                Proceed to Shipping
              </Button>{' '}
            </div>
          </Row>
        </Card.Body>
      </Card>
      <Footer />
    </div>
  );
}
