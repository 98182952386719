import cl from "./Quotes.module.css";
import Quote from "./Quote";

import q1 from "../../../assets/quotes/1.png";
import q2 from "../../../assets/quotes/2.png";
import q3 from "../../../assets/quotes/3.png";
import q4 from "../../../assets/quotes/4.png";
import q5 from "../../../assets/quotes/5.png";

function Quotes() {
  const allQuotes = [
    {
      name: "BILL GATES",
      info: "American Business Magnet & Philanthropist",
      quote:
        "If I would be given a chance to start all over again, I would choose Network Marketing.",
      image: q1,
    },
    {
      name: "BILL CLINTON",
      info: "Former US President",
      quote:
        "You strengthen our country and our economy, not just by striving for your own success, but by offering the opportunity to others. Your industry gives people a chance, after all, to make the most of their own lives. And to me, that's the heart of the American Dream.",
      image: q2,
    },
    {
      name: "BRIAN TRACY",
      info: "Motivational Speaker & Best Selling Author",
      quote:
        "The future of Network Marketing is unlimited. It has probably made more millionaires of more people in more countries than any other form of business starting from nothing. There’s no end in sight. It will continue to grow, because better people are getting into it. It will become one of the respected business methods in the world.",
      image: q3,
    },
    {
      name: "WARREN BUFFETT",
      info: "Billionaire Investor",
      quote: "The best investment I've ever made.",
      image: q4,
    },
    {
      name: "DR. A.P.J. ABDUL KALAM",
      info: "Former President of India, prominent scientist and engineer",
      quote:
        "NETWORK MARKETING is the Fastest Growing BUSINESS of 21st Century whitch must be joined by Every Young Man And Woman Globally Otherwise you can Never get the Best of your Youth Age.",
      image: q5,
    },
  ];
  return (
    <div className={cl.container}>
      {allQuotes.map((c) => {
        return <Quote key={c} quoteObj={c} />;
      })}
    </div>
  );
}

export default Quotes;
