import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import { Store } from '../../Store';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { getError } from '../../utils';
import Alert from 'react-bootstrap/Alert';
import { api } from '../../Api';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function AdminWithdrawScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const [totalUsers, setTotalUsers] = useState();
  const [totalPages, setTotalPages] = useState();
  const [paymentmethodInfo, setPaymentmethodInfo] = useState();
  const [promotion, setPromotion] = useState([]); 

  const [type, setType] = useState('pending');

function getPosition(username) {
	let position_name = "";

	// Sort the promotion array in descending order based on the updated_at field
	let sorted_promotion = promotion.sort(
		(a, b) => new Date(b.updated_at) - new Date(a.updated_at)
	);

	// Iterate through the sorted promotion array to find the position name for the given username
	for (let i = 0; i < sorted_promotion.length; i++) {
		if (sorted_promotion[i].username === username) {
			position_name = sorted_promotion[i].position_name;
			break;
		}
	}

	// console.log(position_name);
  return position_name
}


  const fetchData = async (type) => {
    try {
      const { data } = await axios.post(
        api + `/api/availablebalance/withdraw/admin?page=${page}`,
        { type },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
      setPromotion(data.promotion)
    } catch (error) {
      toast.error(getError(error));
    }
  };
  useEffect(() => {
    fetchData(type);
  }, [page, userInfo]);

  function update(value) {
    setType(value);
    fetchData(value);
  }

  const [table, setTable] = useState(1);
  const [receiverName, setReceiverName] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [netAmount, setNetAmount] = useState();
  const [bankInfo, setBankInfo] = useState();
  async function view(userId, wId) {
    setTable(0);
    try {
      const { data } = await axios.post(
        api + '/api/availablebalance/withdrawinfo',
        { id: userId },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setReceiverName(data.username);
      setPaymentMethod(data.paymentmethod);
      setNetAmount(data.amount);

      receiverInfo(wId, data.paymentmethod);
    } catch (error) {
      toast.error(getError(error));
    }
  }

  async function receiverInfo(wId, method) {
    setBankInfo('Loading...');
    const { data } = await axios.post(
      api + '/api/availablebalance/user',
      { id: wId },
      {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }
    );
    if (method !== 'bank') {
      setBankInfo(data.mobilebankname + ', Number: ' + data.mobilebankaccount);
    } else {
      setBankInfo(
        data.bankname +
          ', ' +
          data.bankbranch +
          'Branch, Account Number: ' +
          data.bankaccount
      );
    }
  }

  async function paid(id, username, paymentmethod, paidby, userId) {
    try {
      const { data } = await axios.put(
        api + `/api/availablebalance/withdraw/adminpay`,
        {
          id: id,
          username: username,
          paymentmethod: paymentmethod,
          paidby: paidby,
          userId: userId,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      handleHideRow(id);
      //fetchData();
    } catch (error) {
      toast.error(getError(error));
    }
  }

  const [hiddenRows, setHiddenRows] = useState([]);

  const handleHideRow = (id) => {
    setHiddenRows([...hiddenRows, id]);
  };

  function getTime(data) {
    const date = new Date(data);
    const currentMonth = date.getMonth();
    const monthString = currentMonth >= 10 ? currentMonth : `0${currentMonth}`;
    const currentDate = date.getDate();
    const dateString = currentDate >= 10 ? currentDate : `0${currentDate}`;
    return `${date.getFullYear()}-${monthString}-${currentDate}`;
  }

  const [username, setUsername] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function filter(username, startDate, endDate) {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await axios.post(
        api + `/api/availablebalance/withdraw/filter?page=${page}`,
        {
          username: username,
          startDate: startDate,
          endDate: endDate,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
      setTotalPages(data.pages);
      setTotalUsers(data.countProducts);
    } catch (err) {
      dispatch({
        type: 'FETCH_FAIL',
        payload: getError(err),
      });
    }
  }

  return (
		<div>
			<Helmet>
				<title>Withdraw History</title>
			</Helmet>
			<Container className="">
				<Row className="my-3">
					<Col md="3" xl="3">
						<h2 className=" ">{type.toUpperCase()} Withdraw Request</h2>
					</Col>

					<Col md={6}>
						<Row
							style={{
								border: "1px solid blue ",
								padding: "5px",
								borderRadius: 10,
							}}
						>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="username">
									<Form.Control
										placeholder="Username"
										onChange={(e) => setUsername(e.target.value)}
										required
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Group className="mb-3" controlId="name">
									<Form.Control
										type="date"
										placeholder="Start Date"
										onChange={(e) => setStartDate(e.target.value)}
										value={startDate}
									/>
								</Form.Group>
							</Col>
							<Col md={4}>
								<Form.Control
									type="date"
									placeholder="End Date"
									onChange={(e) => setEndDate(e.target.value)}
									value={endDate}
								/>
							</Col>

							<Col md={6} style={{ margin: "auto" }}>
								<div className="d-grid">
									<Button
										variant="outline-success"
										onClick={() => filter(username, startDate, endDate)}
									>
										Search
									</Button>
								</div>
							</Col>
						</Row>
					</Col>

					<Col className="col text-end" md="3" xl="3">
						<Button
							onClick={() => update("pending")}
							variant="danger"
							style={{ marginRight: "1rem", marginBottom: "1rem" }}
						>
							Pending Withdraw
						</Button>
						<Button onClick={() => update("approved")}>
							Approved Withdraw
						</Button>
					</Col>
				</Row>

				{loading ? (
					<LoadingBox />
				) : error ? (
					<MessageBox variant="danger">{error}</MessageBox>
				) : (
					<>
						<Alert variant={table ? "success d-none" : "success"}>
							<Alert.Heading>Confirming Withdraw</Alert.Heading>
							<p>Username : {receiverName}</p>
							<p>Payment Method : {paymentMethod}</p>
							<p>Net Amount : {netAmount}</p>
							<p>Bank Info : {bankInfo}</p>
							<hr />
							<p className="mb-0">
								<Button variant="outline-secondary" onClick={() => setTable(1)}>
									Close
								</Button>
							</p>
						</Alert>

						<div
							className={
								table ? "table-responsive " : "table-responsive d-none"
							}
							style={{}}
						>
							<div className="alert alert-info text-center">
								IN THIS PAGE: {products.length || 0} Entries | |{" "}
								{products.reduce((total, item) => total + item.amount, 0) || 0}{" "}
								Tk 
							</div>
							<table className="table table-striped">
								<thead className="table-light">
									<tr>
										<th>Username</th>
										<th>Agent</th>
										<th>Method</th>
										<th>Net Payable</th>
										<th>Charge</th>
										<th>Request Date</th>
										<th>Paid Date</th>
										<th>View</th>
										<th>Action</th>
										<th>Invoice</th>
									</tr>
								</thead>
								<tbody>
									{products.map((product) => (
										<tr
											key={product._id}
											id={product.username}
											style={{
												display: hiddenRows.includes(product._id) ? "none" : "",
											}}
										>
											<td>
												{product.username +
													" - " +
													getPosition(product.username)}
											</td>
											<td>{product.to}</td>
											<td>{product.paymentmethod}</td>
											<td>{product.amount}</td>
											<td>{product.charge}</td>

											<td>{product.createdAt}</td>
											<td>
												{product.status ? (
													<Button bg="success">Paid at {product.paidAt}</Button>
												) : (
													<Badge bg="danger">Pending</Badge>
												)}
											</td>
											<td>
												<Button
													variant="info"
													onClick={() => view(product._id, product.userId)}
												>
													View
												</Button>
											</td>
											<td>
												<Button
													variant={product.status ? "success" : "danger"}
													className={product.status ? "disabled" : ""}
													onClick={() =>
														paid(
															product._id,
															product.username,
															product.paymentmethod,
															userInfo.username,
															product.userId
														)
													}
												>
													Pay Now
												</Button>
											</td>
											<td>
												<Link
													className="btn btn-info"
													target="_blank"
													to={`/withdrawinvoice/${product._id}`}
												>
													Invoice
												</Link>{" "}
											</td>
										</tr>
									))}
								</tbody>
							</table>
							<div>
								{"Total : " + totalUsers + ", Total Pages: " + totalPages}
								<nav aria-label="..." style={{ float: "right" }}>
									<ul className="pagination">
										<Link
											className={
												parseInt(page) - 2 < 1
													? "page-link disabled"
													: "page-link"
											}
											to={`?page=${parseInt(page) - 2}`}
										>
											Previous
										</Link>
										<li className="page-item">
											<Link
												className={
													parseInt(page) - 1 < 1
														? "page-link disabled"
														: "page-link"
												}
												to={`?page=${parseInt(page) - 1}`}
											>
												{parseInt(page) - 1}
											</Link>
										</li>
										<li className="page-item active">
											<span className="page-link">
												{parseInt(page)}
												<span className="sr-only">(current)</span>
											</span>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 1 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`?page=${parseInt(page) + 1}`}
											>
												{parseInt(page) + 1}
											</Link>
										</li>
										<li className="page-item">
											<Link
												className={
													parseInt(page) + 2 > totalPages
														? "page-link disabled"
														: "page-link"
												}
												to={`?page=${page + 2}`}
											>
												Next
											</Link>
										</li>
									</ul>
								</nav>

								{[...Array(pages).keys()].map((x) => (
									<Link
										style={{ display: "none" }}
										className={
											x + 1 === Number(pages) ? "btn text-bold" : "btn"
										}
										key={x + 1}
										to={`?page=${x + 1}`}
									>
										{x + 1}
									</Link>
								))}
							</div>
						</div>
					</>
				)}
			</Container>
		</div>
	);
}
