import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';

export default function SalesScreen() {
  const navigate = useNavigate();

  function gotoHome() {
    navigate('/user-panel');
  }
  return (
    <div>
      <Helmet>
        <title>Sales </title>
      </Helmet>
      <Container className="no-gutter" style={{}}>
        <div className="card">
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <img
                src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
                style={{ width: '15rem', margin: '2rem' }}
                alt="ClickCart Logo"
              />
            </Col>
          </Row>
          <div
            className="card-header"
            onClick={() => {
              gotoHome();
            }}
          >
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> {'  '}{' '}
            Sales
          </div>

          <div className="card-body" style={{ margin: 'auto' }}></div>
          <Link to="/mysales" className="inbody-button mb-3">
            My Sales
          </Link>
          <Link to="/createdids" className="inbody-button mb-3">
            Created IDs
          </Link>
          <Link to="/teamsales" className="inbody-button mb-3">
            Team Sales
          </Link>
          <Link to="/todaysales" className="inbody-button mb-3">
            Today Sales
          </Link>
          <Link to="/globalsales" className="inbody-button mb-3">
            Global Sales
          </Link>
          <div style={{ height: '51vh' }}> {''}</div>
        </div>
      </Container>
    </div>
  );
}
