import Axios from 'axios';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import {Store} from '../Store';
import {getError} from '../utils';
import Header from '../ecommerce/Header';
import Footer from '../ecommerce/Footer';
import {api} from '../Api';

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true, error: '' };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, order: action.payload, error: '' };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };
    case 'DELIVER_REQUEST':
      return { ...state, loadingDeliver: true };
    case 'DELIVER_SUCCESS':
      return { ...state, loadingDeliver: false, successDeliver: true };
    case 'DELIVER_FAIL':
      return { ...state, loadingDeliver: false };
    case 'DELIVER_RESET':
      return {
        ...state,
        loadingDeliver: false,
        successDeliver: false,
      };
    default:
      return state;
  }
}

export default function OrderScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: orderId } = params;
  const navigate = useNavigate();

  const [
    {
      loading,
      error,
      order,
      successPay,
      loadingPay,
      loadingDeliver,
      successDeliver,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    order: {},
    error: '',
    successPay: false,
    loadingPay: false,
  });

  const fetechOrder = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const { data } = await Axios.get(
        api+`/api/orders/${orderId}`,
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (error) {
      dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
    }
  };

  useEffect(() => {
    if (!userInfo) {
      toast.error('Please Login');
      navigate('/signin');
    }
    if (!order._id || (order._id && order._id !== orderId)) {
      fetechOrder();
    }
    if (successPay) {
      dispatch({ type: 'PAY_RESET' });
    }
  }, [order, userInfo, orderId, navigate]);

  const [isLoading, setIsloading] = useState(false)
  async function paywithbKash() {
    setIsloading(true);
    try {
      dispatch({ type: 'PAY_REQUEST' });
      if (userInfo.isAdmin || userInfo.isAgent) {
        const { data } = await Axios.put(
          api + `/api/orders/${order._id}/adminpay`,
          {},
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'PAY_SUCCESS', payload: data });
        toast.success('Order Approved');

        fetechOrder();
      } else {
        dispatch({type: 'PAY_FAIL'});
        toast.error('Payment not starting yet');
      }
    } catch (err) {
      dispatch({type: 'PAY_FAIL', payload: getError(err)});
      toast.error(getError(err));
    }
    setIsloading(false);

  }

  async function deliver() {
    if (userInfo.isAdmin || userInfo.isAgent) {
      try {
        const { data } = await Axios.put(
          api + `/api/orders/${order._id}/delivernow`,
          {},
          {
            headers: { authorization: `Bearer ${userInfo.token}` },
          }
        );
        fetechOrder();
      } catch (error) {
        toast.error(getError(error));
      }
    } else {
      toast.error('Order Not Delivered Yet');
    }
  }

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="error">{error} </MessageBox>
  ) : (
    <div>
      <Helmet>
        <title>Orders </title>
      </Helmet>
      {userInfo.isAdmin || userInfo.isAgent ? '' : <Header />}
      <Container
        className="w-80 "
        id="myPage"
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <div className="d-none d-print-block">
          <img src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png" />
        </div>

        <Row>
          <Col md={8}>
            <div className="card mb-3 bg-white">
              <div className="card-header bg-white">
                Delivery Address Overview{' '}
              </div>
              <div className="card-body bg-white">
                <table className="table table-responsive ">
                  <thead>
                    <tr>
                      <td>Delivery Method </td>
                      <td>:</td>
                      <td>
                        {order.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'Pick from the Store'
                          : 'Home Delivery'}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className={
                        order.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                    >
                      <td>Receiver Name </td>
                      <td>:</td>
                      <td>{order.shippingAddress.fullName}</td>
                    </tr>
                    <tr
                      className={
                        order.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                    >
                      <td>Street Address </td>
                      <td>:</td>
                      <td>{order.shippingAddress.streetAddress}</td>
                    </tr>
                    <tr
                      className={
                        order.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                      paymentMethod
                    >
                      <td>City </td>
                      <td>:</td>
                      <td>{order.shippingAddress.city}</td>
                    </tr>
                    <tr
                      className={
                        order.shippingAddress.delivery_method ===
                        'pick_from_store'
                          ? 'd-none'
                          : ''
                      }
                      paymentMethod
                    >
                      <td>Phone </td>
                      <td>:</td>
                      <td>{order.shippingAddress.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="card-footer text-muted">
                <div className="d-grid">
                  {order.isDelivered ? (
                    <Button variant="outline-success">Delivered</Button>
                  ) : (
                    <Button variant="outline-danger" onClick={() => deliver()}>
                      Delivery Pending
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="card mb-3">
              <div className="card-header bg-white">
                Order Overview{' '}
                <div
                  style={{ float: 'right', padding: '5px', margin: '0' }}
                  className={
                    order.isPaid
                      ? 'alert alert-success text-center'
                      : 'alert alert-danger text-center'
                  }
                >
                  {order.isPaid ? 'Paid' : 'Not Paid Yet'}
                </div>{' '}
              </div>
              <div className="card-body bg-white">
                <table className="table table-responsive">
                  <tr key="paymentMethod">
                    <th>Payment Type </th>
                    <th>:</th>
                    <th>{order.paymentMethod}</th>
                  </tr>
                  <tr key="total_product">
                    <td>Total Products </td>
                    <td>:</td>
                    <td>৳{order.itemsPrice}</td>
                  </tr>
                    <tr key="total_delivery_charge">
                        <td>Total Delivery Charge</td>
                        <td>:</td>
                        <td>৳{order.shippingPrice}</td>
                    </tr>
                    <tr key="total_tax">
                        <td>Total Tax</td>
                        <td>:</td>
                        <td>৳{order.taxPrice}</td>
                    </tr>
                    <tr key="total_tax">
                        <td>Total Point</td>
                        <td>:</td>
                        <td>{order.totalPoint}</td>
                    </tr>
                    <tr key="grand_total">
                        <td>Grand Total</td>
                        <td>:</td>
                        <td>৳{order.totalPrice}</td>
                    </tr>
                </table>
              </div>
              <div className="card-footer text-muted bg-white">
                <div className="d-grid">
                  {order.isPaid ? (
                    <Badge variant="info">
                      Paid At {order.paidAt.substring(0, 10)}
                    </Badge>
                  ) : userInfo.isAdmin || userInfo.isAgent ? (
                      <Button variant={isLoading ? "outline-primary disabled" : "outline-primary"}
                              onClick={paywithbKash}>
                        {' '}
                        Approve Order
                      </Button>
                  ) : (
                    <Button variant="outline-primary">
                      {' '}
                      Order Under Review
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="card bg-white mb-3">
            <div className="card-header bg-white">
              Products Overview ({order._id} )
            </div>
            <div className="card-body">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th className="text-center">Products</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.map((item) => (
                    <tr key={item.slug}>
                      <td>
                        <img
                          src={item.image}
                          alt={item.name}
                          className="img-fluid rounded"
                          style={{ width: '5rem', height: '3rem' }}
                        ></img>{' '}
                        <Link to={`/product/${item.slug}`}>
                          {item.name.substring(0, 20) + '...'}
                        </Link>
                      </td>
                      <td>৳{item.price}</td>
                      <td>{item.quantity}</td>
                      <td>৳ {item.price * item.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
