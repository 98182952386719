import axios from 'axios';
import Button from 'react-bootstrap/Button';
import React, {useContext, useEffect, useReducer} from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from 'react-helmet-async';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import {Store} from '../../Store';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {api} from "../../Api";


const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return {...state, loading: true};
        case 'FETCH_SUCCESS':
            return {
                ...state,
                products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function ProductListScreen() {
  const [{ loading, products, pages, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { userInfo } = state;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          api + `/api/products/admin?page=${page}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {}
    };
    fetchData();
  }, [page, userInfo]);

  const createHandler = async () => {
    try {
      const { data } = await axios.post(
        api + '/api/products/admin/new',
        {},
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.info('Product Created');
      navigate(`/admin/product/${data.product._id}`);
    } catch (error) {
      toast.error(getError(error));
    }
  };

  const deleteHandler = async (product) => {
    if (window.confirm('Are you sure to delete?')) {
      try {
        await axios.delete(
            api + `/api/products/${product._id}`,
            {
                headers: {Authorization: `Bearer ${userInfo.token}`},
            }
        );
        toast.success('Product deleted successfully');
      } catch (err) {
        toast.error(getError(error));
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Manage Products | ClickCart International Ltd.</title>
      </Helmet>
      <Container className="">
        <Row className="my-3">
          <Col>
            <h2 className=" ">Manage Products</h2>
          </Col>
          <Col className="col text-end">
            <Button
              type="Button"
              variant="outline-success"
              onClick={createHandler}
            >
              Add Product
            </Button>
          </Col>
        </Row>

        {loading ? (
          <LoadingBox />
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <div
              className="table-responsive"
              style={{
                background: 'white',
                border: '1px solid black',
                padding: '5px',
                borderRadius: '15px',
              }}
            >
              <table className="table table-striped">
                <thead className="table-light">
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Brand</th>
                    <th>Category</th>
                    <th>Price</th>
                    <th>Point</th>
                    <th>Stock</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product._id}>
                      <td>{product._id}</td>
                      <td>{product.name}</td>
                      <td>{product.brand}</td>
                      <td>{product.category}</td>
                      <td>{product.price}</td>
                      <td>{product.point}</td>
                      <td>{product.stock}</td>
                      <td>
                        <Link
                          to={`/admin/product/${product._id}`}
                          variant="outline-success"
                        >
                          Edit
                        </Link>{' '}
                        &nbsp;
                        <Link
                          onClick={() => deleteHandler(product)}
                          variant="outline-success"
                        >
                          Delete
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {[...Array(pages).keys()].map((x) => (
                  <Link
                    className={
                      x + 1 === Number(pages) ? 'btn text-bold' : 'btn'
                    }
                    key={x + 1}
                    to={`/admin/products?page=${x + 1}`}
                  >
                    {x + 1}
                  </Link>
                ))}
              </div>
            </div>
          </>
        )}
      </Container>
    </div>
  );
}
