import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import Axios from 'axios';
import { api } from '../Api';

export default function MyWalletScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const myId = userInfo._id;
  const [mypoint, setMypoint] = useState(0);
  const [availablebalance, setavailablebalance] = useState(0);
  const [totalRight, setTotalRight] = useState(0);
  const [totalLeft, settotalLeft] = useState(0);
  const paidWithdraw = [0];
  const dueWithdraw = [0];
  const [paid, setPaid] = useState(0);
  const [due, setDue] = useState(0);
  const [grpavailable, setGrpavailable] = useState(0);
  const [incomeGeneration, setIncomeGeneration] = useState([]);

  const fetchData = async (id) => {
    try {
			const { data } = await Axios.get(api + `/api/users/point/${myId}`, {
				headers: { authorization: `Bearer ${userInfo.token}` },
			});
			setIncomeGeneration(data.incomeGeneration);
			setwithIncome(data.incomeGenerationW);
			setwithSucc(data.incomeGenerationWD);
			setMypoint(data.point);
			setavailablebalance(data.availablebalance);
			settotalLeft(data.shoppingbalance);
			setTotalRight(data.totalRight);
			getWithdrawStat();

		
		} catch (error) {
      window.alert(error);
    }
  };

  const [withIncome, setwithIncome] = useState();
  const [withSucc, setwithSucc] = useState();

  async function getWithdrawStat() {
    try {
      const { data } = await Axios.get(
        api + '/api/availablebalance/withdraw/stat',
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );

      for (var i = 0; i < data.length; i++) {
        if (data[i].status) {
          paidWithdraw.push(data[i].amount);
        } else {
          dueWithdraw.push(data[i].amount);
        }
      }

      setPaid(paidWithdraw.reduce((a, b) => a + b, 0));
      setDue(dueWithdraw.reduce((a, b) => a + b, 0));
      getgroupsales();
    } catch (error) {}
  }
  useEffect(() => {
    fetchData(myId);
    global();
  }, [userInfo, myId]);

  const navigate = useNavigate();

  function gotoHome() {
    navigate('/transection');
  }

  const gropsales = [0];
  const gropsalesavl = [0];
  const gropsaleswid = [0];
  const gropsaleswids = [0];
  const [mygroupseles, setMygroupsales] = useState();
  const [mygroupseleswid, setMygroupsaleswid] = useState();
  const [mygroupseleswids, setMygroupsaleswids] = useState();
  async function getgroupsales() {
    try {
      const { data } = await Axios.get(
        api + '/api/availablebalance/getgroupsales',
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );

      for (var i = 0; i < data.length; i++) {
        if (data[i].status === 'Pending') {
          gropsales.push(data[i].amount);
        }
        if (data[i].status === 'available') {
          gropsalesavl.push(data[i].amount);
        }
        if (data[i].status === 'withdraw') {
          gropsaleswid.push(data[i].amount);
        }
        if (data[i].status === 'withdraw_done') {
          gropsaleswids.push(data[i].amount);
        }
      }
      setMygroupsales(gropsales.reduce((a, b) => a + b, 0));
      setGrpavailable(gropsalesavl.reduce((a, b) => a + b, 0));
      setMygroupsaleswid(gropsaleswid.reduce((a, b) => a + b, 0));
      setMygroupsaleswids(gropsaleswids.reduce((a, b) => a + b, 0));
    } catch (error) {}
  }

  const globalsales = [0];
  const globalsaleswid = [0];
  const globalsaleswids = [0];
  const [myglobalseles, setMyglobalsales] = useState();
  const [myglobalseleswid, setMyglobalsaleswid] = useState();
  const [myglobalseleswids, setMyglobalsaleswids] = useState();
  async function global() {
    try {
      const { data } = await Axios.post(
        api + '/api/costs/myglobal',
        { username: userInfo.username },
        {
          headers: { authorization: `Bearer ${userInfo.token}` },
        }
      );

      for (var i = 0; i < data.length; i++) {
        if (data[i].status === 'Pending') {
          globalsales.push(data[i].amount);
        }

        if (data[i].status === 'withdraw') {
          globalsaleswid.push(data[i].amount);
        }
        if (data[i].status === 'withdraw_done') {
          globalsaleswids.push(data[i].amount);
        }
      }
      setMyglobalsales(globalsales.reduce((a, b) => a + b, 0));
      setMyglobalsaleswid(globalsaleswid.reduce((a, b) => a + b, 0));
      setMyglobalsaleswids(globalsaleswids.reduce((a, b) => a + b, 0));
    } catch (error) {}
  }

  return (
		<div>
			<Helmet>
				<title>My Wallet </title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i className="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						My Wallet
					</div>

					<div className="table-responsive" style={{ margin: "0.5rem" }}>
						<table
							className="table table-bordered table-striped "
							style={{ border: "1px" }}
						>
							<thead>
								<tr>
									<th>#</th>
									<th>Commission Type</th>
									<th>Income Point</th>
									<th>Available Point</th>
									<th>Withdraw</th>
									<th>Successful Withdraw</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>1</td>
									<td>Reference</td>
									<td>{availablebalance}</td>
									<td>{availablebalance}</td>
									<td>{due}</td>
									<td>{paid}</td>
								</tr>
								<tr>
									<td>2</td>
									<td>Group Sales</td>
									<td>{mygroupseles}</td>
									<td>{grpavailable}</td>
									<td>{mygroupseleswid}</td>
									<td>{mygroupseleswids}</td>
								</tr>
								<tr>
									<td>3</td>
									<td>Shopping Income</td>
									<td>{totalLeft}</td>
									<td>To Register: {mypoint}</td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>4</td>
									<td>Reward</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>5</td>
									<td>Salary</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>6</td>
									<td>Hub Maintenance</td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>7</td>
									<td>Global Sales</td>
									<td>{myglobalseles}</td>
									<td>0</td>
									<td>{myglobalseleswid}</td>
									<td>{myglobalseleswids}</td>
								</tr>
								<tr>
									<td>8</td>
									<td>Income Generation</td>
									<td>{incomeGeneration}</td>
									<td>0</td>
									<td>{withIncome}</td>
									<td>{withSucc}</td>
								</tr>
								<tr>
									<td></td>
									<td>Total</td>
									<td></td>
									<td></td>
									<td style={{ borderRight: "1px solid" }}></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div style={{ height: "31vh" }}> {""}</div>
				</div>
			</Container>
		</div>
	);
}
