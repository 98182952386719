import Axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {Store} from '../../Store';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {Helmet} from 'react-helmet-async';
import {toast} from 'react-toastify';
import {getError} from '../../utils';
import {useNavigate} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import {api} from "../../Api";

export default function ConfigScreen() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const myId = userInfo._id;
  const [loading, setLoading] = useState(0);
  const [siteStatus, setSiteStatus] = useState(0);
  const [referenceCommission, setreferenceCommission] = useState(0);
  const [matchingCommission, setmatchingCommission] = useState(0);
  const [convertCharge, setconvertCharge] = useState(0);
  const [pointValue, setpointValue] = useState(0);
  const [currency, setcurrency] = useState(0);
  const [withdraw, setwithdraw] = useState(0);
  const [minimumTransfer, setminimumTransfer] = useState(0);
  const [offDay, setoffDay] = useState(0);
  const [withdrawCharge, setwithdrawCharge] = useState(0);

  const fetchData = async () => {
    try {
      setLoading(1);
      const { data } = await Axios.get(api + `/api/users/getconfig`, {
        headers: { authorization: `Bearer ${userInfo.token}` },
      });
      setSiteStatus(data.siteStatus);
      setreferenceCommission(data.referenceCommission);
      setmatchingCommission(data.matchingCommission);
      setconvertCharge(data.convertCharge);
      setpointValue(data.pointValue);
      setcurrency(data.currency);
      setwithdraw(data.withdraw);
      setminimumTransfer(data.minimumTransfer);
      setoffDay(data.offDay);
      setwithdrawCharge(data.withdrawCharge);
      setLoading(0);
    } catch (error) {
      toast.error(getError(error));
    }
  };

  useEffect(() => {
    fetchData();
  }, [userInfo, myId]);

  const navigate = useNavigate();

  async function save() {
    try {
      setLoading(1);
      const { save } = await Axios.put(
        api + '/api/users/saveconfig',
        {
          siteStatus: siteStatus,
          referenceCommission: referenceCommission,
          matchingCommission: matchingCommission,
          convertCharge: convertCharge,
          pointValue: pointValue,
          currency: currency,
          withdraw: withdraw,
          minimumTransfer: minimumTransfer,
          offDay: offDay,
          withdrawCharge: withdrawCharge,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      toast.success('Data Saved');
      fetchData();
    } catch (error) {
      toast.error(getError(error));
    }
  }
  return (
    <div>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Container>
        <div className="card">
          <div className="card-body">
            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>General Settings</Accordion.Header>
                <Accordion.Body>
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <td>Under Maintenance</td>
                        <td>:</td>
                        <td>
                          <select
                            className="form-control"
                            onChange={(e) => setSiteStatus(e.target.value)}
                            value={siteStatus}
                          >
                            <option value="1">Enabled </option>
                            <option value="0">Disabled</option>
                          </select>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Reference Commission</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) =>
                              setreferenceCommission(e.target.value)
                            }
                            value={referenceCommission}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Matching Commission</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) =>
                              setmatchingCommission(e.target.value)
                            }
                            value={matchingCommission}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Convert Charge</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setconvertCharge(e.target.value)}
                            value={convertCharge}
                          />
                        </td>
                      </tr>
                      <tr className="d-none">
                        <td>Point value</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setpointValue(e.target.value)}
                            value={pointValue}
                          />
                        </td>
                      </tr>
                      <tr className="d-none">
                        <td>Currency</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setcurrency(e.target.value)}
                            value={currency}
                          />
                        </td>
                      </tr>
                      <tr className="d-none">
                        <td>Withdraw</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setwithdraw(e.target.value)}
                            value={withdraw}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Minimum Transfer</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setminimumTransfer(e.target.value)}
                            value={minimumTransfer}
                          />
                        </td>
                      </tr>
                      <tr className="d-none">
                        <td>Convert Charge %</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setconvertCharge(e.target.value)}
                            value={convertCharge}
                          />
                        </td>
                      </tr>
                      <tr className="d-none">
                        <td>offDay</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setoffDay(e.target.value)}
                            value={offDay}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Withdraw Charge</td>
                        <td>:</td>
                        <td>
                          <input
                            className="form-control"
                            onChange={(e) => setwithdrawCharge(e.target.value)}
                            value={withdrawCharge}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={3}>
                          <Button
                            variant="outline-primary w-100"
                            onClick={save}
                          >
                            {loading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                variant="primary"
                              />
                            ) : (
                              'Save'
                            )}
                          </Button>{' '}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </div>
  );
}
