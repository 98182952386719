import React, { useContext, useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';

function Rokey() {
  const signoutHandler = () => {
    window.location.reload();
  };

  const signoutHandler1 = () => {
    window.location.href = '/signin';
  };

  const signoutHandler2 = () => {
    window.location.href = '/signin';
  };

  return (
    <>
      <h1>Hi There</h1>
      <Link
        to="#signout"
        className="btn btn-info"
        onClick={signoutHandler}
        style={{ color: 'white' }}
      >
        signoutHandler
      </Link>
      <Link
        to="#signout"
        className="btn btn-info"
        onClick={signoutHandler1}
        style={{ color: 'white' }}
      >
        signoutHandler1
      </Link>
      <Link
        to="#signout"
        className="btn btn-info"
        onClick={signoutHandler2}
        style={{ color: 'white' }}
      >
        signoutHandler2
      </Link>
    </>
  );
}

export default Rokey;
