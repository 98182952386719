import cl from "./Team.module.css";

function Card({ img, text, designation, quote }) {
  return (
    <div className={cl.team}>
      <div className={cl.image}>
        <div className={cl.quote}>
          <p>{`"${quote}"`}</p>
        </div>
        <img src={img} alt="Icon" />
      </div>
      <div className={cl.text}>
        <a href="#!!!">
          <h3>{text}</h3>
        </a>
        <p>{designation}</p>
      </div>
    </div>
  );
}

export default Card;
