import Spinner from 'react-bootstrap/Spinner';
export default function LoadingBox() {
  return (
    <Spinner
      animation="border"
      style={{ margin: 'auto', textAlign: 'center' }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}
