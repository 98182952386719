import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet-async';
import Product from '../component/Product';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import Footer from '../ecommerce/Footer';
import Header from '../ecommerce/Header';
import { api } from "../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, products: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};


function AllBrandScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    products: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(api + '/api/products/allbrands');
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILED', payload: error.message });
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Helmet>
          <title>Icon Marketing</title>
        </Helmet>
        <Header />
        <Container>
          <h3 className="text-left" style={{ margin: 'auto', padding: '1rem' }}>
            All Brands
          </h3>
          <div className="products" style={{ margin: 'auto' }}>
            {loading ? (
              <LoadingBox />
            ) : error ? (
              <MessageBox variant="danger">{error}</MessageBox>
            ) : (
              <>
                <div className="row" style={{ margin: 'autp' }}>
                  {products.map((product) => (
                    <div className="col-lg-4 col-md-6  text-center mb-2">
                      <a href={'/search?brand=' + product._id}>
                        <img
                          src={product.brandPhoto}
                          className="img-thumbnail"
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default AllBrandScreen;
