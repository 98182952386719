import { useState } from "react";
import cl from "./Banner.module.css";

import b1 from "../../../assets/banner/1.png";
import b2 from "../../../assets/banner/2.png";
import b3 from "../../../assets/banner/3.png";
import b4 from "../../../assets/banner/4.png";
import b5 from "../../../assets/banner/5.png";
import b6 from "../../../assets/banner/6.png";
import b7 from "../../../assets/banner/7.png";
import b8 from "../../../assets/banner/8.png";

import left from "../../../assets/icons/banner/arrow-left-solid.svg";
import right from "../../../assets/icons/banner/arrow-right-solid.svg";

function Banner() {
  const [curSlide1, setCurSlide1] = useState(0);
  const [curSlide2, setCurSlide2] = useState(0);
  const allSlides1 = [b1, b2, b3, b4];
  const allSlides2 = [b5, b6, b7, b8];
  const maxSlide1 = allSlides1.length;
  const maxSlide2 = allSlides2.length;

  function nextSlide1() {
    if (curSlide1 === maxSlide1 - 1) {
      setCurSlide1(0);
    } else {
      setCurSlide1(curSlide1 + 1);
    }
  }

  function prevSlide1() {
    if (curSlide1 === 0) {
      setCurSlide1(maxSlide1 - 1);
    } else {
      setCurSlide1(curSlide1 - 1);
    }
  }

  function nextSlide2() {
    if (curSlide2 === maxSlide2 - 1) {
      setCurSlide2(0);
    } else {
      setCurSlide2(curSlide2 + 1);
    }
  }

  function prevSlide2() {
    if (curSlide2 === 0) {
      setCurSlide2(maxSlide2 - 1);
    } else {
      setCurSlide2(curSlide2 - 1);
    }
  }
  return (
    <div className={cl.container}>
      <div className={cl.banner}>
        <div className={cl.title}>
          <h2>Previous Events</h2>
        </div>
        <div className={cl.slider}>
          {allSlides1.map((c, i) => (
            <img
              key={i}
              src={c}
              alt="Icon"
              style={{ transform: `translateX(${100 * (i - curSlide1)}%)` }}
            />
          ))}
        </div>
        <div className={cl.buttons}>
          <button onClick={prevSlide1}>
            <img src={left} alt="Icon" />
          </button>
          <button onClick={nextSlide1}>
            <img src={right} alt="Icon" />
          </button>
        </div>
      </div>
      <div className={cl.banner}>
        <div className={cl.title}>
          <h2 className={cl.right_text}>Upcoming Events</h2>
        </div>
        <div className={cl.slider}>
          {allSlides2.map((c, i) => (
            <img
              key={i}
              src={c}
              alt="Icon"
              style={{ transform: `translateX(${100 * (i - curSlide2)}%)` }}
            />
          ))}
        </div>
        <div className={`${cl.buttons} ${cl.right_buttons}`}>
          <button onClick={prevSlide2}>
            <img src={left} alt="Icon" />
          </button>
          <button onClick={nextSlide2}>
            <img src={right} alt="Icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
