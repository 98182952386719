import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useContext } from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Rating from '../component/Rating';
import Button from 'react-bootstrap/esm/Button';
import { Helmet } from 'react-helmet-async';
import LoadingBox from '../component/LoadingBox';
import MessageBox from '../component/MessageBox';
import { getError } from '../utils';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import Header from '../ecommerce/Header';
import Footer from '../ecommerce/Footer';
import { api } from "../Api";

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, product: action.payload, loading: false };
    case 'FETCH_FAILED':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function ProductScreen() {
  const navigate = useNavigate();
  const param = useParams();
  const { slug } = param;

  const [{ loading, error, product }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    product: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const result = await axios.get(
          api+`/api/products/slug/${slug}`
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAILED', payload: getError(error) });
      }
    };
    fetchData();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;
    const { data } = await axios.get(
      api+`/api/products/${product._id}`
    );
    if (data.stock < quantity) {
      toast.error('Sorry, this product is currently stockout.');
      return;
    } else {
      toast.success('Product Added to the Cart.');
    }
    ctxDispatch({
      type: 'CART_ADD_ITEM',
      payload: { ...product, quantity },
    });
    navigate('/cart');
  };

  return loading ? (
    <LoadingBox />
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <Header />
      <Row
        className="w-80 my-3 bg-white"
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <Col md="4">
          <ListGroup>
            <img
              src={product.image}
              style={{ maxWidth: '15rem', maxHeight: '20rem' }}
              alt={product.name}
            />
          </ListGroup>
        </Col>
        <Col md="8">
          <ListGroup>
            <ListGroup.Item>
              <Helmet>
                <title>{product.name}</title>
              </Helmet>
              <h3>{product.name}</h3>
              <Rating rating={product.rating} totalRating="10"></Rating>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Price</Col>
                <Col>:</Col>
                <Col>
                  <h4 className="text-danger">৳{product.price}</h4>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Club Point</Col>
                <Col>:</Col>
                <Col>
                  <Badge className="danger badge bg-danger">
                    {' '}
                    {product.point}
                  </Badge>
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>Stock</Col>
                <Col>:</Col>
                <Col>
                  {product.stock > 0 ? (
                    <Badge bg="success">{product.stock} Products</Badge>
                  ) : (
                    <Badge bg="danger">Unavailable</Badge>
                  )}
                </Col>
              </Row>
            </ListGroup.Item>
            {product.stock > 0 && (
              <ListGroup.Item>
                <div className="d-gird">
                  <Button
                    variant="primary"
                    onClick={addToCartHandler}
                    className="w-100"
                  >
                    Add to Cart
                  </Button>
                </div>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row
        className="w-80 my-3 bg-white"
        style={{ maxWidth: '80%', margin: 'auto', padding: '1rem' }}
      >
        <ListGroup.Item>
          <h4>Product Description:</h4>
          <p>{product.description}</p>
        </ListGroup.Item>
      </Row>
      <Footer />
    </div>
  );
}

export default ProductScreen;
