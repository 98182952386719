import axios from 'axios';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate, useParams} from 'react-router-dom';
import LoadingBox from '../../component/LoadingBox';
import MessageBox from '../../component/MessageBox';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {Store} from '../../Store';
import {getError} from '../../utils';
import {toast} from 'react-toastify';
import {api} from "../../Api";


const reducer = (state, action) => {
    switch (action.type) {
        case 'FETCH_REQUEST':
            return {...state, loading: true};
        case 'FETCH_SUCCESS':
            return {...state, loading: false};
        case 'FETCH_FAIL':
            return {...state, loading: false, error: action.payload};
        case 'UPLOAD_REQUEST':
            return {...state, loadingUpload: true, errorUpload: ''};
        case 'UPLOAD_SUCCESS':
            return {
                ...state,
                loadingUpload: false,
                errorUpload: '',
            };
        case 'UPLOAD_FAIL':
            return {...state, loadingUpload: false, errorUpload: action.payload};
        default:
            return state;
    }
};

export default function DistributeScreen() {
    const navigate = useNavigate();
    const params = useParams(); // /product/:id
    const {id: productId} = params;
    const {state} = useContext(Store);
    const {userInfo} = state;
    const [{loading, error, loadingUpload}, dispatch] = useReducer(reducer, {
        loading: true,
        error: '',
    });

    const [global, setGlobal] = useState();
    const [users, setUsers] = useState();
    const [allData, setAllData] = useState();


    const loadingText = [
        "Checking All Users Now",
        "Checking Promotion History",
        "Checking Matching History",
        "Getting Global...",
        "Counting Point...",
        "Preparing Data..."
    ];
    const [loadingTextIndex, setLoadingTextIndex] = useState(0);


    const setLoadingText = (text) => {
        console.clear(); // Clears the console for a cleaner display (you can remove this line if not needed)
    };

    useEffect(() => {
        const loadingInterval = setInterval(() => {
            setLoadingText(loadingText[loadingTextIndex]);
            setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingText.length);
        }, 2500);

        return () => clearInterval(loadingInterval);
    }, [loadingTextIndex]);

    const fetchData = async () => {
        try {
            dispatch({type: 'FETCH_REQUEST'});


            await new Promise(resolve => setTimeout(resolve, 1000));

            for (let i = 0; i < loadingText.length; i++) {
                // Update loading text while making the request
                setLoadingText(loadingText[i]);


                const {data} = await axios.get(
                    api + `/api/costs/admin/global/distribute/${productId}`,
                    {
                        headers: {Authorization: `Bearer ${userInfo.token}`},
                    }
                );

                setGlobal(data.global);
                setUsers(data.users);
                setAllData(data);
                dispatch({type: 'FETCH_SUCCESS'});
            }
        } catch (error) {
            dispatch({type: 'FETCH_FAIL', payload: getError(error)});
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    async function distribute() {
        const deg1 =
            allData.devOffAchivers.length > 0
                ? (
                    (global.users * users.one.point) /
                    allData.devOffAchivers.length
                ).toFixed(2)
                : 0;

        const deg2 =
            allData.assManAchivers.length > 0
                ? (
                    (global.users * users.two.point) /
                    allData.assManAchivers.length
                ).toFixed(2)
                : 0;

        const deg3 =
            allData.depManAchivers.length > 0
                ? (
                    (global.users * users.three.point) /
                    allData.depManAchivers.length
                ).toFixed(2)
                : 0;

        const deg4 =
            allData.manAchivers.length > 0
                ? (
                    (global.users * users.four.point) /
                    allData.manAchivers.length
                ).toFixed(2)
                : 0;

        const deg5 =
            allData.gManAchivers.length > 0
                ? (
                    (global.users * users.five.point) /
                    allData.gManAchivers.length
                ).toFixed(2)
                : 0;

        const deg6 =
            allData.ambAchivers.length > 0
                ? (
                    (global.users * users.six.point) /
                    allData.ambAchivers.length
                ).toFixed(2)
                : 0;

        const deg7 =
            allData.gAmbAchivers.length > 0
                ? (
                    (global.users * users.seven.point) /
                    allData.gAmbAchivers.length
                ).toFixed(2)
                : 0;
        var points = [deg1, deg2, deg3, deg4, deg5, deg6, deg7];
        try {
            const {data} = await axios.post(
                api + `/api/costs/admin/global/distributenow/${productId}`,
                {
                    points,
                },
                {
                    headers: {Authorization: `Bearer ${userInfo.token}`},
                }
            );
            toast.success('Distribution Completed');

            fetchData();
        } catch (error) {
            toast.error('Distribution Failed');
        }
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const [title, setTitle] = useState();
    const [data, setData] = useState([]);

    async function viewModal(serial, dataf, title) {
        setShow(true);
        setTitle(title);
        setData(dataf);

    }

    return loading ? (
        <><LoadingBox/>
            <h2 className="w-100 text-center">  {loadingText[loadingTextIndex]} </h2>
        </>

    ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
    ) : (
        <>
            <Helmet>
                <title>Global Distribute</title>
            </Helmet>
            <Container className="container">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.map((item) => (
                            <li key={item.id}>{item}</li>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="card mb-3">
                    <div className="card-header  text-center">Global Distribute</div>
                    <div className="card-body" style={{background: "white"}}>
                        <b>
                            {global.month +
                                " - " +
                                global.year +
                                " || Total : " +
                                global.users * 59.75 +
                                " Points"}
                        </b>
                        <Table className="table table-responsive table-hover">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Promotion Name</th>
                                <th>Total User</th>
                                <th>Achievers</th>
                                <th>Point</th>
                                <th>Total Point</th>
                                <th>Per Head</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>Development Officer</td>
                                <td>{users.one.user}</td>
                                <td>{allData.devOffAchivers.length}</td>
                                <td>{users.one.point}</td>
                                <td>{(global.users * users.one.point).toFixed(2)}</td>
                                <td>
                                    {allData.devOffAchivers.length > 0
                                        ? (
                                            (global.users * users.one.point) /
                                            allData.devOffAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            viewModal(
                                                "1",
                                                allData.devOffAchivers,
                                                "Development Officer"
                                            )
                                        }
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Assistant Manager</td>
                                <td>{users.two.user}</td>
                                <td>{allData.assManAchivers.length}</td>
                                <td>{users.two.point}</td>
                                <td>{(global.users * users.two.point).toFixed(2)}</td>
                                <td>
                                    {allData.assManAchivers.length > 0
                                        ? (
                                            (global.users * users.two.point) /
                                            allData.assManAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("2", allData.assManAchivers, "Assistant Manager")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Deputy Manager</td>
                                <td>{users.three.user}</td>
                                <td>{allData.depManAchivers.length}</td>
                                <td>{users.three.point}</td>
                                <td>{(global.users * users.three.point).toFixed(2)}</td>
                                <td>
                                    {allData.depManAchivers.length > 0
                                        ? (
                                            (global.users * users.three.point) /
                                            allData.depManAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("3", allData.depManAchivers, "Deputy Manager")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Manager</td>
                                <td>{users.four.user}</td>
                                <td>{allData.manAchivers.length}</td>
                                <td>{users.four.point}</td>
                                <td>{(global.users * users.four.point).toFixed(2)}</td>
                                <td>
                                    {allData.manAchivers.length > 0
                                        ? (
                                            (global.users * users.four.point) /
                                            allData.manAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("4", allData.manAchivers, "Manager")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>General Manager</td>
                                <td>{users.five.user}</td>
                                <td>{allData.gManAchivers.length}</td>
                                <td>{users.five.point}</td>
                                <td>{(global.users * users.five.point).toFixed(2)}</td>
                                <td>
                                    {allData.gManAchivers.length > 0
                                        ? (
                                            (global.users * users.five.point) /
                                            allData.gManAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("5", allData.gManAchivers, "General Manager")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Ambassador</td>
                                <td>{users.six.user}</td>
                                <td>{allData.ambAchivers.length}</td>
                                <td>{users.six.point}</td>
                                <td>{(global.users * users.six.point).toFixed(2)}</td>
                                <td>
                                    {allData.ambAchivers.length > 0
                                        ? (
                                            (global.users * users.six.point) /
                                            allData.ambAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("6", allData.ambAchivers, "Ambassador")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Global Ambassador</td>
                                <td>{users.seven.user}</td>
                                <td>{allData.gAmbAchivers.length}</td>
                                <td>{users.seven.point}</td>
                                <td>{(global.users * users.seven.point).toFixed(2)}</td>
                                <td>
                                    {allData.gAmbAchivers.length > 0
                                        ? (
                                            (global.users * users.seven.point) /
                                            allData.gAmbAchivers.length
                                        ).toFixed(2)
                                        : 0}
                                </td>
                                <td>
                                    {" "}
                                    <Button
                                        variant="primary"
                                        onClick={() => viewModal("7", allData.gAmbAchivers, "Global Ambassador")}
                                    >
                                        View
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={8}>
                                    <Button
                                        onClick={distribute}
                                        variant="success"
                                        className={global.status ? "w-100 disabled" : "w-100"}
                                    >
                                        {global.status ? "Already Distributed" : "Distribute Now"}
                                    </Button>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </Container>
        </>
    );
}
