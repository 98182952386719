import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
export default function HistoryScreen() {
  const navigate = useNavigate();

  function gotoHome() {
    navigate('/transferandhistory');
  }
  return (
		<div>
			<Helmet>
				<title>History </title>
			</Helmet>
			<Container className="no-gutter" style={{}}>
				<div className="card">
					<Row>
						<Col style={{ textAlign: "center" }}>
							<img
								src="https://res.cloudinary.com/dddogyqqa/image/upload/v1670435815/logo/clickcart-logo_wfktlg.png"
								style={{ width: "15rem", margin: "2rem" }}
								alt="ClickCart Logo"
							/>
						</Col>
					</Row>
					<div
						className="card-header"
						onClick={() => {
							gotoHome();
						}}
					>
						<i class="fa fa-long-arrow-left" aria-hidden="true"></i> {"  "}{" "}
						History
					</div>
					<div className="card-body" style={{ margin: "auto" }}></div>
					<Link to="/selectgeneration" className="inbody-button mb-3">
						Income Generation History
					</Link>
					<Link to="/converthistory" className="inbody-button mb-3">
						Convert History
					</Link>
					<Link to="/withdrawhistory" className="inbody-button mb-3">
						Withdraw History
					</Link>
					<Link to="/transferhistory" className="inbody-button mb-3">
						Transfer History
					</Link>
					<Link to="/receivedhistory" className="inbody-button mb-3">
						Received History
					</Link>
					<Link to="/referencehistory" className="inbody-button mb-3">
						Reference History
					</Link>{" "}
					<Link to="/groupsaleshistory" className="inbody-button mb-3">
						Group Sales History
					</Link>{" "}
					<Link to="/rewardhistory" className="inbody-button mb-3">
						Reward History
					</Link>{" "}
					<Link to="/history" className="inbody-button mb-3">
						Salary History
					</Link>{" "}
					<Link to="/history" className="inbody-button mb-3">
						Hub Maintenance History
					</Link>{" "}
					<Link to="/history" className="inbody-button mb-3">
						Global Sales History
					</Link>
					<div style={{ height: "31vh" }}> {""}</div>
				</div>
			</Container>
		</div>
	);
}
